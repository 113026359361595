<template>
  <v-main v-if="authenticated" id="dashboard-view">

    <Toolbar/>

    <v-container fluid class="pa-0 main-content">
      <PageLoader :display="true === loadingCourse"/>

      <VuePerfectScrollbar class="scroll pa-0">
        <v-container v-if="false === loadingCourse" class="pa-3 ma-3">

          <CourseUpdateComponent
            :course="course"
            :sendingForm="sendingForm"
            @formSend="updateCourse"
          />

          <SnackbarMessage
            ref="errorMessage"
            :text="message"
            color="red darken-2"
          />
        </v-container>
      </VuePerfectScrollbar>
    </v-container>
  </v-main>
</template>

<script>
  import Toolbar               from '@/components/toolbar/index'
  import CourseUpdateComponent from '@/modules/course/crud/Update'
  import PageLoader            from '@/components/ui/loader/PageLoader'
  import SnackbarMessage           from '@/components/ui/snackbar'

  import { mapGetters }        from 'vuex'

  export default {
    name: 'CourseUpdate',
    components:{
      PageLoader,
      Toolbar,
      CourseUpdateComponent,
      SnackbarMessage
    },
    data(){
      return{
        message: ''
      }
    },
    computed:{
      ...mapGetters({
        authenticated: 'services/api/auth/authenticated',
        loadingCourse: 'services/api/course/course/fetching',
        course: 'services/api/course/course/course',
        sendingForm: 'services/api/course/crud/fetching',
        error: 'services/api/course/crud/error'
      })
    },
    mounted(){
      let me = this;
      me.fetchCourse(me.courseId);
    },
    methods:{
      fetchCourse: function(id){
        let me = this;
        me.$store.dispatch('services/api/course/course/fetch', id);
      },
      updateCourse: function(values){
        let me = this;
        me.$store.dispatch('services/api/course/crud/update', values)
          .then(response => {

            if(false === response.success){
              me.message = response.message;
              me.$refs['errorMessage'].show();
              return;
            }

            me.fetchCourse(me.course.current.id);
          })
      }
    },
    props:{
      courseId:{
        type: [String, Number],
        required: true
      }
    }
  }
</script>

<style scoped>

</style>
