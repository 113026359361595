
export default {
  isFetching(state){
    return state.api.get.fetching;
  },
  getData(state){
    return state.api.get.values;
  },
  getDiplomas(state){
    return state.diplomas;
  },
  getAchievements(state){
    return state.achievements;
  },
  getStudent(state){
    return state.student;
  },
  getCourses(state){
    return state.courses;
  },
  getSocial(state){
    return state.social;
  }
}
