<template>
  <v-container
    v-if="true === display"
    class="pageLoader"
    :style="style"
  >
    <v-layout column class="align-center justify-center fill-height">
      <v-flex xs3>
        <v-progress-circular
          indeterminate
          :size="size"
          color="primary"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  export default {
    name: "PageLoader",
    computed:{
      style:function(){
        let me = this;
        return 'height:'+me.height+';width:'+me.width+';';
      }
    },
    props:{
      display:{
        type: Boolean,
        default: false
      },
      size:{
        type: Number,
        default: 120
      },
      height:{
        type: String,
        default: '100vh'
      },
      width:{
        type: String,
        default: '100vw'
      }
    }
  }
</script>

<style scoped>
  .pageLoader{
    height:100vh;
    width:100vw;
  }

</style>
