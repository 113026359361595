/**
 * @todo When connecting the real backend, just change this file to the correct
 * end points.
 */

const ENDPOINT = new Map();
ENDPOINT.set('create', '/administration/achievement/create');
ENDPOINT.set('update', '/administration/achievement/update');

export default ENDPOINT
