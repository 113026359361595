<template>
  <v-snackbar
    v-model="snackbar"
    :color="color"
  >
    {{ text }}

    <v-btn
      text
      @click="snackbar = false"
    >
      Close
    </v-btn>
  </v-snackbar>
</template>

<script>
  export default {
    name: 'index',
    data(){
      return{
        snackbar: false
      }
    },
    methods:{
      show: function(){
        let me = this;
        me.snackbar = true;
      }
    },
    props:{
      text:{
        type: String,
        required: false
      },
      color: {
        type: String,
        default: 'red darken-2'
      }
    }
  }
</script>

<style scoped>

</style>
