export default {
  get: function(){
    return {
      student:{},
      diplomas:[],
      courses:[],
      achievements:[],
      social: [],
      api:{
        get:{
          fetching: null,
          error: null,
          values: {},
        },
        put: {

        },
        post:{

        },
        delete:{

        }
      }
    }
  }
}
