<template>
  <v-main v-if="authenticated" class="main-content authenticated-layout" id="dashboard-view" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <slot name="app-components">
            <Toolbar/>
            <!-- <Sidebar/> -->
          </slot>

          <slot name="default">Content goes here</slot>
        </v-col>
      </v-row>
  </v-main>

</template>

<script>
  import TranslationsMixin      from '@/mixins/Translation'

  import Toolbar                from '@/components/toolbar/index'
  import Sidebar                from '@/components/sidebar/index'

  import { mapGetters } from 'vuex'

  export default{
    name: 'AuthenticatedLayout',
    mixins:[
      TranslationsMixin
    ],
    components:{
      Toolbar,
      Sidebar
    },
    computed:{
      ...mapGetters({
        authenticated: 'services/api/auth/authenticated',
        focused: 'services/browser/window/focused',
      })
    },
    props:{
      id: {
        type: String,
        required:true
      }
    }
  }
</script>

<style scoped>
</style>
