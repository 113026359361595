<template>
  <v-row justify="center">
    <v-col cols="12" md="8">
      <v-card>
        <v-card-title>
          Opciones del sistema
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-group
              :value="true"
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Limpiar cache</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(dir, i) in dirs"
                :key="i"
              >
                <v-checkbox
                  v-model="dir.active"
                  :label="dir.name"
                  :false-value="0"
                  :true-value="1"
                />
              </v-list-item>

              <v-list-item>
                <v-combobox
                  v-model="users"
                  label="Limpiar para los mails:"
                  chips
                  deletable-chips
                  clearable
                  multiple
                  :delimiters="[',', ' ']"
                  append-icon=""
                >
                </v-combobox>
              </v-list-item>

              <v-list-item :link="false" class="body-1">
                Los mails se separan por comas o espacios
              </v-list-item>

              <v-list-item :link="false" class="error--text body-1">
                Nota: el cache se limpiará únicamente para los usuarios elegidos. En caso contrario, si no se especifica ningún mail, se programará una tarea para la limpieza general acorde a los items seleccionados.
              </v-list-item>

              <v-list-item>
                <v-list-item-action>
                  <v-btn
                    color="primary"
                    @click="clearCache"
                    :loading="cleaning"
                    :disabled="cleaning"
                    class="mt-5"
                  >
                    Limpiar
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card-text>

      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'Clear',
    data(){
      return {
        dirs:[
          {name:'Certificados', active: 1, key:'certificate'},
          {name:'Diplomas', active: 1, key:'diploma'},
          {name:'Logros', active: 1, key:'achievements'},
          {name:'HTTP', active: 1, key:'http'}
        ],
        users:[]
      }
    },
    methods:{
      clearCache: function(){
        let me = this,
          form = new FormData();

        setTimeout(function(){
          for(let i = 0;i < me.dirs.length;i++){
          form.append(me.dirs[i].key, me.dirs[i].active);
        }

        if(me.users.length > 0){
          me.users.map(user => form.append('users[]', user));
        }

        me.$emit('clearCache', form);
        },
          100
        );
      }
    },
    props:{
      cleaning:{
        type: Boolean,
        required: false
      }
    }
  }
</script>

<style scoped>

</style>
