import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const merge = (args) => {
  let params = new URLSearchParams();
  params.append('applicant', args.applicant);
  params.append('requested', args.requested);
  return Api.get(endpoints.get('merge')+'?'+params.toString());
}

export default {
  merge
};
