const data = state => state.data;
const searching = state => state.searching;
const error = state => state.error;
const success = state => state.success;

export default {
  data,
  searching,
  error,
  success
};
