<template>
    
</template>

<script>
    export default {
      name: "SocialIcon",
      methods: {
        getSocialNetworkIcon: function (socialNetworkName) {
          switch (socialNetworkName.toLowerCase()) {
            case 'linkedin':
              return 'ico-linkedin';

            case 'twitter':
              return 'ico-twitter';

            case 'github':
              return 'ico-github';

            case 'instagram':
              return 'ico-instagram';

            case 'facebook':
              return 'ico-facebook';

            default:
              return '';
          }
        }
      }
    }
</script>

<style scoped>

</style>
