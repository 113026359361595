import store from '@/store'

const send =  ({ commit, state, rootGetters }, args) => {
  let force = typeof args['force'] === 'undefined' ? false : args['force'];

  /**
   * Check if the browser window is focused and if the notification should only be displayed when the browser is
   * unfocused.
   */
  if(
    typeof args.unfocused !== 'undefined' &&
    true === args.unfocused &&
    true === rootGetters['services/browser/window/focused']
  ){
    return;
  }

  commit('ADD', args);

  if(false === state.enable){
    return;
  }

  if(false === state.granted){
    console.warn('Notification permission not granted, can not receive notification');

    if(!force){
      return;
    }

    store.dispatch('services/browser/window/titleFlashStart', {
      message: [args.title, ' ', args.options.message].join(' '),
      stopOnFocus: true
    });

  }

  if(false === state.supported){
    return console.warn('Your browser does not support the notification API');
  }

  new Notification(args.title, args.options);
}

const enable = ({commit}) => {
  commit('ENABLE', true);
}

const disable = ({commit}) => {
  commit('ENABLE', false);
}

const init = ({commit}) => {
  if(!('Notification' in window)){
    commit('SUPPORTED', false);

    return commit('GRANTED', false);
  }

  commit('SUPPORTED', true);
  commit('GRANTED', 'granted' === Notification.permission);

  if('denied' === Notification.permission){
    return;
  }

  Notification.requestPermission()
    .then(permission => {
      commit('GRANTED', 'granted' === permission);
    });
}

export default {
  init,
  send,
  enable,
  disable
}
