<template>
  <v-row>
    <v-col cols="12">
      <v-pagination
        v-model="page"
        class="my-2"
        :length="pages"
        :total-visible="9"
        :circle="true"
        @input="fetch"
        @next="fetch"
        @previous="fetch"
        :disabled="null === pages"
      ></v-pagination>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'SimplePagination',
    data(){
      return {
        page: 1
      }
    },
    methods:{
      fetch: function(){
        let me = this;
        me.$emit('fetch', me.page);
      },
      reset: function(){
        let me = this;
        me.page = 1;
      }
    },
    props:{
      pages:{
        type: Number,
        required: false
      }
    }
  }
</script>

<style scoped>

</style>
