/**
 * Vuex Store
 */

import Vue from 'vue';
import Vuex from 'vuex';

import apiServices from '@/services/api'
import browserServices from '@/services/browser'
import tenorService from '@/services/tenor'
import localService from '@/services/local'

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced:true,
  modules: {
    services:{
      namespaced: true,
      modules: {
        api: apiServices,
        browser: browserServices,
        local: localService,
        tenor: tenorService
      }
    }
  }
})
