<template>
  <v-dialog
    v-model="mixinUiModalVisible"
    max-width="500"
  >
    <v-card>
      <v-card-title class="text-h5">
        Clonar rol: {{achievement.name}}
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="version"
          label="Versión"
        />
        <v-checkbox
          v-model="updateRequirements"
          label="Utilizar las últimas versiones de los roles requeridos"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error darken-1"
          text
          @click.native="show(false)"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="success darken-1"
          text
          @click.native="cloneAchievement"
          :disabled="'' === version || cloning"
        >
          <v-progress-circular
            v-if="cloning"
            indeterminate
            color="grey"
            size="20"
          ></v-progress-circular>

          <div v-if="!cloning">Clonar</div>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import ModalMixin from '@/mixins/ui/Modal'

  export default {
    name: 'CloneModal',
    mixins:[
      ModalMixin
    ],
    data(){
      return{
        achievement: [],
        version: '',
        updateRequirements: true
      }
    },
    methods:{
      setAchievement: function(achievement){
        let me = this;
        me.version = '';
        me.updateRequirements = true;
        me.achievement = achievement;
      },
      cloneAchievement: function(){
        let me = this,
          form = new FormData();

        form.append('version', me.version);
        form.append('achievement', me.achievement.uniqid);
        form.append('updateRequirements', me.updateRequirements);

        me.$emit('cloneAchievement', form);
      }
    },
    props:{
      cloning:{
        type: Boolean,
        required: false
      }
    }
  }
</script>

<style scoped>

</style>
