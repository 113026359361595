<template>
  <v-row class="pa-0 ma-0">
    <v-col cols="12">
      <PageLoader :display="true === isFetchingFilters || true === isFetchingServerDate" />

      <FilterBox
        v-if="false === isFetchingFilters && false === isFetchingServerDate"
        ref="filters"
        :items="items"
        :sections="sections"
        :serverDate="serverDate"
        :info="info"
        @search="search"
        @openConfiguration="showScore"
        @changeSections="changeSections"
      />

      <v-expansion-panels v-model="showScoreThermometer" class="pt-3" multiple>
        <v-expansion-panel>
          <v-expansion-panel-content>
            <ScoreThermometer ref="scoreThermometer" :values="scoreThermometer" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <v-col cols="12">
      <PageLoader :display="true === isFetchingStats" height="10vh" :size="60" />

      <StatsList
        v-if="false === isFetchingStats && null !== filters"
        :values="stats"
        :filters="filters"
        :selectedSections="filteredSections"
        :scoreThermometer="scoreThermometer"
      />
    </v-col>
  </v-row>
</template>

<script>

  import FilterBox from './_components/form/search/index'
  import StatsList from './_components/list/index'
  import ScoreThermometer from '@/components/ui/score'

  import { mapGetters } from 'vuex'
  import PageLoader from '@/components/ui/loader/PageLoader'

  export default {
    name: 'CareerStatsDateModule',
    components:{
      PageLoader,
      FilterBox,
      StatsList,
      ScoreThermometer,
    },
    data(){
      return {
        filters: null,
        scoreThermometer: [
          {name: 'ok', color:'#228C22', min: 4.50, max:5.00},
          {name: 'warning', color:'#e79100', min: 4.00, max: 4.49},
          {name:'error', color:'#B00020', min: 0.00, max: 3.99}
        ],
        showScoreThermometer: [],
        filteredSections: []
      }
    },
    computed:{
      ...mapGetters({
        isFetchingServerDate: 'services/api/server/date/fetching',
        serverDate: 'services/api/server/date/date'
      })
    },
    methods:{
      getFilterComponent: function(){
        let me = this;
        return me.$refs['filters'];
      },
      changeSections: function(sections){
        let me = this;
        me.filteredSections = sections;
      },
      showScore: function(){
        let me = this;

        me.showScoreThermometer = me.showScoreThermometer.length > 0 ? [] : [0]
      },
      search: function(){
        let me = this,
          filterComponent = me.getFilterComponent();

        if(0 === me.filteredSections.length){
          me.filteredSections = filterComponent.getSections();
        }

        me.filters = filterComponent.getValues();
        me.$emit('search', me.filters);
      }
    },
    props:{
      stats:{
        type: Array,
        required: true
      },
      sections: {
        type: Array,
        required: true
      },
      items: {
        type: Array,
        required: true
      },
      info:{
        type: Object,
        required: true
      },
      isFetchingStats: {
        type: Boolean,
        required: false,
      },
      isFetchingFilters: {
        type: Boolean,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>
