import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const fetch = (args) => {
  let params = new URLSearchParams();

  if(typeof args['career'] !== 'undefined'){
    args.career.map((career, index)=>{
      params.append('career['+index+']', career);
    });
  }

  if(typeof args['branch'] !== 'undefined'){
    params.append('branch', args.branch);
  }

  if(typeof args['year'] !== 'undefined'){
    params.append('year', args.year);
  }

  if(typeof args['month'] !== 'undefined'){
    params.append('month', args.month);
  }

  return Api.post(endpoints.get('fetch'), params,{
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

export default {
  fetch
};
