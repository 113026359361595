<template>
  <v-row justify="center">
    <v-col cols="12" md="8">
      <v-row>
        <v-col cols="12">
          <h2>Unión de cuentas</h2>
        </v-col>

        <v-col cols="12">
          <p>La unión de cuentas combinará los cursos, calendarios, mensajería, exámenes, diplomas, certificados y puntos. El resultado se verá reflejado en la cuenta a conservar.</p>
        </v-col>

        <v-col cols="12">
          <h3>Emails de las cuentas que se van a unificar</h3>
        </v-col>

        <v-col cols="12">
          <v-text-field
            v-model="applicantUser"
            label="Cuenta a conservar"
          />

          <v-text-field
            v-model="requestedUser"
            label="Cuenta a eliminar"
          />
        </v-col>

        <v-col cols="12">
          <v-btn
            color="primary"
            class="elevation-0 ma-0 btn-merge"
            @click.prevent="confirmModal"
            :disabled="sendingRequest"
          >
            <span v-if="true !== sendingRequest">Unir cuentas</span>
            <v-progress-circular
              v-if="true === sendingRequest"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog
      v-model="modal"
      max-width="400"
    >
      <v-card>
        <v-card-title class="primary white--text pa-3">
          Confirmación de unión de cuentas
        </v-card-title>
        <v-card-text class="pa-3 mt-3">¿Está seguro que desea unir la cuenta: <b>{{applicantUser}}</b> con: <b>{{requestedUser}}</b>?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="elevation-0 white--text"
            color="error darken-1"
            @click="modal = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="elevation-0 white--text"
            color="green darken-1"
            @click="mergeAccounts"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Merge',
    data(){
      return {
        applicantUser: '',
        requestedUser: '',
        modal: false,
        awaitModal: false
      }
    },
    computed:{
      ...mapGetters({
        sendingRequest: 'services/api/user/merger/mergeSending'
      })
    },
    methods:{
      confirmModal: function(){
        let me = this;

        if(me.applicantUser === '' || me.requestedUser === ''){
          return me.$emit('incompleteForm')
        }

        me.modal = true;
      },
      mergeAccounts: function(){
        let me = this;
        me.modal = false;

        me.$emit('mergeAccounts', {
          applicant: me.applicantUser,
          requested: me.requestedUser
        })
      },
      reset: function(){
        let me = this;
        me.applicantUser = '';
        me.requestedUser = '';
      }
    }
  }
</script>

<style scoped>

</style>
