<template>
	<div class="animated fadeIn logout-view">
	</div>
</template>

<script>
	import Routes from '@/router/routes'

	export default{
		name: 'LogoutView',
    methods:{
		  redirectToLogin: function(){
		    let me = this;

        /**
         * It's specially important to listen to the logout response to be able to avoid the application to reconnect
         * to the websocket server.
         */
        me.$store.dispatch('services/api/auth/logout')
        .then(response => {
          me.$router.push(Routes.get('login').path);
        })
      }
    },
		mounted(){
			let me = this;

			setTimeout(() => {
				me.redirectToLogin();
			}, 200);
		}
	}

</script>

<style scoped>
	.logout-view{
		height:100vh;
		background-color:#fcfcfc;
	}
</style>
