import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const fetch = (args) => {
  if(typeof args.form === 'undefined' || typeof args.form !== 'object'){
    throw 'Profile update requires a form attribute'
  }

  if(false === (args.form instanceof FormData)){
    throw 'Form attribute must be of FormData type'
  }

  return Api.post(endpoints.get('list'), args.form,{
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

export default {
  fetch,
};
