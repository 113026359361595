import api from '../api'

const merge = ({commit}, data) => {
  commit('MERGE_SENDING', true);

  return new Promise((resolve, reject) => {
    api.merge(data)
      .then(response => {
        commit('MERGE_SENDING', false);
        commit('MERGE_SUCCESS', true);
        resolve(response);
      })
      .catch(error => {
        commit('MERGE_SENDING', false);
        commit('MERGE_SUCCESS', false);

        reject(error);
      })
  });
};

export default {
  merge
}
