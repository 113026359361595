import dateService from './date'
import pingService from './ping'

export default {
  namespaced:true,
  modules: {
    date: dateService,
    ping: pingService
  }
}
