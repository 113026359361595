import Api from '@/services/tenor/api/index.js'
import endPoints from './endpoint'
import settings from '@/settings'

const search = (args) => {

  let params  = new URLSearchParams();
  params.set('q', args.search);
  params.set('limit', args.limit);
  params.set('key', settings.api.tenor.key);

  return Api.get(endPoints.get('search')+'?'+params.toString());
}

const trending = (args) => {
  let params  = new URLSearchParams();
  params.set('limit', args.limit);
  params.set('key', settings.api.tenor.key);

  return Api.get(endPoints.get('trending')+'?'+params.toString());
}

export default {
  search,
  trending
};
