<template></template>

<script>
	import { mapGetters } from 'vuex'

	export default{
		name: 'AuthenticatedMixin',
		computed:{
			...mapGetters({
				authenticatedMixin: 'services/api/auth/authenticated',
			})
		}
	}
</script>
