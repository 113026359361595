<template></template>

<script>
	import { mapGetters } from 'vuex'

	export default{
		name: 'TranslationMixin',
		computed:{
			...mapGetters({
				translations:'services/api/translation/getTranslations',
			})
		}
	}
</script>
