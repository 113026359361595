import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  current: '',
  api:{
    get:{
      fetching: false,
      error: false,
      values: [],
    },
    put: {

    },
    post:{

    },
    delete:{

    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
