<template>
  <v-main v-if="authenticated" id="dashboard-view">

    <Toolbar/>

    <v-container fluid class="pa-0 main-content">
      <VuePerfectScrollbar class="scroll pa-0">
        <v-container>
          <PageLoader :display="fetchingProfile" />

          <PublicProfileModule
            v-if="false === fetchingProfile && true === public"
            :certificates="certificates"
            :diplomas="diplomas"
            :student="student"
            :courses="courses"
            :achievements="achievements"
            :translations="translations"
            :social="social"
          />

          <div class="private-profile" v-if="false === public">
            <v-icon class="lock-icon">lock</v-icon>
            <p class="pp-text">El perfil no puede visualizarse porque es privado</p>
          </div>
        </v-container>
      </VuePerfectScrollbar>
    </v-container>
  </v-main>
</template>

<script>
  import Toolbar from '@/components/toolbar/index'
  import PublicProfileModule from '@/modules/user/profile'
  import TranslationsMixin from '@/mixins/Translation'
  import PageLoader from '@/components/ui/loader/PageLoader'
  import _ from 'underscore'

  import { mapGetters } from 'vuex'

  export default {
    name: "Profile",
    mixins:[
      TranslationsMixin
    ],
    components:{
      Toolbar,
      PublicProfileModule,
      PageLoader
    },
    data(){
      return{
        public: true
      }
    },
    computed:{
      ...mapGetters({
        authenticated: 'services/api/auth/authenticated',
        student: 'services/api/user/profile/getStudent',
        courses: 'services/api/user/profile/getCourses',
        achievements: 'services/api/user/profile/getAchievements',
        diplomas: 'services/api/user/profile/getDiplomas',
        certificates: 'services/api/course/certificate/certificates',
        social:  'services/api/user/profile/getSocial',
        fetchingProfile: 'services/api/user/profile/isFetching'
      }),
      loading: function(){
        let me = this;
        return _.isEmpty(me.student);
      }
    },
    methods: {
      fetchProfile: function(id){
        let me = this;

        me.public = true;

        me.$store.dispatch('services/api/user/profile/fetch',
          {
            id: id
          }
        )
          .catch(error => {

            if(403 === error.response.status){
              me.public = false;
            }
          });

        me.$store.dispatch('services/api/course/certificate/certificates',
          {
            uniqid: me.id
          }
        );
      }
    },
    mounted(){
      let me = this;

      me.fetchProfile(me.id);
    },
    watch:{
      id: function(n, o){
        let me = this;
        me.fetchProfile(n);
      }
    },
    props:{
      id: {
        type: [ Number, String ],
        required: true
      }
    }
  }
</script>

<style scoped>
  @import './style/style.scss';
</style>
