<template>
  <v-main v-if="authenticated" id="dashboard-view">

    <Toolbar/>

    <v-container fluid class="pa-0 main-content">
      <PageLoader :display="true === loadingVersions"/>

      <VuePerfectScrollbar class="scroll pa-0">
        <v-container v-if="false === loadingVersions">

          <AchievementUpdateComponent
            :versions="versions"
            @update="updateAchievement"
          />

          <SnackbarMessage
            ref="finishMessage"
            :text="message"
            :color="color"
          />
        </v-container>
      </VuePerfectScrollbar>
    </v-container>
  </v-main>
</template>

<script>
  import Toolbar               from '@/components/toolbar/index'
  import AchievementUpdateComponent from '@/modules/achievement/crud/Update'
  import PageLoader            from '@/components/ui/loader/PageLoader'
  import SnackbarMessage           from '@/components/ui/snackbar'

  import { mapGetters }        from 'vuex'

  export default {
    name: 'AchievementUpdate',
    components:{
      PageLoader,
      Toolbar,
      AchievementUpdateComponent,
      SnackbarMessage
    },
    data(){
      return{
        message: '',
        color: ''
      }
    },
    computed:{
      ...mapGetters({
        authenticated: 'services/api/auth/authenticated',
        loadingVersions: 'services/api/achievement/version/fetching',
        versions: 'services/api/achievement/version/versions'
      })
    },
    mounted(){
      let me = this;
      me.fetchAchievement();
    },
    methods:{
      fetchAchievement: function(){
        let me = this;
        me.$store.dispatch('services/api/achievement/version/fetch', me.achievementId);
      },
      updateAchievement: function(form){
        let me = this;

        me.$store.dispatch('services/api/achievement/crud/update', {
          form: form
        })
          .then(response => {
            me.color = true === response.success ? 'success' : 'red darken-2';
            me.message = response.message;
            me.$refs['finishMessage'].show();

            setTimeout(function(){ me.fetchAchievement(); }, 2000);
          })
      }
    },
    props:{
      achievementId:{
        type: String,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>
