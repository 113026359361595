import listService from './list'
import profileService from './profile'
import achievementService from './achievement'
import mergerService from './merger'

export default {
  namespaced:true,
  modules: {
    list: listService,
    profile: profileService,
    achievement: achievementService,
    merger: mergerService
  }
}
