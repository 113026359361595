<template>
  <v-select
    v-model="month"
    :disabled="false === enabled"
    :items="months"
    hint="Mes"
    label="Mes"
    :return-object="true"
  />
</template>

<script>
  export default {
    name: 'MonthSelect',
    data(){
      return {
        month: null,
      }
    },
    computed:{
      months: function(){
        let result = [];

        for(let i = 1; i <= 12; i++){
          result.push(i);
        }

        return result;
      }
    },
    methods:{
      getName: function(){
        return 'month';
      },
      getFormValue: function(){
        let me = this;
        return me.month;
      }
    },
    props:{
      enabled:{
        type: Boolean,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>
