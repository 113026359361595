import initialState from './state'

const FETCH = (state, value) => {
  state.fetching = value;
}

const DATA = (state, values) => {
  state.careers = values.career;
  state.branches = values.branch;
  state.sections = values.section;
}

const SUCCESS = (state, value) =>{
  state.careers = value.career;
  state.branches = value.branch;
  state.section = value.section;
}

const ERROR = (state, value) => {
  state.error = value;
}


export default {
  FETCH,
  SUCCESS,
  DATA,
  ERROR
}
