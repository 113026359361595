<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="courseName"
          label="Filtrar por curso"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" class="text-right">
        <v-btn color="success" class="elevation-0" @click.prevent="createCourse">
          <v-icon class="pr-3">
            fas fa-plus
          </v-icon>
          Crear curso
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1">ID</v-col>
      <v-col cols="9">
        Curso
      </v-col>

      <v-col cols="2" sm="1">
        Opciones
      </v-col>
    </v-row>

    <v-divider class="pb-2" />

    <v-row v-for="course in filteredCourses" :key="course.uniqid">
      <v-col cols="1">{{course.id}}</v-col>
      <v-col cols="9">
        <v-avatar class="mr-4" size="40">
          <CourseIcon :icon="course.icon" :contain="true" />
        </v-avatar>

        {{course.name}}
      </v-col>

      <v-col cols="2" sm="1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              class="elevation-0"
              v-bind="attrs"
              v-on="on"
              @click.prevent="updateCourse(course)"
            >
              <v-icon
                color="orange"
                dark
              >
                fas fa-pen
              </v-icon>
            </v-btn>
          </template>
          <span>Editar {{course.name}}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import CourseIcon from '@/components/ui/course/Icon'
  import Routes from '@/router/routes'

  export default {
    name: 'index',
    components:{
      CourseIcon,
    },
    data(){
      return{
        courseName: ''
      }
    },
    computed:{
      filteredCourses: function(){
        let me = this;

        if('' === me.courseName){
          return me.courses;
        }

        return me.courses.filter(course => course.name.toLowerCase().match(me.courseName.toLowerCase()))
      }
    },
    methods:{
      createCourse: function(){
        let me = this;
        me.$router.push({
          name: Routes.get('courseCreate').name,
        }).catch(()=>{})
      },
      updateCourse: function(course){
        let me = this;

        me.$router.push({
          name: Routes.get('courseUpdate').name,
          params: {
            courseId: course.uniqid
          }
        }).catch(()=>{})
      }
    },
    props:{
      courses:{
        type: Array,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>
