<template>
   <v-checkbox
     v-model="selected"
     :label="label"
     v-bind:false-value="0"
     v-bind:true-value="1"
     hide-details
   ></v-checkbox>
</template>

<script>
  export default {
    name: 'Checkbox',
    data(){
      return{
        selected: false
      }
    },
    mounted(){
      let me = this;

      if(null !== me.value){
        me.selected = me.value;
      }
    },
    methods:{
      getName: function(){
        let me = this;
        return me.name;
      },
      getFormValue: function(){
        let me = this;
        return me.selected;
      },
      reset: function(){
        let me = this;
        me.selected = false;
      }
    },
    props:{
      label:{
        type: String,
        required: true
      },
      name:{
        type: String,
        required: true
      },
      value:{
        type: [Number, Boolean],
        default: false
      },
    }
  }
</script>

<style scoped>

</style>
