import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const reload = (args) => {
  let params = new URLSearchParams();

  params.append('user', args.user)

  return Api.post(endpoints.get('reload'), params,{
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

export default {
  reload,
};
