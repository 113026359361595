export default {
  get: function(){
    return {
      branch:{},
      course:{},
      formats:{},
      fetching: null,
      certified: null,
      error: null,
      certificate: {},
      certificates: []
    }
  }
}
