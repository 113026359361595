<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row justify="center">
      <v-col cols="12" md="10">
        <UpdateForm
          ref="updateForm"
          mainButtonText="Actualizar"
          colorMainBtn="info"
          formTitle="Actualización de curso"
          :showResetButton="false"
          :courseId="course.current.id"
          :title="course.current.name"
          :uniqid="course.current.uniqid"
          :courseCode="course.current.course_code"
          :courseTypeId="course.current.courseTypeId"
          :hours="course.current.hours"
          :description="course.current.description"
          :credits="course.current.credits"
          :icon="course.current.icon"
          :requires="course.requires"
          :sendingForm="sendingForm"
          @formSend="updateCourse"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import UpdateForm from '@/modules/course/_components/form'

  export default {
    name: 'Update',
    components:{
      UpdateForm
    },
    methods:{
      updateCourse: function(){
        let me = this,
          formComponent = me.$refs['updateForm'],
          values = formComponent.getFormValues();

        me.$emit('formSend', values);
      }
    },
    props:{
      course:{
        type: Object,
        required: true
      },
      sendingForm:{
        type: Boolean,
        required: false
      }
    }
  }
</script>

<style scoped>

</style>
