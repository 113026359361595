<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="achievementName"
          label="Filtrar por nombre de rol"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" class="text-right">
        <v-btn color="success" class="elevation-0" @click.prevent="createAchievement">
          <v-icon class="pr-3">
            fas fa-plus
          </v-icon>
          Crear rol
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10" sm="11">
        Roles
      </v-col>

      <v-col cols="2" sm="1">
        Opciones
      </v-col>
    </v-row>
    <v-divider />

    <v-row v-for="(achievement, index) in filteredAchievements" :key="index">
      <v-col cols="10" sm="11">
        {{achievement.name}}
      </v-col>

      <v-col cols="2" sm="1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              class="elevation-0 mr-5"
              v-bind="attrs"
              v-on="on"
              @click.prevent="openCloneModal(achievement)"
            >
              <v-icon
                color="orange"
                dark
              >
                far fa-clone
              </v-icon>
            </v-btn>
          </template>
          <span>Clonar {{achievement.name}}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              class="elevation-0"
              v-bind="attrs"
              v-on="on"
              @click.prevent="updateAchievement(achievement)"
            >
              <v-icon
                color="orange"
                dark
              >
                fas fa-pen
              </v-icon>
            </v-btn>
          </template>
          <span>Editar {{achievement.name}}</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <CloneModal
      ref="CloneModal"
      :cloning="cloning"
      @cloneAchievement="cloneAchievement"
    />
  </v-container>
</template>

<script>
  import Routes from '@/router/routes'
  import CloneModal from './_components/CloneModal'

  export default {
    name: 'index',
    components: { CloneModal },
    data(){
      return{
        achievementName: ''
      }
    },
    computed:{
      filteredAchievements: function(){
        let me = this;

        if('' === me.achievementName){
          return me.achievements;
        }

        return me.achievements.filter(achievement => achievement.name.toLowerCase().match(me.achievementName.toLowerCase()))
      }
    },
    methods:{
      getCloneModalComponent: function(){
        let me = this;
        return me.$refs['CloneModal'];
      },
      createAchievement: function(){
        let me = this;
        me.$router.push({
          name: Routes.get('achievementCreate').name,
        }).catch(()=>{})
      },
      updateAchievement: function(achievement){
        let me = this;

        me.$router.push({
          name: Routes.get('achievementUpdate').name,
          params: {
            achievementId: achievement.uniqid
          }
        }).catch(()=>{})
      },
      openCloneModal: function(achievement){
        let me = this,
          cloneModal = me.getCloneModalComponent();

        cloneModal.setAchievement(achievement);
        return cloneModal.show();
      },
      cloneAchievement: function(values){
        let me = this;
        me.$emit('cloneAchievement', values);
      }
    },
    props:{
      achievements:{
        type: Array,
        required: true
      },
      cloning:{
        type: Boolean,
        required: false
      }
    }
  }
</script>

<style scoped>

</style>
