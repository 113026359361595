<template>
  <v-main v-if="authenticated" id="dashboard-view">

    <Toolbar/>

    <v-container fluid class="pa-0 main-content">
      <VuePerfectScrollbar class="scroll pa-0">
        <v-container>
          <v-row class="pa-0 ma-0">
            <v-col
              cols="12" md="3"
              v-for="item in items" :key="item.subtitle"
            >
              <Item
                :subtitle="item.subtitle"
                :text="item.text"
                :icon="item.icon"
                :route="item.route"
              />
            </v-col>
          </v-row>
        </v-container>
      </VuePerfectScrollbar>
    </v-container>
  </v-main>
</template>

<script>
  import Toolbar from '@/components/toolbar/index'
  import Routes from '@/router/routes'
  import Item from '@/components/ui/card/CardLink'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Options',
    components:{
      Toolbar,
      Item
    },
    computed:{
      ...mapGetters({
        authenticated: 'services/api/auth/authenticated',
      })
    },
    data(){
      return {
        routes: Routes,
        items:[
          {
            subtitle: 'Listado',
            text: 'Crea, edita o revisa el listado de cursos',
            icon: 'fas fa-poll-h',
            route: Routes.get('courseList')
          },
          {
            subtitle: 'Clonar Módulos',
            text: 'Copiar módulos de un curso a otro',
            icon: 'far fa-clone',
            route: Routes.get('courseCloneModules')
          },
          {
            subtitle: 'Administrar Módulos',
            text: 'Actualizar el estado de módulos de un curso como su posición y su estado (abierto/cerrado)',
            icon: 'fas fa-redo',
            route: Routes.get('courseManageModules')
          },
          {
            subtitle: 'Administrar Exámenes',
            text: 'Actualizar el estado de exámenes de un curso (activar/desactivar y/o eliminar)',
            icon: 'fas fa-file-alt',
            route: Routes.get('courseManageExams')
          },
        ]
      }
    }
  }
</script>

<style scoped>

</style>
