<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <CreateForm
          ref="createForm"
          formTitle="Alta de curso"
          :sendingForm="sendingForm"
          @formSend="createCourse"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import CreateForm from '@/modules/course/_components/form'

  export default {
    name: 'Create',
    components:{
      CreateForm
    },
    methods:{
      createCourse: function(){
        let me = this,
          formComponent = me.$refs['createForm'],
          values = formComponent.getFormValues();

        me.$emit('formSend', values);
      }
    },
    props:{
      sendingForm:{
        type: Boolean,
        required: false
      }
    }
  }
</script>

<style scoped>

</style>
