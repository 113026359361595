<template>
  <v-row>
    <v-col cols="12">
      <v-expansion-panels
        v-model="panel"
        :disabled="disabled"
      >
        <v-expansion-panel @click="loadCourses">
          <v-expansion-panel-header>Filtros de búsqueda</v-expansion-panel-header>
          <v-expansion-panel-content>
            <PageLoader :display="loadingCourses" height="10vh" :size="80" />

            <v-row v-if="false === loadingCourses">
              <v-col cols="12">
                <v-combobox
                  v-model="users"
                  label="Filtrar por mails de alumnos"
                  chips
                  deletable-chips
                  clearable
                  multiple
                  :delimiters="[',', ' ']"
                >
                </v-combobox>
              </v-col>

              <v-col cols="12" class="py-0">
                <GenericAutocomplete
                  ref="filterCourses"
                  :name="info.name"
                  :values="courses"
                  :selectAllDefault="false"
                  :itemText="info.text"
                  :itemValue="info.value"
                  :label="info.label"
                  :hint="info.hint"
                  @change="verifyFilteredCourses"
                />
              </v-col>

              <v-col cols="12" class="py-0">
                <v-checkbox
                  v-model="certified"
                  label="¿Alumnos certificados? (debe seleccionar al menos un curso)"
                  :false-value="0"
                  :true-value="1"
                  :disabled="disableCertified"
                >
                </v-checkbox>
              </v-col>

              <v-col cols="12" class="text-end">
                <v-btn color="primary" @click="applyFilters">Aplicar</v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
  import GenericAutocomplete from '@/components/ui/selection/GenericAutocomplete'
  import PageLoader     from '@/components/ui/loader/PageLoader'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Filters',
    components: { GenericAutocomplete, PageLoader },
    data(){
      return{
        panel: [],
        info:{
          name: 'course',
          text: 'name',
          value: 'code',
          label: 'Curso',
          hint: 'Cursos a filtrar'
        },
        users: [],
        certified: 0,
        disableCertified: false
      }
    },
    computed:{
      ...mapGetters({
        loadingCourses: 'services/api/course/list/fetching',
        courses: 'services/api/course/list/courses'
      })
    },
    methods:{
      loadCourses: function(){
        let me = this;

        if(null === me.loadingCourses){
          me.$store.dispatch('services/api/course/list/fetch');
        }
      },
      applyFilters: function(){
        let me = this;
        me.panel = [];
        setTimeout(function(){ me.$emit('applyFilters'); }, 100);
      },
      getCourseFilterComponent: function(){
        let me = this;
        return me.$refs['filterCourses'];
      },
      /**
       *
       * @param formData FormData
       * @returns {*}
       */
      getValidData: function(formData) {
        let me = this,
          filteredCourses = me.getCourseFilterComponent().getFormValue();

        formData.delete('courses_codes[]')
        formData.delete('users[]')

        if(null !== filteredCourses){
          filteredCourses.map(course => formData.append('courses_codes[]', course.course_code));
        }

        if(me.users.length > 0){
          me.users.map(user => formData.append('users[]', user));
        }

        true === me.disableCertified ? formData.delete('certificate') : formData.set('certificate', me.certified);
      },
      verifyFilteredCourses: function(values){
        let me = this;
        me.disableCertified = values.length === 0;
      }
    },
    props:{
      disabled:{
        type: Boolean,
        required: false
      }
    }
  }
</script>

<style scoped>

</style>
