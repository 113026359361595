import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const fetch = (args) => {
  let params = new URLSearchParams();

  params.append('course', args.course);

  if(typeof args['uniqid'] !== 'undefined'){
    params.append('uniqid', args.uniqid);
  }

//There is no need to make an XHR call, diplomas are of type HTML or PDF.
  return Api.get(endpoints.get('get')+'?'+params.toString());
};

const certificates = (args) => {
  let params = new URLSearchParams();

  params.append('uniqid', args.uniqid);

//There is no need to make an XHR call, diplomas are of type HTML or PDF.
  return Api.get(endpoints.get('certificates')+'?'+params.toString());
};

export default {
  fetch,
  certificates
};
