<template>
  <v-autocomplete
    v-model="selectedValues"
    :items="values"
    :item-value="itemValue"
    :item-text="itemText"
    :hint="hint"
    :label="label"
    :chips="chips"
    multiple
    clearable
    :return-object="returnObject"
    @change="change"
  >
    <template v-slot:prepend-item>
      <v-list-item
        ripple
        @click="toggle"
      >
        <v-list-item-action>
          <v-icon :color="selectedValues.length > 0 ? 'indigo darken-4' : ''">{{ icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Seleccionar todas</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>

    <template v-slot:append-item>
      <v-divider class="mb-2"></v-divider>
      <v-list-item disabled>
        <v-list-item-avatar color="grey lighten-3">
          <v-icon>fas fa-info</v-icon>
        </v-list-item-avatar>

        <v-list-item-content v-if="selectAll">
          <v-list-item-title>Seleccionaste todos los items!</v-list-item-title>
        </v-list-item-content>

        <v-list-item-content v-else-if="selectSome">
          <v-list-item-title>Items seleccionados</v-list-item-title>
          <v-list-item-subtitle>{{ selectedValues.length }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-content v-else>
          <v-list-item-title>
            No se selecciono ningun elemento
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-slot:selection="{ item, index }">
      <v-chip v-if="true === limitDisplayOptions(index)" color="primary">
        <span>{{ item.name }}</span>
      </v-chip>
      <span
        v-if="index === displayedOptions"
        class="grey--text caption"
      >(+{{ selectedValues.length - 1 }} seleccionados)</span>
    </template>
  </v-autocomplete>
</template>

<script>
  export default {
    name: 'GenericAutocomplete',
    data(){
      return {
        selectedValues: []
      }
    },
    computed:{
      selectAll: function(){
        let me = this;
        return me.selectedValues.length === me.values.length;
      },
      selectSome: function(){
        let me = this;
        return me.selectedValues.length > 0 && !me.selectAll;
      },
      icon: function(){
        let me = this;
        if (me.selectAll) return 'fas fa-window-close';
        if (me.selectSome) return 'fas fa-minus-square';
        return 'far fa-square';
      }
    },
    mounted(){
      let me = this;
      if(me.selectAllDefault){
        me.toggle();
      }
    },
    methods:{
      getName: function(){
        let me = this;
        return me.name;
      },
      getFormValue: function(){
        let me = this;

        if(0 === me.selectedValues.length){
          return null;
        }

        let result = [];

        for(let i = 0; i < me.selectedValues.length; i++){
          result.push(me.selectedValues[i]);
        }

        return result;
      },
      toggle: function() {
        let me = this;
        me.$nextTick(() => {
          if (me.selectAll) {
            me.selectedValues = [];
          } else {
            me.selectedValues = me.values.slice()
          }
          me.change();
        })
      },
      change: function(){
        let me = this;
        me.$emit('change', me.selectedValues);
      },
      limitDisplayOptions: function(index){
        let me = this;

        for(let i = 0;i < me.displayedOptions; i++){
          if(index === i){
            return true;
          }
        }

        return false;
      }
    },
    props:{
      name:{
        type: String,
        required: true
      },
      values:{
        type: Array,
        required: true
      },
      itemText:{
        type: String,
        required: true
      },
      itemValue:{
        type: String,
        required: true
      },
      label:{
        type: String,
        required: true
      },
      hint:{
        type: String,
        required: true
      },
      chips:{
        type: Boolean,
        required: false,
        default: true
      },
      selectAllDefault:{
        type: Boolean,
        required: false,
        default: false
      },
      displayedOptions:{
        type: Number,
        required: false,
        default: 4
      },
      returnObject:{
        type: Boolean,
        required: false,
        default: true
      }
    }
  }
</script>

<style scoped>

</style>
