<template>
  <v-card class="pa-1 elevation-0">
    <v-row
      v-for="value in localValues"
      :key="value.name"
      align="center"
      justify="center"
      class="text-center"
    >
      <v-col cols="3" class="text-left score-name mb-6 pl-6">
        {{value.name}}
      </v-col>

      <v-col cols="3">
        <v-text-field v-model="value.min" label="Min" outlined @change="change"></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-text-field v-model="value.max" label="Max" outlined @change="change"></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-menu offset-x offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-btn
              class="elevation-0 pb-3 mb-6"
              :color="value.color"
              x-small
              fab
              v-on="on"
            >
            </v-btn>
          </template>
          <v-color-picker v-model="value.color" hide-inputs @input="change"></v-color-picker>
        </v-menu>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  export default {
    name: 'ScoreThermometer',
    data(){
      return {
        localValues: [],
      }
    },
    mounted(){
      let me = this;

      me.localValues = me.values;
    },
    methods:{
      getValues: function(){
        let me = this;
        return me.localValues;
      },
      change: function(){
        let me = this;
        me.$emit('change', me.localValues);
      }
    },
    props:{
      values:{
        type: Array,
        required: false,
        default: () => {
          return [
            {name: 'ok', color:'#228C22', min: 4.50, max:5.00},
            {name: 'warning', color:'#e79100', min: 4.00, max: 4.49},
            {name:'error', color:'#B00020', min: 0.00, max: 3.99}
          ];
        }
      }
    }
  }
</script>

<style scoped>
  >>>.v-color-picker__dot{
    display: none;
  }
  .score-name{
    text-transform: capitalize;
  }
</style>
