<template>

  <v-main class="text-xs-center animated fadeIn white fill-height">

    <v-container grid-list-md class="pa-0 white">

      <v-layout align-center class="justify-center">

        <v-card v-if="true === showForm" class="elevation-0 card-container">

          <v-toolbar class="title elevation-0" color="primary">
            <img src="@/assets/logo/logo.svg" style="margin:0 10px 0 25px;-webkit-filter: brightness(10);filter: brightness(10);float:left; width:40px;"/>
            <span class="pl-3">ALUMNI</span>
          </v-toolbar>

          <v-card-title class="justify-center text-xs-left">
            Por favor ingresa tu nombre de usuario, te enviaremos un correo para que puedas cambiar tu contraseña.
          </v-card-title>

          <v-card-text>
            <v-text-field
              v-model="username"
              label="Ingresa tu nombre de usuario / email"
              class="body-2"
              v-on:keyup.enter="recaptcha"
            />

            <p class="caption text-xs-left pb-2">
              Este sitio está protegido por reCAPTCHA y se aplica la <a href="https://policies.google.com/privacy" target="_blank">Política de privacidad</a> y los <a href="https://policies.google.com/terms" target="_blank">Términos de servicio</a> de Google
            </p>

            <v-card-actions class="pa-0">
              <v-flex
                v-if="errors.captcha"
                xs12
                class="animated shake error-text"
              >
                <v-icon color="error">ico-adb</v-icon>
                No hemos podido verificar que no eres un robot. Por favor, intente nuevamente
              </v-flex>

              <v-flex
                v-if="errors.optin"
                xs12
                class="animated shake error-text"
              >
                <p>Detectamos que ya has intentado recuperar tu contraseña</p>
                <p>Revisa tu casilla de correo o espera unos minutos para intentar nuevamente.</p>
              </v-flex>
            </v-card-actions>

            <v-card-actions class="justify-center">
              <v-layout row wrap class="text-xs-center justify-center">

                <v-flex xs12 md7>
                    <v-btn
                      block
                      :loading="sending"
                      width="120px"
                      color="primary"
                      v-ripple
                      @mouseover="buttonIcon = 'ico-unlock'"
                      @mouseout="buttonIcon = 'ico-lock'"
                      :disabled="disableSubmit"
                      class="elevation-0"
                      @click.prevent="recaptcha"
                    >
                      Recuperar contraseña
                      <v-icon class="pl-4">
                        {{buttonIcon}}
                      </v-icon>
                    </v-btn>
                </v-flex>

                <v-flex xs12 md7>
                  <v-btn
                    :to="{name:'Login'}"
                    color="secondary"
                    v-ripple
                    block
                    class="elevation-0"
                  >
                    <v-icon left color="white">ico-chevron-left</v-icon> Volver
                  </v-btn>
                </v-flex>

              </v-layout>

            </v-card-actions>
          </v-card-text>
        </v-card>

        <v-card
          v-if="false === showForm"
          class="animated fadeIn card-container elevation-0"
        >

          <v-toolbar class="title elevation-0" color="primary">
            <img src="@/assets/logo/logo.svg" style="margin:0 10px 0 25px;-webkit-filter: brightness(10);filter: brightness(10);float:left; width:40px;"/>
            <span class="pl-3">ALUMNI</span>
          </v-toolbar>

          <v-card-title>
            <h5>Te hemos enviado un correo para que puedas recuperar tu contraseña. Por favor comprueba tu casilla de correo electronico</h5>
          </v-card-title>

          <v-card-text>
            <v-icon
              size="90px"
              class="animated pulse infinite"
              color="primary"
            >
              ico-unlock
            </v-icon>
          </v-card-text>

          <v-card-actions>
            <v-btn
              :to="{name:'Login'}"
              color="secondary"
              v-ripple
              block
              class="elevation-0"
            >
              <v-icon left color="white">ico-chevron-left</v-icon> Volver
            </v-btn>
          </v-card-actions>

        </v-card>

      </v-layout>
    </v-container>

  </v-main>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default{
    name: 'ForgotPasswordView',
    computed:{
      ...mapGetters({
        sending: 'services/api/auth/recoverSending',
        success: 'services/api/auth/recoverSuccess',
        errors: 'services/api/auth/recoverErrors'
      }),
      disableSubmit: function(){
        let me = this;
        return me.username.length < 5;
      }
    },
    data(){
      return {
        siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
        username: '',
        showForm: true,
        buttonIcon: 'ico-lock'
      }
    },
    methods:{
      async recaptcha() {
        let me = this;

        await me.$recaptchaLoaded()
        const token = await me.$recaptcha('login')
        me.submit(token);
      },
      submit: function(token){
        let me = this;

        me.$store.dispatch('services/api/auth/recoverPassword',{
          username: me.username,
          captcha: token
        })
        .then(response => {
          me.showForm = false;
        })
      }
    }
  }
</script>

<style scoped>
  .title{
    font-size: 1.5rem;
    color: white;
  }

  @media (min-width:1024px){
    .card-container {
      border: 1px solid #ccc;
      margin-top: 10%;
    }
  }

</style>
