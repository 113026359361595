export default {
  get() {
    return {
      api:{
        get:{
          fetching: false,
          error: false,
          lang: '',
          values: {}
        },
        put: {

        },
        post:{

        },
        delete:{

        }
      }
    }
  }
}
