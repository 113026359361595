import api from '../api'

const fetch = ({ commit }, args={}) => {
  commit('RESET_STATE');
  commit('FETCH', true);

  return api.fetch(args)
  .then(response => {
    commit('FETCH', false);
    commit('FETCH_SUCCESS', true);
    commit('DATA', response.data);

    return response.data;
  })
  .catch(error => {
    commit('FETCH_SUCCESS', false)
    commit('FETCH_ERROR', error)
    return error;
  })
};

export default {
  fetch
}
