<template>
  <AuthenticatedLayout id="survey-index">

    <template slot="default">
      <v-container fluid class="pa-0">
            <CourseStatsDate />
      </v-container>
    </template>

  </AuthenticatedLayout>
</template>

<script>
  import AuthenticatedLayout from '@/components/ui/view/AuthenticatedLayout'
  import CourseStatsDate     from '@/modules/stats/course'

  export default {
    name: 'SurveyCareerDateView',
    components:{
      AuthenticatedLayout,
      CourseStatsDate
    }
  }
</script>

<style scoped>
  .scroll{
    height: 85vh;
  }
</style>
