<template>
  <v-main v-if="authenticated" id="dashboard-view">

    <Toolbar/>

    <v-container fluid class="pa-0 main-content">
      <VuePerfectScrollbar class="scroll pa-0">
        <v-container>
          <Filters ref="filters" @applyFilters="applyFilters" :disabled="loadingUsers"/>
          <SimplePagination ref="pagination" :pages="pages" @fetch="updateUsers" />
          <PageLoader :display="loadingUsers" />
          <UserList
            v-if="!loadingUsers"
            :users="users"
            :loadingUsers="loadingUsers"
            :reloading="reloading"
            :isMobile="isMobile"
            @reloadAchievements="reloadAchievements"
          />

          <SnackbarMessage
            ref="reloadAchievements"
            :color="snackbar.color"
            :text="snackbar.message"
          />
        </v-container>
      </VuePerfectScrollbar>
    </v-container>
  </v-main>
</template>

<script>
  import UserList       from '@/modules/user/list'
  import Toolbar        from '@/components/toolbar/index'
  import PageLoader     from '@/components/ui/loader/PageLoader'
  import Filters        from '@/modules/user/list/Filters'
  import SimplePagination from '@/components/ui/pagination/SimplePagination'
  import SnackbarMessage from '@/components/ui/snackbar'
  import { mapGetters } from 'vuex'

  export default {
    name: 'List',
    components:{
      SimplePagination,
      UserList,
      PageLoader,
      Toolbar,
      Filters,
      SnackbarMessage
    },
    data(){
      return {
        formData: new FormData(),
        snackbar:{
          message: '',
          color: ''
        }
      }
    },
    computed:{
      ...mapGetters({
        authenticated: 'services/api/auth/authenticated',
        loadingUsers: 'services/api/user/list/fetching',
        users: 'services/api/user/list/users',
        pages: 'services/api/user/list/pages',
        reloading: 'services/api/user/achievement/fetching',
        isMobile: 'services/browser/window/mobile'
      })
    },
    methods:{
      getFilterComponent: function(){
        let me = this;
        return me.$refs['filters'];
      },
      getPaginationComponent: function(){
        let me = this;
        return me.$refs['pagination'];
      },
      applyFilters: function(){
        let me = this;

        me.getFilterComponent().getValidData(me.formData);
        me.getPaginationComponent().reset();
        me.formData.set('page', '1');
        me.fetchUsers();
      },
      fetchUsers: function(){
        let me = this;

        me.$store.dispatch('services/api/user/list/fetch',{
          form: me.formData
        })
      },
      updateUsers: function(page){
        let me = this;

        me.formData.set('page', page);

        me.fetchUsers();
      },
      reloadAchievements: function(user){
        let me = this;
        me.$store.dispatch('services/api/user/achievement/reload', {
          user: user
        })
          .then(response => {
            me.snackbar.message = 'Achievements were recalculated successfully';
            me.snackbar.color = 'success';
            me.$refs['reloadAchievements'].show();
          })
          .catch(error => {
            me.snackbar.message = error.response.data.message;
            me.snackbar.color = 'error';
            me.$refs['reloadAchievements'].show();
          })
      }
    }
  }
</script>

<style scoped>

</style>
