<template>
  <v-main v-if="authenticated" id="dashboard-view">

    <Toolbar/>

    <v-container fluid class="pa-0 main-content">
      <VuePerfectScrollbar class="scroll pa-0">
        <v-container>
          <SystemCacheClear
            :cleaning="cleaning"
            @clearCache="clearCache"
          />

          <SnackbarMessage
            ref="finishMessage"
            color="success"
            :text="message"
          />
        </v-container>
      </VuePerfectScrollbar>
    </v-container>
  </v-main>
</template>

<script>
  import Toolbar        from '@/components/toolbar/index'
  import SystemCacheClear from '@/modules/system/cache/Clear'
  import SnackbarMessage from '@/components/ui/snackbar'

  import { mapGetters } from 'vuex'

  export default {
    name: 'System',
    components:{
      Toolbar,
      SystemCacheClear,
      SnackbarMessage
    },
    computed:{
      ...mapGetters({
        authenticated: 'services/api/auth/authenticated',
        cleaning: 'services/api/system/cache/fetching'
      })
    },
    data(){
      return{
        message: ''
      }
    },
    methods:{
      clearCache: function(formData){
        let me = this;
        me.$store.dispatch('services/api/system/cache/clear', {
          form: formData
        })
          .then(response => {
            me.message = response.data.message;
            me.$refs['finishMessage'].show();
          })
      }
    }
  }
</script>

<style scoped>

</style>
