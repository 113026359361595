import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'animate.css'
import './assets/style/app.scss'

import vuetify from './plugins/vuetify'
import './plugins/axios'

import languageGetters from '@/services/api/language/store/getters'
import AlumniBanner from './plugins/alumni/banner'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Progress from 'vue-multiple-progress'
import FlagIcon from 'vue-flag-icon'
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  loaderOptions: {
    autoHideBadge: true
  }
})

Vue.use(Progress)
Vue.use(FlagIcon);
Vue.use(require('vue-shortkey'))
console.log(AlumniBanner, 'color:#1976d2; background-color:#242424')

Vue.component('VuePerfectScrollbar', VuePerfectScrollbar)

import Vue from 'vue'
import App from './App'
import store from './store'
import Moment from 'moment-timezone'

Vue.config.productionTip = false

Promise.all([
  store.dispatch(
    'services/api/translation/fetchTranslations',
    languageGetters.getCurrentFromCache(),
    true
  ).then(response => {
    store.dispatch(
      'services/api/language/setCurrentLanguage',
      response.lang
    )

    Moment.tz.guess();
    Moment.locale(response.lang);
    axios.defaults.headers.common['Accept-Language'] = response.lang;
  }),
]).then( () => {
  return new Vue({
    vuetify,
    components: {
      App,
    },
    store: store,
    template: '<App/>',
  }).$mount('#app');
});
