export default {
  get: function(){
    return {
      merge:{
        sending: null,
        success: null,
        error: false
      }
    }
  }
}
