<template>
  <v-container fluid>
    <Card class="mb-3" v-for="(item, i) in users" :user="item.user" :key="i">
      <template v-slot:actions>
        <v-btn text color="primary" @click="gotoProfile(item.user.uniqid)">Ver perfil</v-btn>
        <v-btn
          text
          color="warning"
          @click="reloadAchievements(item)"
          :disabled="item.reload"
        >
          Recalcular roles
        </v-btn>
      </template>
    </Card>
  </v-container>
</template>

<script>
  import Card   from './_components/Card'
  import Routes from '@/router/routes'
  export default {
    name: 'index',
    components:{
      Card
    },
    methods:{
      gotoProfile: function(user){
        let me = this;

        me.$router.push({
          name: Routes.get('userProfile').name,
          params: {
            id: user
          }
        }).catch(()=>{})
      },
      reloadAchievements: function(item){
        let me = this;
        item.reload = true;
        me.$emit('reloadAchievements', item.user.userId);
      }
    },
    props:{
      users:{
        type: [Array, String],
        required: true
      }
    }
  }
</script>

<style scoped>

</style>
