<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-btn
          large
          text
          class="elevation-0 mb-6"
          @click.native="$router.go(-1)"
        >
          <v-icon left>fas fa-chevron-left</v-icon>
          Volver
        </v-btn>

        <h1 class="pb-6">Administrador de módulos</h1>
        <v-combobox
          v-model="course"
          :items="courses"
          item-text="name"
          item-value="id"
          label="Curso"
          clearable
          @change="updateModules"
          :disabled="loadingClasses"
        >
        </v-combobox>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col v-if="course" cols="12" md="8" lg="6">
        <v-card class="mb-8">
          <v-card-title>Módulos: {{course.name}}</v-card-title>
          <slot name="avatar"></slot>
          <v-card-text v-if="loadingClasses" class="fill-height">
            <v-row align="center" justify="center" class="fill-height text-center">
              <v-col cols="12">
                <v-progress-circular
                  indeterminate
                  size="40"
                  color="primary"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-else>
<!--            <v-list height="400" style="overflow-y: auto;">
              <v-expansion-panels v-model="panels" multiple>
                <template v-for="(item, i) in modules">
                  <v-expansion-panel>
                    <v-expansion-panel-header v-slot="{ open }" class="py-0">
                      <v-row no-gutters align="center">
                        <v-col cols="1">
                          <v-checkbox
                            v-model="selected"
                            :key="`item-${i}`"
                            :value="item.id"
                            @click.stop
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="11" :md="open ? '' : '6'">
                          {{item.name}}
                        </v-col>
                        <v-col
                          v-if="!open"
                          cols="12"
                          lg="4"
                          class="text&#45;&#45;secondary"
                        >
                          <v-fade-transition leave-absolute>
                            <v-row
                              no-gutters
                              style="width: 100%"
                            >
                              <v-col cols="12" xl="6">
                                Posición: {{ item.number }}
                              </v-col>
                              <v-col cols="12" xl="6">
                                <span v-if="item.status === 'open'" class="success-text">
                                  Clase abierta
                                </span>
                                <span v-else class="error-text">
                                  Clase cerrada
                                </span>
                              </v-col>
                            </v-row>
                          </v-fade-transition>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row
                        justify="space-around"
                        no-gutters
                        align="center"
                      >
                        <v-col cols="3">
                          <v-text-field
                            v-model="item.number"
                            label="Posición"
                            dense
                            type="number"
                            @blur.stop="orderModules"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="7">
                          <v-checkbox
                            v-model="item.status"
                            label="¿Cerrar clase?"
                            false-value="open"
                            true-value="close"
                            @click.stop="recalculatePositions"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </template>
              </v-expansion-panels>
            </v-list>-->
            <v-row v-for="(item, i) in modules" align="center" :key="i">
              <v-col cols="7" class="body-1 black--text">
                <v-checkbox
                  v-model="selected"
                  :key="`item-${i}`"
                  :value="item.id"
                  :label="item.name"
                  @click.stop
                ></v-checkbox>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="item.number"
                  label="Posición"
                  dense
                  type="number"
                  @change="orderModules"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  v-model="item.status"
                  false-value="open"
                  true-value="close"
                  @click.stop="recalculatePositions"
                >
                  <template v-slot:label>
                    <span :class="item.status === 'open' ? 'success-text' : 'error-text'">
                      Clase cerrada
                    </span>
                  </template>

                </v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-row align="center">
          <v-col cols="12">
            <v-btn
              color="error"
              class="mb-2 mr-12"
              width="120"
              @click="deleteModules"
              :disabled="updating"
              :loading="updating"
            >
              Eliminar
            </v-btn>

            <v-btn
              color="primary"
              class="mb-2"
              width="120"
              @click="saveModules"
              :disabled="updating"
              :loading="updating"
            >
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <SnackbarMessage
        ref="snackbar"
        :color="snackbar.color"
        :text="snackbar.message"
      />
    </v-row>
  </v-container>
</template>

<script>
  import SnackbarMessage from '@/components/ui/snackbar'
  import PageLoader from '@/components/ui/loader/PageLoader'
  import { mapGetters } from 'vuex'
  export default {
    name: 'ManageClasses',
    components:{
      SnackbarMessage,
      PageLoader
    },
    data(){
      return{
        course: null,
        panels: [],
        modules: [],
        selected: [],
        snackbar:{
          message: '',
          color: ''
        }
      }
    },
    computed:{
      ...mapGetters({
        loadingClasses: 'services/api/course/classes/fetching',
        updating: 'services/api/course/classes/updating'
      })
    },
    methods:{
      orderModules(){
        this.modules.sort((a, b) => {
          if(a.number < b.number){
            return -1;
          }

          return 1;
        });

        this.panels = [];
        this.selected = [];
        this.recalculatePositions();
      },
      updateModules(){
        if(null === this.course || '' === this.course){
          this.modules = [];
          return;
        }

        this.$store.dispatch('services/api/course/classes/fetch', this.course.uniqid)
          .then(res => {
            this.modules = res;
          })
      },
      recalculatePositions(){
        let current = 0;

        for(let i = 0; i < this.modules.length; i++){
          if(this.modules[i].status === 'close'){
            this.modules[i].number = i === 0 ? i + 1 : i;
            continue;
          }

          current += 1;
          this.modules[i].number = current;
        }
      },
      saveModules(){
        let form = new FormData();

        for(let i = 0; i < this.modules.length; i++){
          let id = this.modules[i].id;
          let classes = ['classes', '[', id, ']'].join('');
          let status = ['statuses', '[', id, ']'].join('');
          form.append(classes, this.modules[i].number);
          form.append(status, this.modules[i].status);
        }

        this.selected = [];
        this.$store.dispatch('services/api/course/classes/update', {
          form: form,
        })
          .then(response => {
            this.updateModules();
            this.snackbar.message = 'Classes was updated succcessfully';
            this.snackbar.color = 'success';
            this.$refs['snackbar'].show();
          })
          .catch(error => {
            this.snackbar.message = error.response.data.message;
            this.snackbar.color = 'error';
            this.$refs['snackbar'].show();
          })
      },
      deleteModules(){
        let form = new FormData();

        if(this.selected.length === 0){
          return
        }

        for(let i = 0; i < this.selected.length; i++){
          form.append('classes[]', this.selected[i]);
        }

        form.append('course', this.course.uniqid);
        this.selected = [];

        this.$store.dispatch('services/api/course/classes/remove', {
          form: form,
        })
          .then(response => {
            this.updateModules();
            this.snackbar.message = response.success ? 'Classes was removed succcessfully' : response.message;
            this.snackbar.color = response.success ? 'success' : 'error';
            this.$refs['snackbar'].show();
          })
      }
    },
    props:{
      courses:{
        type: Array,
        required: true
      }
    }
  }
</script>
