const fetching = state => state.fetching;
const success = state => state.success;
const error = state => state.error;

const achievements = state => state.achievements;

export default {
  fetching,
  success,
  error,
  achievements
};
