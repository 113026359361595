const fetching = state => state.fetching;
const success = state => state.success;
const error = state => state.error;

const types = state => state.types;

export default {
  fetching,
  success,
  error,
  types
};
