<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
      <tr>
        <th></th>
        <th>Carrera</th>
        <th>Totales</th>
        <th>Mes</th>
        <th
          v-for="section in selectedSections"
          :key="section.id"
        >
          {{section.name}}
        </th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="(item, i) in values" :key="i">
        <td>
          <StatsModal
            :title="item.info.name"
            :stats="item.stats"
            :scoreThermometer="scoreThermometer"
          />
        </td>
        <td class="table-career-name">{{item.info.name}}</td>
        <td class="table-small-column">{{getTotals(item.stats)}}</td>
        <td class="table-small-column">{{filters.month}}</td>
        <td
          v-if="item.stats.length === 0"
          v-for="section in selectedSections"
          :key="section.id"
          class="table-column"
        >
          -
        </td>

        <td
          v-if="item.stats.length > 0 && showColumn(value.section)"
          v-for="(value, key) in item.stats"
          :key="key"
          class="table-column"
        >
          <span :style="scoreClass(scoreThermometer, value.average)">{{getSectionAverage(value)}}</span>
          <span class="total-score"> ({{getSectionTotals(value)}})</span>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
  import StatsModal from '../detail/StatsModal'
  import ScoreColor from '@/components/ui/score/mixins/ScoreColor'

  export default {
    name: 'CareerList',
    mixins:[
      ScoreColor
    ],
    components: { StatsModal },
    data(){
      return{
        totalSections: 0,
        details: []
      }
    },
    methods:{
      showColumn: function(section){
        let me = this;

        for(let i = 0; i < me.selectedSections.length; i++){
          if(section.name === me.selectedSections[i].name){
            return true;
          }
        }
        return false;
      },
      getSectionAverage(item){
        let me = this;
        for(let i = 0; i < me.selectedSections.length; i++){
          if(item.section.name === me.selectedSections[i].name){
            return item.average;
          }
        }
      },
      getSectionTotals(section){
        let me = this;

        for(let i = 0; i < me.selectedSections.length; i++){
          if(section.section.name === me.selectedSections[i].name){
            return section.totals;
          }
        }
      },
      getTotals: function(stats){
        let greaterTotals = 0;

        for(let i = 0; i < stats.length; i++){
          if(greaterTotals > stats[i].totals){
            continue;
          }

          greaterTotals = stats[i].totals;
        }

        return greaterTotals;
      }
    },
    props:{
      values:{
        type: Array,
        required: true
      },
      filters:{
        type: Object,
        required: true
      },
      selectedSections:{
        type: Array,
        required: true
      },
      scoreThermometer:{
        type: Array,
        required: true
      }
    }
  }
</script>

<style scoped>
  .table-career-name{
    width: 24%;
  }
  .table-small-column{
    width: 3%;
  }
  .table-column{
    width: 14%;
  }
  .total-score{
    color: #888888;
  }
</style>
