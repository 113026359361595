const ENDPOINT = new Map();

ENDPOINT.set('login', '/auth/authenticate');
ENDPOINT.set('authenticated', '/auth/admin/session');
ENDPOINT.set('refresh', '/auth/refresh');
ENDPOINT.set('logout', '/auth/logout');
ENDPOINT.set('recoverPassword', '/auth/optin/password/recover');
ENDPOINT.set('validateHash', '/auth/optin/validate')
ENDPOINT.set('changePassword', '/auth/optin/password/change');

export default ENDPOINT
