const FETCH = (state, value) => {
  state.fetching = value;
}

const DATA = (state, values) => {
  state.courses = values.course;
  state.sections = values.section;
}

const SUCCESS = (state, value) =>{
  state.courses = value.course;
  state.section = value.section;
}

const ERROR = (state, value) => {
  state.error = value;
}


export default {
  FETCH,
  SUCCESS,
  DATA,
  ERROR
}
