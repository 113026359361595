<template>
  <v-card class="filters">
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <!-- Career Select -->
          <GenericMultipleSelect
            :ref="references.career"
            :name="info.name"
            :values="items"
            :selectAllDefault="true"
            :itemText="info.text"
            :itemValue="info.value"
            :label="info.label"
            :hint="info.hint"
          />
        </v-col>

        <v-col cols="12">
          <v-row align="center" justify="start">
            <v-col cols="6" md="2">
              <YearSelect
                :ref="references.year"
                :serverDate="serverDate"
                @reset="enabled = false"
                @select="enabled = true"
              />
            </v-col>

            <v-col cols="6" md="2">
              <MonthSelect
                :ref="references.month"
                :enabled="enabled"
              />
            </v-col>

            <v-col cols="6" md="4">
              <!-- Section Select -->
              <GenericMultipleSelect
                ref="sectionSelect"
                name="section"
                :values="sections"
                :selectAllDefault="true"
                :displayedOptions="1"
                itemText="name"
                itemValue="name"
                label="Sección"
                hint="Secciones a filtrar"
                @change="changeSection"
              />
            </v-col>
          </v-row>

          <v-row justify="start" align="start">
            <v-col cols="12" sm="3" md="2">
              <v-btn :disabled="!enabled" color="primary" @click.prevent="search">
                Buscar
                <v-icon color="white" class="pl-3">fas fa-search</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" sm="3" md="2">
              <v-btn
                color="secondary"
                @click.prevent="openConfiguration"
                text
              >
                Configuración
                <v-icon class="pl-3" small>fas fa-cog</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import GenericMultipleSelect from './_components/GenericMultipleSelect'
  import YearSelect   from './_components/YearSelect'
  import MonthSelect  from './_components/MonthSelect'

  export default {
    name: 'FilterBox',
    components:{
      MonthSelect,
      YearSelect,
      GenericMultipleSelect,
    },
    data(){
      return {
        references:{
          career: 'field-0',
          year: 'field-1',
          month: 'field-2',
        },
        enabled: false,
      }
    },
    methods:{
      getSectionComponent: function(){
        let me = this;
        return me.$refs['sectionSelect'];
      },
      search: function(){
        let me = this;
        me.$emit('search');
      },
      getValues: function(){
        let me = this,
          count  = 0,
          params = {
          };

        while(typeof me.$refs['field-'+count] !== 'undefined'){
          let field = me.$refs['field-'+count],
            value = field.getFormValue();

          count++;

          if(null === value){
            continue;
          }

          if(me.info.name === field.getName()){
            let codes = [];
            value.map(item => {
              codes.push(item.course_code);
            })
            params[field.getName()] = codes;
            continue;
          }

          params[field.getName()] = value;
        }

        return params;
      },
      openConfiguration: function(){
        let me = this;
        me.$emit('openConfiguration');
      },
      getSections: function(){
        let me = this,
          sectionComponent = me.getSectionComponent();

        return me.orderSections(sectionComponent.getFormValue());
      },
      changeSection: function(sections){
        let me = this,
          orderedSections = me.orderSections(sections);
        me.$emit('changeSections', orderedSections);
      },
      orderSections: function(sections){
        return sections.sort((a, b) => {

          let secA = a.position,
            secB = b.position;

          if(secA < secB){
            return -1;
          }

          return 1;
        });
      }
    },
    props:{
      items:{
        type: Array,
        required: true
      },
      sections:{
        type: Array,
        required: true
      },
      serverDate:{
        type: String,
        required: true
      },
      info:{
        type: Object,
        required: true
      }
    }
  }
</script>

<style scoped>
  .filters{
    overflow: hidden;
  }
</style>
