export default {
  get: function() {
    return {
      fetching: null,
      success: null,
      error: null,
      date: null,
      now: null //Local clock synchronized through setInterval
    }
  }
}
