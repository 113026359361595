import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const ping = () => {
  return Api.get(endpoints.get('ping'));
}

export default {
  ping
};
