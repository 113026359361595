<template>
</template>

<script>
  export default {
    name: 'ScoreColor',
    methods:{
      scoreClass: function(configuration, score){
        let cssClass = [];

        for(let i = 0; i < configuration.length; i++){
          let scoreType = configuration[i];

          if(scoreType.max < score){
            continue;
          }

          if(scoreType.min <= score && score <= scoreType.max){
            cssClass.push("color:" + scoreType.color);
          }
        }

        return cssClass.join(';');
      }
    }
  }
</script>

<style scoped>

</style>
