<template>
  <div>
    <TableList
      v-if="!isMobile"
      :users="users"
      @reloadAchievements="reloadAchievements"
    />
    <CardList
      v-if="isMobile"
      :users="users"
      @reloadAchievements="reloadAchievements"
    />
  </div>
</template>

<script>
  import TableList from './list'
  import CardList from './card'
  export default {
    name: 'index',
    components:{
      TableList,
      CardList
    },
    methods:{
      reloadAchievements: function(user){
        let me = this;
        me.$emit('reloadAchievements', user);
      }
    },
    props:{
      users:{
        type: [Array, String],
        required: true
      },
      isMobile:{
        type: Boolean,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>
