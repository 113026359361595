export default {
  get: function() {
    return {
      fetching: null,
      success: null,
      error: null,
      last: null
    }
  }
}
