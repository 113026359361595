<template>
  <VImg
    :src="theSrc"
    :contain="contain"
    :class="classes"
    :aspect-ratio="aspectRatio"
    :width="width"
    :height="height"
    @error="onError"
    rel="noreferrer"
  />
</template>

<script>
  import VImg from "vuetify/lib/components/VImg"

  export default VImg.extend({
    name: "CourseIcon",
    data() {
      return {
        theSrc: null,
        defaultIcon: "https://static.educacionit.com/alumni/assets/course/generic.svg"
      }
    },
    created() {
      this.theSrc = this.icon || this.defaultIcon
    },
    methods: {
      onError() {
        this.theSrc = this.defaultIcon
      }
    },
    props: {
      icon: {
        type: String,
        required: true,
        default: ""
      },
      classes: {
        type: String,
        default: ""
      }
    }
  })
</script>
