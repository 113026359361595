import Api from '@/services/api/api'
import endpoints from './endpoint'

const fetchTranslations = (language) => {
  let uri = endpoints.get('getTranslations');
  let params = new URLSearchParams();

  if(language){
    params.append('lang',language);
  }

  uri +=  Array.from(params).length > 0 ? '?'+params.toString() : '';
  return Api.get(uri);
}

export default {
  fetchTranslations
};
