import api from '../api'

const fetch = ({ commit }, args={}) => {
  commit('RESET_STATE');
  commit('FETCH', true);

  return api.fetch(args)
    .then(response => {
      commit('FETCH', false);
      commit('FETCH_SUCCESS', true);
      commit('SET_CLASSES', response.data)
      return response.data;
    })
    .catch(error => {
      commit('FETCH', false);
      commit('FETCH_SUCCESS', false)
      commit('FETCH_ERROR', error)
      return error.response.data;
    })
};

const save = ({ commit }, args={}) => {
  commit('RESET_STATE');
  commit('UPDATE', true);

  return api.save(args)
    .then(response => {
      commit('UPDATE', false);

      return response.data;
    })
    .catch(error => {
      commit('UPDATE', false);
      return error.response.data;
    })
};

const remove = ({ commit }, args={}) => {
  commit('RESET_STATE');
  commit('UPDATE', true);

  return api.remove(args)
    .then(response => {
      commit('UPDATE', false);

      return response.data;
    })
    .catch(error => {
      commit('UPDATE', false);
      return error.response.data;
    })
};

const update = ({ commit }, args={}) => {
  commit('RESET_STATE');
  commit('UPDATE', true);

  return api.update(args)
    .then(response => {
      commit('UPDATE', false);

      return response.data;
    })
    .catch(error => {
      commit('UPDATE', false);
      return error.response.data;
    })
};

export default {
  fetch,
  save,
  remove,
  update
}
