import notificationService from './notification'
import windowService from './window'

export default{
  namespaced: true,
  modules: {
    window: windowService,
    notification: notificationService,
  }
}
