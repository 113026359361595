<template>
  <v-container fluid>
    <v-row>
      <v-col cols="10" sm="11">
        Alumnos
      </v-col>
    </v-row>
    <v-divider />

    <v-row v-if="users.length === 0">
      <v-col cols="12">
        No hay resultados
      </v-col>
    </v-row>

    <v-list v-if="users.length > 0">
      <v-list-item
        v-for="(item, i) in users"
        :key="i"
        link
      >
        <v-list-item-avatar>
          <!--<v-img :src="user.avatar"></v-img>-->
        </v-list-item-avatar>

        <v-list-item-content>
          <v-row>
            <v-col cols="4">
              {{item.user.name}} {{item.user.lastname}}
            </v-col>

            <v-col cols="4">
              {{item.user.mail}}
            </v-col>

            <v-col cols="4">
              {{item.user.uniqid}}
            </v-col>
          </v-row>
        </v-list-item-content>

        <v-list-item-action>
          <v-row>
            <v-col cols="3">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    text
                    small
                    class="elevation-0 mr-5"
                    v-bind="attrs"
                    v-on="on"
                    @click="reloadAchievements(item)"
                    :disabled="item.reload"
                  >
                    <v-icon
                      color="warning"
                      dark
                    >
                      fas fa-redo
                    </v-icon>
                  </v-btn>
                </template>
                <span>Recalcular roles</span>
              </v-tooltip>
            </v-col>

            <v-col cols="9">
              <v-btn text color="primary" @click="gotoProfile(item.user.uniqid)">Ver perfil</v-btn>
            </v-col>
          </v-row>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
  import Routes from '@/router/routes'
  export default {
    name: 'index',
    methods:{
      gotoProfile: function(user){
        let me = this;

        me.$router.push({
          name: Routes.get('userProfile').name,
          params: {
            id: user
          }
        }).catch(()=>{})
      },
      reloadAchievements: function(item){
        let me = this;
        item.reload = true;
        me.$emit('reloadAchievements', item.user.userId);
      }
    },
    props:{
      users:{
        type: [Array, String],
        required: true
      }
    }
  }
</script>

<style scoped>

</style>
