import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const serverDate = () => {
  return Api.get(endpoints.get('serverDate'));
};

export default {
  serverDate
};
