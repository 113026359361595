export default {
  get: function () {
    return {
      fetching: null,
      error: null,
      success: null,
      course: {}
    }
  }
}
