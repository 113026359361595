import axios from 'axios'
import store from '@/store'
import xhrError from './store/error'
import settings from '@/settings'
import cache from '@/services/api/cache'

const instance = axios.create({
  baseURL: settings.api.alumni.server,
  withCredentials: true,
  headers: settings.api.alumni.headers
});

const ON_REQUEST_SUCCESS = function(xhr){
  store.dispatch('services/api/api/endRequest');
  return xhr;
}

const ON_REQUEST_ERROR = function(error){
  store.dispatch('services/api/api/endRequest');
  /**
   * If the response returns an invalid token header, logout
   */
  if(typeof error.response.headers['x-alumni-token-invalid'] !== 'undefined') {
    store.dispatch('services/api/auth/dropAuthToken');
  }

  if(!error.response){
    store.dispatch('services/api/api/notifyConnError', xhrError.NO_CONNECTION);
  }

  return Promise.reject(error);
}

instance.interceptors.request.use(function(xhr){
  if(cache.getItem('application/token')){
    xhr.headers['X-Alumni-Token'] = cache.getItem('application/token');
  }

  store.dispatch('services/api/api/doRequest');
  return xhr;
});

instance.interceptors.response.use(ON_REQUEST_SUCCESS, ON_REQUEST_ERROR);

export default instance
