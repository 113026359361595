<template>
  <v-card :class="_class">
    <v-card-text class="pb-0">
      <CardItem
        title="Nombre:"
        :value="user.name + user.lastname"
        titleClass="xs3"
        valueClass="text-xs-right xs9"
      />

      <CardItem
        title="Mail:"
        :value="user.mail"
        titleClass="xs3"
        valueClass="text-xs-right xs9"
      />

      <CardItem
        title="Id único:"
        :value="user.uniqid"
        titleClass="xs3"
        valueClass="text-xs-right xs9"
      />
    </v-card-text>

    <v-card-actions class="justify-center pa-0">
      <slot name="actions"></slot>
    </v-card-actions>

  </v-card>
</template>

<script>
  import CardItem from '@/components/ui/card/Item'

  export default {
    name: "UserCard",
    components:{
      CardItem
    },
    props:{
      user: {
        type: Object,
        required: true
      },
      _class:{
        type: String,
        required: false,
        default: ''
      }
    }
  }
</script>

<style scoped>

</style>
