import api from '../api'

const search = ({ commit }, args) => {
  commit('RESET');
  commit('SEARCHING', true);

  let endpoint = args.trending ? api.trending : api.search;

  return endpoint(args)
  .then(response => {

    commit('SEARCHING', false);
    commit('SUCCESS', true);
    commit('ERROR', null);
    commit('DATA', response.data.results);

    return response.data;
  })
  .catch(error => {

    commit('SEARCHING', false);
    commit('SUCCESS', false);
    commit('ERROR', error);

    return error;
  })
};

export default {
  search
}
