import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const fetch = (args) => {
  let params = new URLSearchParams();
  params.append('course_code', args);
  return Api.get(endpoints.get('get')+'?'+params.toString());
}

const remove = (args) => {
  if(!(args.form instanceof FormData)){
    throw 'Form attribute must be of FormData type'
  }

  return Api.post(endpoints.get('remove'), args.form,{
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

const update = (args) => {
  if(!(args.form instanceof FormData)){
    throw 'Form attribute must be of FormData type'
  }

  return Api.post(endpoints.get('update'), args.form,{
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

export default {
  fetch,
  remove,
  update
};
