<template>
</template>

<script>
	export default{
		name:"ModalMixin",
		data(){
			return {
				mixinUiModalVisible: false,
			}
		},
		methods:{
			show:function(value = true){
				let me = this;
				me.mixinUiModalVisible = !!value;
        me.$emit('show', me.mixinUiModalVisible);
			},
			hide: function(value = true){
				let me = this;
				me.mixinUiModalVisible = !value;
				me.$emit('hide', me.mixinUiModalVisible);
			},
			toggle:function(){
				let me = this;
				me.mixinUiModalVisible = !!me.mixinUiModalVisible;
        me.$emit('toggle', me.mixinUiModalVisible);
			}

		}
	}
</script>
