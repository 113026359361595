const FETCHING = (state, value) => {
  state.fetching = !!value;
}

const LAST = (state, value) => {
  state.last = value;
}

const SUCCESS = (state, value) => {
  state.success = !!value;
}

const ERROR = (state, error) => {
  state.error = error;
}

export default{
  FETCHING,
  SUCCESS,
  ERROR,
  LAST
}
