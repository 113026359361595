<template>
  <div id="app">
    <v-app>
      <NoConnection />
      <router-view></router-view>
    </v-app>
  </div>
</template>

<script>
  import Router from './router'
  import NoConnection from '@/components/ui/connection/NoConnection'

  import Settings from '@/settings'

  import LightTheme from '@/plugins/vuetify/theme/light.js'

  import { mapGetters } from 'vuex'

  export default {
    name: 'App',
    router: Router,
    components:{
      NoConnection
    },
    computed:{
      ...mapGetters({
        authenticated: 'services/api/auth/authenticated'
      })
    },
    created(){
      let me = this;
       me.$vuetify.theme.themes.light.primary = LightTheme.primary;

    },
    mounted(){
      let me = this,
        preloader = document.querySelector('#app--preloader');

      /**
       * Remove the application preloader, this preloader lays in the index.html page and has no relation
       * with the application at all.
       */
      if(preloader){
        preloader.remove();
      }

      /**
       * Init browser window events (Checks for viewport resize / detects mobile client)
       */
      me.$store.dispatch('services/browser/window/init');

    },
    watch:{
      authenticated: function(n, o){
        let me = this,
          url = new URL(window.location.href);

        /**
         * If it's not authenticated or the url path name is '/' (login) return
         */
        if(false === n || url.pathname === '/') {

          return;

        }
        /**
         * Obtain server date and start timer to have an accurate client date
         */
        me.$store.dispatch('services/api/server/date/fetch');

        /**
         * Ask for notification privilege (if supported)
         */
        me.$store.dispatch('services/browser/notification/init');

        /**
         * Keep the session alive
         */
        me.$store.dispatch(
          'services/api/auth/keepAlive',
          {
            interval: Settings.api.alumni.session.keepAliveMinutes
          }
        );
      }
    }
  }
</script>
