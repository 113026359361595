<template>
  <v-select
    v-model="year"
    :items="years"
    hint="Año"
    label="Año"
    :return-object="true"
    @change="change"
  />
</template>

<script>
  export default {
    name: 'YearSelect',
    data(){
      return {
        year: null,
      }
    },
    computed:{
      years:function(){
        let me = this,
          date = new Date(me.serverDate),
          maxYear = date.getFullYear(),
          minYear = maxYear - 5,
          result = [];

        for(let i = minYear; i <= maxYear; i++){
          result.push(i);
        }

        return result;
      }
    },
    methods:{
      getName: function(){
        return 'year';
      },
      getFormValue: function(){
        let me = this;
        return me.year;
      },
      change: function(){
        let me = this;

        if(null === me.year){
          return me.$emit('reset');
        }

        return me.$emit('select');
      }
    },
    props:{
      serverDate:{
        type: String,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>
