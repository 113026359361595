const fetching = state => state.fetching;
const success = state => state.success;
const error = state => state.error;

const courses = state => state.courses;
const surveys = state => state.surveys;
const sections = state => state.sections;

export default {
  fetching,
  success,
  error,
  courses,
  surveys,
  sections
};
