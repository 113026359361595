<template>
  <v-textarea
    v-model="formValue"
    :label="label"
    :required="required"
    :value="value"
    :outlined="outlined"
    :rules="true === required ? rules : []"
    clearable
  ></v-textarea>
</template>

<script>
  export default {
    name: 'TextArea',
    data(){
      return{
        formValue: null,
        rules: [
          value => !!value || 'Campo requerido.',
        ]
      }
    },
    mounted(){
      let me = this;

      if('' !== me.value){
        me.formValue = me.value;
      }
    },
    methods:{
      getName: function(){
        let me = this;
        return me.name;
      },
      getFormValue: function(){
        let me = this;
        return me.formValue;
      },
      reset: function(){
        let me = this;
        me.formValue = null;
      }
    },
    props:{
      label:{
        type: String,
        required: true
      },
      name:{
        type: String,
        required: true
      },
      required:{
        type: Boolean,
        default: false
      },
      value:{
        type: String,
        default: ''
      },
      outlined:{
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style scoped>

</style>
