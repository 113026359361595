const fetching = state => state.fetching;
const success = state => state.success;
const error = state => state.error;

const careers = state => state.careers;
const branches = state => state.branches;
const surveys = state => state.surveys;
const sections = state => state.sections;

export default {
  fetching,
  success,
  error,
  careers,
  branches,
  surveys,
  sections
};
