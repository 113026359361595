<template>
  <v-main v-if="authenticated" id="dashboard-view">

    <Toolbar/>

    <PageLoader :display="loadingAchievements" />

    <v-container v-if="false === loadingAchievements" fluid class="pa-0 main-content">
      <VuePerfectScrollbar class="scroll pa-0">
        <v-container>
          <AchievementList
            ref="AchievementList"
            :achievements="achievements"
            :cloning="cloning"
            @cloneAchievement="cloneAchievement"
          />

          <SnackbarMessage
            ref="finishMessage"
            :text="message"
            :color="color"
          />
        </v-container>
      </VuePerfectScrollbar>
    </v-container>
  </v-main>
</template>

<script>
  import AchievementList from '@/modules/achievement/list'
  import SnackbarMessage from '@/components/ui/snackbar'
  import Toolbar         from '@/components/toolbar/index'
  import PageLoader      from '@/components/ui/loader/PageLoader'
  import Routes from '@/router/routes'

  import { mapGetters } from 'vuex'

  export default {
    name: 'Achievement',
    components:{
      AchievementList,
      SnackbarMessage,
      PageLoader,
      Toolbar
    },
    computed:{
      ...mapGetters({
        authenticated: 'services/api/auth/authenticated',
        loadingAchievements: 'services/api/achievement/list/fetching',
        cloning: 'services/api/achievement/achievement/fetching',
        achievements: 'services/api/achievement/list/achievements'
      })
    },
    data(){
      return{
        message: '',
        color: ''
      }
    },
    mounted(){
      let me = this;
      me.$store.dispatch('services/api/achievement/list/fetch');
    },
    methods:{
      cloneAchievement: function(values){
        let me = this;

        me.$store.dispatch('services/api/achievement/achievement/clone', values)
          .then(response => {
            me.color = true === response.success ? 'success' : 'red darken-2';
            me.message = response.message;
            me.$refs['finishMessage'].show();

            if(!response.success){
              return;
            }

            setTimeout(function(){
              me.$router.push({
                name: Routes.get('achievementUpdate').name,
                params: {
                  achievementId: values.get('achievement')
                }
              }).catch(()=>{})
            }, 500);
          });
      }
    }
  }
</script>

<style scoped>

</style>
