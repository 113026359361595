const fetching = state => state.fetching;
const success = state => state.success;
const error = state => state.error;
const course = state => state.course;

export default {
  fetching,
  success,
  error,
  course
};
