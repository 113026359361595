<template>
  <v-select
    v-model="selectedValue"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :label="label"
    :required="required"
    :outlined="outlined"
    :loading="loading"
  >
  </v-select>
</template>

<script>
  export default {
    name: 'SelectField',
    data(){
      return{
        selectedValue: null,
      }
    },
    mounted(){
      let me = this;

      if(null !== me.value){
        me.selectedValue = me.value;
      }
    },
    methods:{
      getName: function(){
        let me = this;
        return me.name;
      },
      getFormValue: function(){
        let me = this;
        return me.selectedValue;
      },
      reset: function(){
        let me = this;
        me.selectedValue = null;
      }
    },
    props:{
      loading: {
        type: Boolean,
        required: false,
        default: true
      },
      items: {
        type: Array,
        required: false,
        default: []
      },
      label:{
        type: String,
        required: true
      },
      name:{
        type: String,
        required: true
      },
      required:{
        type: Boolean,
        default: false
      },
      value:{
        type: [String, Number],
        default: null
      },
      outlined:{
        type: Boolean,
        default: true
      },
      itemText: {
        type: String,
        required: false,
        default: 'name'
      },
      itemValue: {
        type: String,
        required: false,
        default: 'id'
      }
    }
  }
</script>

<style scoped>

</style>
