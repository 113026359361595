import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const fetch = (args) => {
  let params = new URLSearchParams();
  params.append('course', args);
  return Api.get(endpoints.get('get')+'?'+params.toString());
}

export default {
  fetch
};
