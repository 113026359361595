<template>
  <v-form>
    <v-container fluid class="pa-0 ma-0">
      <v-row>
        <v-col cols="12">
          <v-btn
            large
            text
            class="elevation-0"
            @click.native="$router.go(-1)"
          >
            <v-icon left>fas fa-chevron-left</v-icon>
            Volver
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="11">
          <h2>{{formTitle}}</h2>

          <v-divider />
        </v-col>

        <v-col cols="12">
          <v-row>
            <v-col cols="11">
              <TextField
                ref="field-0"
                name="title"
                label="Título del curso"
                :required="true"
                :value="title"
                @changeText="courseTitle"
              />
            </v-col>

            <v-col cols="1">
              <v-icon color="error" size="15">fas fa-asterisk</v-icon>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="11">
              <TextField
                ref="field-1"
                name="course_code"
                label="Código del curso"
                :required="true"
                :value="courseCode"
                @changeText="changeCourseCode"
              />
            </v-col>

            <v-col cols="1">
              <v-icon color="error" size="15">fas fa-asterisk</v-icon>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="11">
              <SelectField
                ref="field-2"
                name="courseTypeId"
                label="Tipo de curso"
                :items="courseTypes"
                :required="true"
                :value="courseTypeId || 1"
                :loading="loadingTypes"
              />
            </v-col>

            <v-col cols="1">
              <v-icon color="error" size="15">fas fa-asterisk</v-icon>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="11">
          <v-row>
            <v-col v-if="null !== icon" cols="1">
              <v-avatar size="55">
                <img :src="icon" />
              </v-avatar>
            </v-col>

            <v-col :cols="null === icon ? '12' : '11'">
              <FileInput
                ref="field-5"
                name="icon"
                label="Ícono del curso"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="11">
          <TextField
            ref="field-3"
            name="uniqid"
            label="SEO url"
            :value="uniqid"
          />

          <TextArea
            ref="field-4"
            name="description"
            label="Descripción del curso"
            :value="description"
          />
        </v-col>

        <v-col cols="11">
          <TextField
            ref="field-6"
            name="hours"
            label="Duración del curso"
            fieldType="number"
            :value="hours"
          />

          <TextField
            ref="field-7"
            name="credits"
            label="IT Créditos"
            fieldType="number"
            :value="credits"
          />

        </v-col>

        <v-col cols="11">
          <Comparison
            ref="CourseRequirement"
            title="Cursos"
            secondaryTitle="Cursos requeridos"
            :withOptions="false"
            :items="requires"
            :has-specs="false"
          />
        </v-col>
      </v-row>



      <v-row justify="end" class="main-btn">
        <v-col cols="12">
          <v-btn
            @click.prevent="buttonClick"
            :color="colorMainBtn"
            class="mr-5 elevation-0 form-button"
            :disabled="false === courseHasTitle || false === courseHasCode"
          >
            <v-progress-circular
              v-if="sendingForm"
              indeterminate
              color="white"
              size="20"
            ></v-progress-circular>

            <div v-if="!sendingForm">{{mainButtonText}}</div>
          </v-btn>

          <v-btn
            v-if="showResetButton"
            @click.prevent="reset"
            color="warning"
            class="elevation-0 form-button"
          >
            Limpiar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
  import TextField from './_components/TextField'
  import TextArea from './_components/TextArea'
  import SelectField from './_components/SelectField'
  import Checkbox from './_components/Checkbox'
  import DatePicker from './_components/DatePicker'
  import FileInput from './_components/FileInput'
  import Comparison from '@/components/comparison/Table'
  import {mapGetters} from "vuex";
  import PageLoader from '@/components/ui/loader/PageLoader'

  export default {
    name: 'index',
    components: {
      FileInput,
      DatePicker,
      Checkbox,
      SelectField,
      TextArea,
      TextField,
      Comparison,
      PageLoader
    },
    data(){
      return{
        courseHasTitle: false,
        courseHasCode: false
      }
    },
    computed:{
      ...mapGetters({
        loadingTypes: 'services/api/course/type/fetching',
        courseTypes: 'services/api/course/type/types'
      })
    },
    mounted(){
      let me = this;

      me.$store.dispatch('services/api/course/type/fetch');

      if(null !== me.title && null !== me.courseCode){
        me.courseTitle(me.title);
        me.changeCourseCode(me.courseCode)
      }
    },
    methods:{
      courseTitle: function(value){
        let me = this;

        if(null === value || '' === value){
          me.courseHasTitle = false;
          return;
        }

        me.courseHasTitle = true;
      },
      changeCourseCode: function(value){
        let me = this;

        if(null === value || '' === value){
          me.courseHasCode = false;
          return;
        }

        me.courseHasCode = true;
      },
      buttonClick: function(){
        let me = this;
        me.$emit('formSend');
      },
      getCourseRequirementComponent: function(){
        let me = this;
        return me.$refs['CourseRequirement']
      },
      getFormValues: function(){
        let me = this,
          count  = 0,
          form = new FormData(),
          requirements = me.getCourseRequirementComponent().getAccepted();

        while(typeof me.$refs['field-'+count] !== 'undefined'){
          let field = me.$refs['field-'+count],
            name = field.getName(),
            value = field.getFormValue();

          count++;

          if(null === value){
            continue;
          }

          form.append(name, value);
        }

        if(null !== me.courseId){
          form.append('id', me.courseId);
        }

        for(let i = 0; i < requirements.length; i++){
          form.append('requirements[]', requirements[i].id);
        }

        return form;
      },
      reset: function() {
        let me = this,
          count  = 0;

        while(typeof me.$refs['field-'+count] !== 'undefined'){
          let field = me.$refs['field-'+count];
          field.reset();
          count++;
        }
        me.courseHasTitle = false;
        me.courseHasCode = false;
      }
    },
    props:{
      formTitle:{
        type: String,
        required: true
      },
      sendingForm:{
        type: Boolean,
        required:false
      },
      colorMainBtn:{
        type: String,
        default: 'success'
      },
      mainButtonText:{
        type: String,
        default: 'Crear'
      },
      showResetButton:{
        type: Boolean,
        default: true
      },
      courseId:{
        type: Number,
        required: false
      },
      title:{
        type: String,
        default: null
      },
      courseCode:{
        type: String,
        default: null
      },
      courseTypeId:{
        type: Number,
        default: null
      },
      uniqid:{
        type: String,
        default: null
      },
      description:{
        type: String,
        default: null
      },
      icon:{
        type: String,
        default: null
      },
      hours:{
        type: Number,
        default: null
      },
      credits:{
        type: Number,
        default: null
      },
      requires:{
        type: Array,
      }
    }
  }
</script>

<style scoped>
  .main-btn{
    position: fixed;
    bottom: 15px;
    right: 40px;
  }
  .form-button{
    width: 120px;
  }
</style>
