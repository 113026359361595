<template>
  <div class="sidebar-nav-link">
    <v-list>

      <!-- My Profile on mobile -->
      <v-list-group
        class="hidden-md-and-up"
        prepend-icon="fas fa-user"
        @click="groupClick"
      >

        <template v-slot:activator="{ on }">
          <v-list-item class="pa-0">
            <v-list-item-title>Mi Cuenta</v-list-item-title>
          </v-list-item>
        </template>

        <v-list-group
          no-action
          sub-group
          prepend-icon="fas fa-cog"
        >
          <template v-slot:activator="{ on }">
            <v-list-item :to="{name: 'profileSettings'}">
              <v-list-item-title>Configuración</v-list-item-title>
            </v-list-item>
          </template>
        </v-list-group>

      </v-list-group>

      <!-- Logout on mobile -->
      <v-list-item class="hidden-md-and-up" :to="{name: 'Logout'}">
        <v-list-item-action class="sidebar-icons" @click.prevent="groupClick">
          <v-icon color="red">fas fa-power-off</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Salir</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>
  </div>
</template>

<script>
  export default {
    name: 'Nav',
    data(){
      return {
        groupsExpanded: false,
        expand: false
      }
    },
    methods:{
      groupClick: function(){
        let me = this;
        if(true === me.sidebarExpanded) return;
        me.$emit('groupClick');
      },
      expandGroups: function(value = false){
        let me = this;
        me.groupsExpanded = value;
      },
      toggle: function(){
        let me = this;
        me.expand = !me.expand;
        me.expandGroups(me.expand);
        me.$emit('toggle', me.expand);
      }
    },
    props:{
      user: {
        type: Object,
        required: true
      },
      sidebarExpanded:{
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style>
  .highlighted{
    background-color: #b3d4fc;
  }

  .highlighted .sidebar-icons{
    color:#fff;
  }

  .sidebar-icons{
    justify-content:start !important;
    font-size:7rem;
  }
</style>
