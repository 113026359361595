<template>
  <v-text-field
    v-model="formValue"
    :label="label"
    :required="required"
    :value="value"
    :type="fieldType"
    :outlined="outlined"
    :rules="true === required ? rules : []"
    @input="requiredFieldChange"
    clearable
  >
  </v-text-field>
</template>

<script>
  export default {
    name: 'TextField',
    data(){
      return{
        formValue: null,
        rules: [
          value => !!value || 'Campo requerido.',
        ]
      }
    },
    mounted(){
      let me = this;

      if(null !== me.value){
        me.formValue = me.value;
      }
    },
    methods:{
      requiredFieldChange: function(){
        let me = this;

        if(false === me.required){
          return;
        }

        me.$emit('changeText', me.formValue);
      },
      getName: function(){
        let me = this;
        return me.name;
      },
      getFormValue: function(){
        let me = this;
        return me.formValue;
      },
      reset: function(){
        let me = this;
        me.formValue = null;
      }
    },
    props:{
      label:{
        type: String,
        required: true
      },
      name:{
        type: String,
        required: true
      },
      required:{
        type: Boolean,
        default: false
      },
      value:{
        type: [String, Number],
        default: null
      },
      fieldType:{
        type: String,
        default: 'text'
      },
      outlined:{
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style scoped>

</style>
