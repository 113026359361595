<template>
  <v-row class="pa-0 ma-0">
    <v-col
      cols="12" md="3"
      v-for="item in items" :key="item.subtitle"
    >
    <Item
      :subtitle="item.subtitle"
      :text="item.text"
      :icon="item.icon"
      :route="item.route"
    />
    </v-col>
  </v-row>
</template>

<script>
  import Routes from '@/router/routes'
  import Item from '@/components/ui/card/CardLink'

  export default {
    name: 'DashboardNavigationModule',
    components:{
      Item
    },
    data(){
      return {
        routes: Routes,
        items:[
          {
            subtitle: 'Estadisticas',
            text: 'Obtener información de distintas encuestas, filtrando la información recolectada mediante diversos filtros',
            icon: 'fas fa-poll-h',
            route: Routes.get('stats')
          },
          {
            subtitle: 'Cursos',
            text: 'Revisa las distintas opciones para los cursos',
            icon: 'fas fa-poll-h',
            route: Routes.get('courseOptions')
          },
          {
            subtitle: 'Alumnos',
            text: 'Revisa las distintas opciones para alumnos',
            icon: 'fas fa-users',
            route: Routes.get('userOptions')
          },
          {
            subtitle: 'Roles',
            text: 'Crea, edita o revisa el listado de roles',
            icon: 'fas fa-trophy',
            route: Routes.get('achievementList')
          },
          {
            subtitle: 'Sistema',
            text: 'Opciones del sistema',
            icon: 'fas fa-cogs',
            route: Routes.get('system')
          }
        ]
      }
    }
  }
</script>

<style scoped>

</style>
