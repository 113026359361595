import Vue from 'vue'
import VueRouter from 'vue-router'
import Routes from './routes'
import Store from '@/store'

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: Array.from(Routes.values())
});

router.beforeEach(

  (to, from, next) => {

    Store.dispatch('services/api/auth/isAuthenticated')
    .then(xhr => {
      let url = new URL(window.location.href),
        path = url.pathname.replace(/^\/|\/$/g, ''),
        redirect = url.searchParams.get('redir'),
        isAuthenticated = xhr.status === 200;

      if('/' === url.pathname && true === isAuthenticated){
        return window.location.href= redirect ? redirect : '/dashboard';
      }

      if(false === to.meta.requiresAuth){
         return next();
      }

      if(false === isAuthenticated && true === to.meta.requiresAuth){
        /**
         * If the router is used here it enters in an infinite loop
         * @TODO try to use the router instead of window.location.href
         */
        return window.location.href= ('logout' === path || '' === path) ? '/' : '/?redir='+path;
      }

      if(true === to.meta.history){
        Store.dispatch('services/api/router/history/add', {from: from, to:to, next:next});
      }

      return next();
    })
    .catch((error) => {
      console.error(error);
    })
  }

)

export default router
