<template>
  <v-main v-if="authenticated" id="dashboard-view">

    <Toolbar/>

    <v-container fluid class="pa-0 main-content">
      <VuePerfectScrollbar class="scroll pa-0">
        <v-container>
          <UserMergeAccounts
            ref="userMergeAccountsComponent"
            @incompleteForm="incompleteForm"
            @mergeAccounts="mergeAccounts"
          />

          <v-dialog
            v-model="modal"
            persistent
            width="300"
          >
            <v-card
              color="primary"
              dark
            >
              <v-card-text>
                Por favor, aguarde unos instantes mientras se unifican las cuentas
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>

          <SnackbarMessage
            ref="finishMessage"
            :color="snackbar.color"
            :text="snackbar.message"
          />
        </v-container>
      </VuePerfectScrollbar>
    </v-container>
  </v-main>
</template>

<script>
  import Toolbar        from '@/components/toolbar/index'
  import UserMergeAccounts from '@/modules/user/account/Merge'
  import SnackbarMessage from '@/components/ui/snackbar'

  import { mapGetters } from 'vuex'

  export default {
    name: 'MergeAccounts',
    components:{
      Toolbar,
      UserMergeAccounts,
      SnackbarMessage
    },
    computed:{
      ...mapGetters({
        authenticated: 'services/api/auth/authenticated'
      })
    },
    data(){
      return{
        snackbar:{
          message: '',
          color: ''
        },
        modal: false
      }
    },
    methods:{
      incompleteForm: function(){
        let me = this;

        me.snackbar.message = 'Faltan completar datos del formulario';
        me.snackbar.color = 'error';
        me.$refs['finishMessage'].show();
      },
      mergeAccounts: function(values){
        let me = this;
        me.modal = true;

        me.$store.dispatch('services/api/user/merger/merge', values)
          .then(response => {
            me.$refs['userMergeAccountsComponent'].reset();
            me.modal = false;
            me.snackbar.message = 'Accounts merge successfully!';
            me.snackbar.color = 'success';
            me.$refs['finishMessage'].show();
          })
          .catch(error => {
            me.modal = false;
            me.snackbar.message = error.response.data.message;
            me.snackbar.color = 'error';
            me.$refs['finishMessage'].show();
          })
      }
    }
  }
</script>

<style scoped>

</style>
