import api from '../api'

const fetch = ({ commit, rootGetters }) => {
  commit('FETCHING', true);

  return api.ping()
    .then(response => {
      commit('FETCHING', false);
      commit('SUCCESS', true)
      commit('ERROR', null);
      commit('LAST', rootGetters['services/api/server/date/now'])
    })
    .catch(error => {
      commit('FETCHING', false);
      commit('SUCCESS', false)
      commit('ERROR', error);
    })
};

export default {
  fetch
}
