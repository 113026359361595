<template>
  <v-dialog
    v-model="dialog"
    max-width="290"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="picker"
        :label="label"
        outlined
        readonly
        clearable
        v-bind="attrs"
        v-on="on"
      >
      </v-text-field>
    </template>
    <v-date-picker
      v-model="picker"
      elevation="5"
      @input="dialog = false"
    ></v-date-picker>
  </v-dialog>
</template>

<script>
  export default {
    name: 'DatePicker',
    data(){
      return{
        picker: null,
        dialog: false
      }
    },
    mounted(){
      let me = this;

      if(null !== me.value){
        me.picker = me.value;
      }
    },
    methods:{
      getName: function(){
        let me = this;
        return me.name;
      },
      getFormValue: function(){
        let me = this;
        return me.picker;
      },
      reset: function(){
        let me = this;
        me.picker = null;
      }
    },
    props:{
      label:{
        type: String,
        required: true
      },
      name:{
        type: String,
        required: true
      },
      value:{
        type: String,
        default: null
      }
    }
  }
</script>

<style scoped>

</style>
