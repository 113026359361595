<template>
  <AuthenticatedLayout id="stats-index">

    <template slot="default">
      <v-container fluid class="pa-0">
        <StatsNavigation />
      </v-container>
    </template>

  </AuthenticatedLayout>
</template>

<script>
  import AuthenticatedLayout from '@/components/ui/view/AuthenticatedLayout'
  import StatsNavigation     from '@/modules/stats/Navigation'

  export default {
    name: 'StatsView',
    components:{
      AuthenticatedLayout,
      StatsNavigation
    }
  }
</script>

<style scoped>

</style>
