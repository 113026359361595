<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-btn
          large
          text
          class="elevation-0"
          @click.native="$router.go(-1)"
        >
          <v-icon left>fas fa-chevron-left</v-icon>
          Volver
        </v-btn>
      </v-col>

      <v-col cols="12" md="8">

        <v-text-field
          v-model="achievementName"
          label="Nombre del rol"
          outlined
          clearable
        >
        </v-text-field>

        <v-text-field
          v-model="achievementVersion"
          label="Versión (Opcional)"
          outlined
          clearable
        >
        </v-text-field>

        <v-checkbox
          v-model="achievementActive"
          label="¿Desea que la versión sea la activa para dicho rol?"
        ></v-checkbox>
      </v-col>

      <v-col cols="12" md="8">
        <v-btn
          @click.prevent="createAchievement"
          color="success"
          class="mr-5 elevation-0 form-button"
          :disabled="!achievementName || sendingForm"
        >
          <v-progress-circular
            v-if="sendingForm"
            indeterminate
            color="white"
            size="20"
          ></v-progress-circular>

          <div v-if="!sendingForm">Crear Rol</div>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Create',
    data(){
      return{
        achievementName: null,
        achievementVersion: '1.0.0',
        achievementActive: true
      }
    },
    methods:{
      createAchievement: function(){
        let me = this,
          form = new FormData();

        form.append('name', me.achievementName);
        form.append('active', me.achievementActive);

        if('' !== me.achievementVersion){
          form.append('version', me.achievementVersion);
        }

        me.$emit('formSend', form);
      }
    },
    props:{
      sendingForm:{
        type: Boolean,
        required: false
      }
    }
  }
</script>

<style scoped>

</style>
