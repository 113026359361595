import initialState from './state'

const RESET_STATE = (state) => {
  let iState = initialState.get();
  iState.fetching = state.fetching;
  iState.updating = state.updating;
  Object.assign(state, iState);
};

const FETCH = (state, value) => {
  state.fetching = value;
}

const UPDATE = (state, value) => {
  state.updating = value;
}

const FETCH_SUCCESS = (state, value) =>{
  state.stats = value;
}

const FETCH_ERROR = (state, value) => {
  state.error = value;
}

const SET_CLASSES = (state, data) => {
  state.list = data;
}

export default {
  RESET_STATE,
  FETCH,
  UPDATE,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SET_CLASSES
}
