const date = state => state.date;
const success = state => state.success;
const fetching = state => state.fetching;
const now = state => state.now;
const error = state => state.error;

export default {
  date,
  success,
  fetching,
  now,
  error
}
