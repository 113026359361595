<template>
  <v-row>
    <v-col cols="12" md="5">
      <v-card max-height="500">
        <v-card-title class="pb-0">{{title}}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="searchItem"
            label="Buscar..."
          ></v-text-field>

          <v-list height="350" style="overflow-y: auto;">
            <v-list-item-group
              v-model="selected"
              multiple
            >
              <template v-for="(item, i) in filteredItems">
                <v-list-item
                  :key="`item-${i}`"
                  :value="item.id"
                  active-class="primary--text text--accent-4"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-avatar v-if="item.icon">
                      <CourseIcon :icon="item.icon" :contain="true" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name" class="text-wrap"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" md="2" class="align-self-center">
      <v-row class="text-center">
        <v-col cols="12">
          <v-btn color="primary" class="elevation-0" @click="acceptLeft">
            <v-icon>fas fa-arrow-right</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn color="warning" class="elevation-0" @click="rejectRight">
            <v-icon>fas fa-arrow-left</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" md="5">
      <v-card class="fill-height">
        <v-card-title>{{secondaryTitle}}</v-card-title>
        <slot name="avatar"></slot>
        <v-card-text>
          <v-list height="400" style="overflow-y: auto;">
            <v-expansion-panels
              v-if="withOptions"
              multiple
            >
              <template v-for="(item, i) in accepted">
                <v-expansion-panel>
                  <v-expansion-panel-header v-slot="{ open }" class="py-0">
                    <v-row no-gutters align="center">
                      <v-col cols="1">
                        <v-checkbox
                          v-model="rejected"
                          :key="`item-${i}`"
                          :value="item.id"
                          @click.native.stop
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="11" :md="open ? '' : '6'">
                        {{item.name}}
                      </v-col>
                      <v-col
                        v-if="!open"
                        cols="12"
                        lg="4"
                        class="text--secondary"
                      >
                        <v-fade-transition leave-absolute>
                          <v-row
                            no-gutters
                            style="width: 100%"
                          >
                            <v-col cols="12" xl="6">
                              Posición: {{ item.position }}
                            </v-col>
                            <v-col v-if="hasSpecs" cols="12" xl="6">
                              Específico: {{ item.specific ? 'Sí' : 'No' }}
                            </v-col>
                          </v-row>
                        </v-fade-transition>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row
                      :justify="hasSpecs ? 'space-around' : 'start'"
                      no-gutters
                      align="center"
                    >
                      <v-col cols="3">
                        <v-text-field
                          v-model="item.position"
                          label="Posición"
                          dense
                          type="number"
                          @click.stop
                        ></v-text-field>
                      </v-col>

                      <v-col v-if="hasSpecs" cols="7">
                        <v-checkbox
                          v-model="item.specific"
                          label="¿Es específico?"
                          :false-value="0"
                          :true-value="1"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </template>
            </v-expansion-panels>

            <v-list-item-group
              v-if="false === withOptions"
              v-model="rejected"
              multiple
            >
              <template v-for="(item, i) in accepted">
                <v-list-item
                  :key="`item-${i}`"
                  :value="item.id"
                  active-class="primary--text text--accent-4"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import CourseIcon from '@/components/ui/course/Icon'

  export default {
    name: 'Comparison',
    components:{
      CourseIcon,
    },
    data(){
      return{
        searchItem: '',
        filtered: [],
        accepted: [],
        selected: [],
        rejected: []
      }
    },
    computed:{
      filteredItems: function(){
        let me = this;

        if('' === me.searchItem){
          return me.filtered;
        }

        return me.filtered.filter(achievement => achievement.name.toLowerCase().match(me.searchItem.toLowerCase()))
      }
    },
    mounted(){
      let me = this;

      me.filtered = me.items.filter(item => {
        if(!item.admitted){
          return true;
        }

        me.accepted.push(item);
        return false;
      })

      if(me.hasPosition){
        me.accepted.sort((a, b) => {
          return a.position - b.position;
        });
      }
    },
    methods:{
      acceptLeft: function(){
        let me = this;

        me.selected.forEach(id => {
          me.filtered = me.filtered.filter(item => {
            if(item.id !== id){
              return true;
            }

            if(me.hasPosition){
              item.position = me.getNextPosition();
            }

            item.admitted = true;
            me.accepted.push(item);
            return false;
          })
        });

        me.selected = [];
      },
      rejectRight: function(){
        let me = this;

        me.rejected.forEach(id => {
          me.accepted = me.accepted.filter(item => {
            if(item.id !== id){
              return true;
            }

            if(me.hasPosition){
              item.position = -1;
            }

            item.admitted = false;
            me.filtered.push(item);
            return false;
          })
        });

        me.rejected = [];
      },
      getNextPosition: function(){
        let me = this,
          max = -1;

        for(let i = 0; i < me.accepted.length; i++){
          if(max > me.accepted[i].position){
            continue;
          }

          max = parseInt(me.accepted[i].position);
        }
        return max + 1;
      },
      getAccepted: function(){
        let me = this;
        return me.accepted;
      }
    },
    props:{
      title:{
        type: String,
        required: true
      },
      secondaryTitle:{
        type: String,
        required: true
      },
      hasPosition:{
        type: Boolean,
        required: false,
        default: true
      },
      hasSpecs:{
        type: Boolean,
        required: false,
        default: true
      },
      items:{
        type: Array,
        required: true
      },
      withOptions:{
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>

<style scoped>

</style>
