/**
 * @todo When connecting the real backend, just change this file to the correct
 * end points.
 */

const ENDPOINT = new Map();
ENDPOINT.set('get', '/administration/course/class/list');
ENDPOINT.set('save', '/administration/course/classes/clone');
ENDPOINT.set('remove', '/administration/course/classes/delete');
ENDPOINT.set('update', '/administration/course/classes/update');

export default ENDPOINT
