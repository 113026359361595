<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-btn
          large
          text
          class="elevation-0 mb-6"
          @click.native="$router.go(-1)"
        >
          <v-icon left>fas fa-chevron-left</v-icon>
          Volver
        </v-btn>

        <h1 class="pb-6">Administrador de exámenes</h1>
        <v-combobox
          v-model="course"
          :items="courses"
          item-text="name"
          item-value="id"
          label="Curso"
          clearable
          @change="updateExams"
          :disabled="loadingExams"
        >
        </v-combobox>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col v-if="course" cols="12" md="8" lg="6">
        <v-card class="mb-8">
          <v-card-title>Exámenes: {{course.name}}</v-card-title>
          <slot name="avatar"></slot>
          <v-card-text v-if="loadingExams" class="fill-height">
            <v-row align="center" justify="center" class="fill-height text-center">
              <v-col cols="12">
                <v-progress-circular
                  indeterminate
                  size="40"
                  color="primary"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-else>
            <v-row v-for="(item, i) in exams" align="center" :key="i">
              <v-col cols="9" class="body-1 black--text">
                <v-checkbox
                  v-model="selected"
                  :key="`item-${i}`"
                  :value="item.id"
                  :label="item.name"
                  @click.stop
                ></v-checkbox>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  v-model="item.active"
                  :false-value="1"
                  :true-value="0"
                >
                  <template v-slot:label>
                    <span :class="item.active ? '' : 'error-text'">
                      Deshabilitado
                    </span>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-row align="center">
          <v-col cols="12">
            <v-btn
              color="error"
              class="mb-2 mr-12"
              width="120"
              @click="deleteExams"
              :disabled="updating"
              :loading="updating"
            >
              Eliminar
            </v-btn>

            <v-btn
              color="primary"
              class="mb-2"
              width="120"
              @click="saveExams"
              :disabled="updating"
              :loading="updating"
            >
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <SnackbarMessage
        ref="snackbar"
        :color="snackbar.color"
        :text="snackbar.message"
      />
    </v-row>
  </v-container>
</template>

<script>
import SnackbarMessage from '@/components/ui/snackbar'
import PageLoader from '@/components/ui/loader/PageLoader'
import { mapGetters } from 'vuex'
export default {
  name: 'ManageExams',
  components:{
    SnackbarMessage,
    PageLoader
  },
  data(){
    return{
      course: null,
      panels: [],
      exams: [],
      selected: [],
      snackbar:{
        message: '',
        color: ''
      }
    }
  },
  computed:{
    ...mapGetters({
      loadingExams: 'services/api/course/exam/fetching',
      updating: 'services/api/course/exam/updating'
    })
  },
  methods:{
    updateExams(){
      if(null === this.course || '' === this.course){
        this.exams = [];
        return;
      }

      this.$store.dispatch('services/api/course/exam/fetch', this.course.course_code)
        .then(res => {
          this.exams = res;
        })
    },
    saveExams(){
      let form = new FormData();

      for(let i = 0; i < this.exams.length; i++){
        let id = this.exams[i].id;
        let active = ['active', '[', id, ']'].join('');
        form.append(active, this.exams[i].active);
      }

      this.selected = [];
      this.$store.dispatch('services/api/course/exam/update', {
        form: form,
      })
        .then(response => {
          this.updateExams();
          this.snackbar.message = 'Exams was updated succcessfully';
          this.snackbar.color = 'success';
          this.$refs['snackbar'].show();
        })
        .catch(error => {
          this.snackbar.message = error.response.data.message;
          this.snackbar.color = 'error';
          this.$refs['snackbar'].show();
        })
    },
    deleteExams(){
      let form = new FormData();

      if(this.selected.length === 0){
        return
      }

      for(let i = 0; i < this.selected.length; i++){
        form.append('exams[]', this.selected[i]);
      }

      this.selected = [];

      this.$store.dispatch('services/api/course/exam/remove', {
        form: form,
      })
        .then(response => {
          this.updateExams();
          this.snackbar.message = response.success ? 'Exams was removed succcessfully' : response.message;
          this.snackbar.color = response.success ? 'success' : 'error';
          this.$refs['snackbar'].show();
        })
    }
  },
  props:{
    courses:{
      type: Array,
      required: true
    }
  }
}
</script>
