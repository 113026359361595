import initialState from './state.js'
import settings from '@/settings'

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve fetching state
  iState.fetching = state.fetching;
  Object.assign(state, iState);
};

const FETCHING = (state, value) => {
  state.fetching = !!value;
}

const FETCH_SUCCESS = (state, value) => {
  state.success = !!value;
};

const FETCH_ERROR = (state, value) => {
  state.error = value;
};

const SET_SERVER_DATE = (state, value) => {
  let flag = false;
  state.date = value.date;
  state.now = value.date;

  setInterval(function(){
      let date = flag ? state.now : new Date(state.now);

      date.setTime(date.getTime() + settings.clock.sync);

      state.now = date;

      flag = true;
    },
    settings.clock.sync
  );
};

export default {
  FETCHING,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SET_SERVER_DATE,
  RESET_STATE
};
