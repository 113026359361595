<template>
    <div class="language-selection">

      <v-select :items="languages" @change=change :value="current" item-value="iso">
        <template slot="item" slot-scope="data">
          <v-avatar>
            <flag :iso="data.item.iso" />
          </v-avatar>
          <template v-if="showText">
            {{ data.item.name }}
          </template>
        </template>
        <template slot="selection" slot-scope="data">
          <v-avatar>
            <flag :iso="data.item.iso" />
          </v-avatar>
          <template v-if="showText">{{ data.item.name }}</template>
        </template>
      </v-select>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Language',
    computed:{
      ...mapGetters({
        languages: 'services/api/language/getActive',
        current: 'services/api/language/getCurrent'
      })
    },
    created(){
      let me = this;

      if(me.languages.length === 0){
        me.$store.dispatch('services/api/language/fetchLanguages');
      }
    },
    methods:{
      change: function(lang){
        let me = this;

        if(lang === me.current) return;

        me.$store.dispatch('services/api/translation/fetchTranslations', lang)
        .then(response => {
          me.$store.dispatch('services/api/language/setCurrentLanguage', lang)
        });
      }
    },
    props:{
      showText:{
        required: true,
        type: Boolean
      },
      activeOnly:{
        required: true,
        type: Boolean
      }
    }
  }
</script>

<style scoped>
</style>
