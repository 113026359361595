import uniqid from 'uniqid'

const fetch = (api, context, message, args, delay=500, showLoader=true) => {
  let commit = context.commit;
  let dispatch = context.dispatch;
  let rootGetters = context.rootGetters;
  let loaderId = uniqid();
  delay = parseInt(delay);

  commit('FETCHING', true);
  commit('RESET_STATE');

  if(true === showLoader){
    //Add XHR Loader description
    dispatch('services/api/api/addDescription', {
        id: loaderId, //Random uniqid (could also be server side generated)
        message: message, //Translation value
      },
      {root:true}
    );
  }

  return new Promise((resolve, reject) => {

    setTimeout( function() {

      return api.fetch(args)
        .then((response) => {
          commit('FETCHING', false);
          commit('FETCH_UPDATED', {args: args, data: response.data});

          if(true === showLoader){
            //Once the request is done, remove the description by id
            dispatch(
              'services/api/api/removeDescription',
              {
                id: loaderId,
                delay: 1000
              },
              {root: true}
            );
          }

          if(200 !== response.status){
            return reject(response);
          }

          return resolve(response.data);

        })
        .catch((error) => {
          commit('FETCHING', false);

          if(true === showLoader){
            dispatch(
              'services/api/api/removeDescription',
              {
                id: loaderId,
                delay: 1000
              },
              {root: true}
            );
          }

          return reject(error);
        });
      }, delay)
  });
}

export default {
  fetch,
}
