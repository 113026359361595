import apiService from '@/services/api/api/store'
import authenticationService from '@/services/api/auth/store'
import languageService from '@/services/api/language/store'
import router from '@/services/api/router'
import translationService from '@/services/api/translation/store'
import serverService from '@/services/api/server'
import careerService from '@/services/api/career'
import courseService from '@/services/api/course'
import userService from '@/services/api/user'
import achievementService from '@/services/api/achievement'
import systemService from '@/services/api/system'

export default{
  namespaced: true,
  modules: {
    api: apiService,
    auth: authenticationService,
    language: languageService,
    router: router,
    translation: translationService,
    server: serverService,
    career: careerService,
    course: courseService,
    user: userService,
    achievement: achievementService,
    system: systemService
  }
}
