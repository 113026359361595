const fetching = state => state.fetching;
const success = state => state.success;
const error = state => state.error;

const versions = state => state.versions;

export default {
  fetching,
  success,
  error,
  versions
};
