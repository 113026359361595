const PENDING = (state, value) => {
  state.pending = value;
}

const ADD_DESCRIPTION = (state, value) => {
  state.descriptions.push(value);
}

const RESET_DESCRIPTIONS = (state) => {
  return state.descriptions = [];
}

const REMOVE_DESCRIPTION = (state, args) => {
  setTimeout(function(){
    state.descriptions = state.descriptions.filter( el => el.id !== args.id);
  }, args.delay);
}

const CONN_ERROR = (state, args) => {
  state.error = args;
}

export default {
  PENDING,
  ADD_DESCRIPTION,
  REMOVE_DESCRIPTION,
  RESET_DESCRIPTIONS,
  CONN_ERROR,
};
