let settings = {
  version: {
    number: '0.0.8-beta',
    name: 'Grün'
  },
  clock: {
    sync: 500
  },
  mobile: {
    breakpoint: 960
  },
  api: {
    alumni: {
      server: process.env.VUE_APP_API_SERVER,
      session: {
        keepAliveMinutes: 15
      },
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Alumni-Application': 'administration'
      }
    },
    tenor: {
      server: 'https://api.tenor.com/v1/',
      key: 'WXPCC3DR6KAF'
    }
  }
}

module.exports = settings
