/**
 * @todo When connecting the real backend, just change this file to the correct
 * end points.
 */

const ENDPOINT = new Map();
ENDPOINT.set('get', '/course/certificate/user/list/course');
ENDPOINT.set('certificates', '/course/certificate/user/list');

export default ENDPOINT
