import settings from '@/settings'

const banner = "%c\n                      =*****@          \n"+
  "                     =*****            \n"+
  "                    ******             \n"+
  " @**********#      *****=              \n"+
  "  @***#####@     @*****=               \n"+
  "   #***         @*****#                \n"+
  "    #**=       #*****@                 \n"+
  "     =**      #*****@       @          \n"+
  "      =      =*****        @*=         \n"+
  "            =*****        #***#        \n"+
  "           *****=        @*****@       \n"+
  "          *****=          #*****@      \n"+
  "        @*****=            =*****@     \n"+
  "       @*****#              =*****     \n"+
  "      #*****@                ******    \n"+
  "     #*****@                  ******   \n"+
  "    =*****        @*****************=  \n"+
  "   =*****        #*******************= \n"+
  "  *****=        =*********************#\n"+
  "                                       \n"+
  "[           Alumni Frontend           ]\n"+
  "                                       \n"+
  "Version: " + settings.version.number + ' (' + settings.version.name  +         ")\n"+
  "Author : Federico Stange               \n"+
  "Contact: <jpfstange@gmail.com>         ";

export default banner;
