<template>
  <v-container fluid>
    <v-col cols="12" class="pt-0">
      <v-btn
        large
        text
        class="elevation-0 mb-4"
        @click.native="$router.go(-1)"
      >
        <v-icon left>fas fa-chevron-left</v-icon>
        Volver
      </v-btn>

      <h1>Listado de módulos</h1>
    </v-col>
    <v-row>
      <v-col cols="12" md="6">
        <v-combobox
          v-model="courseLeft"
          :items="courses"
          item-text="name"
          item-value="id"
          label="Curso origen"
          clearable
          @input="updateClassesLeft"
          :loading="loadingClasses"
          :disabled="loadingClasses"
        >
        </v-combobox>
      </v-col>

      <v-col cols="12" md="6">
        <v-combobox
          v-model="courseRight"
          :items="courses"
          item-text="name"
          item-value="id"
          label="Curso destino"
          clearable
          @change="updateClassesRight"
          :loading="loadingClasses"
          :disabled="loadingClasses || classesLeft.length === 0"
        >
        </v-combobox>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="courseLeft && classesLeft.length > 0" cols="12" md="5">
        <v-card class="fill-height">
          <v-card-title>
            Módulos {{courseLeft.name}}
          </v-card-title>
          <v-card-text>
            <v-list height="400" style="overflow-y: auto;">
              <v-list-item-group
                v-model="selected"
                multiple
              >
                <template v-for="(item, i) in classesLeft">
                  <v-list-item
                    :key="`item-${i}`"
                    :value="item"
                    active-class="primary--text text--accent-4"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.name"></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-avatar class="justify-center">
                        <v-btn fab disabled>
                          <span v-if="item.status === 'open'" class="success-text">{{item.number}}</span>
                          <span v-else class="error-text">{{item.number}}</span>
                        </v-btn>
                      </v-list-item-avatar>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-if="classesLeft.length > 0 && courseRight" cols="12" md="2" class="align-self-center text-center">
        <v-row class="text-center">
          <v-col cols="12">
            <v-btn color="primary" class="elevation-0" @click="acceptLeft">
              <v-icon>fas fa-arrow-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="courseRight" cols="12" md="5">
        <v-card class="fill-height mb-8">
          <v-card-title>Módulos {{courseRight.name}}</v-card-title>
          <v-card-text v-if="loadingClasses" class="fill-height">
            <v-row align="center" justify="center" class="fill-height text-center">
              <v-col cols="12">
                <v-progress-circular
                  indeterminate
                  size="40"
                  color="primary"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-else>
            <v-list height="400" style="overflow-y: auto;">
              <v-list-item-group
                v-model="rejected"
                multiple
              >
                <template v-for="(item, i) in classesRight">
                  <v-list-item
                    :key="`item-${i}`"
                    :value="item"
                    active-class="primary--text text--accent-4"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-action v-if="item.new">
                        <v-list-item-action-text class="primary--text">new!</v-list-item-action-text>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title v-text="item.name"></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-avatar class="justify-center">
                        <v-btn fab disabled>
                          <span v-if="item.status === 'open'" class="success-text">{{item.number}}</span>
                          <span v-else class="error-text">{{item.number}}</span>
                        </v-btn>
                      </v-list-item-avatar>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>

        <v-row align="center">
          <v-col cols="12">
            <v-btn
              color="error"
              class="mb-2 mr-12"
              width="120"
              @click="deleteModules"
              :disabled="updating"
              :loading="updating"
            >
              Eliminar
            </v-btn>

            <v-btn
              color="primary"
              class="mb-2"
              width="120"
              @click="saveModules"
              :disabled="updating"
              :loading="updating"
            >
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <SnackbarMessage
        ref="snackbar"
        :color="snackbar.color"
        :text="snackbar.message"
      />
    </v-row>
  </v-container>
</template>

<script>
  import SnackbarMessage from '@/components/ui/snackbar'
  import PageLoader from '@/components/ui/loader/PageLoader'
  import CourseIcon from "@/components/ui/course/Icon"
  import { mapGetters } from 'vuex'
  export default {
    name: 'CloneClasses',
    components:{
      SnackbarMessage,
      PageLoader,
      CourseIcon
    },
    data(){
      return{
        courseLeft: null,
        courseRight: null,
        classesLeft: [],
        classesRight: [],
        selected: [],
        accepted: [],
        rejected: [],
        snackbar:{
          message: '',
          color: ''
        }
      }
    },
    computed:{
      ...mapGetters({
        loadingClasses: 'services/api/course/classes/fetching',
        updating: 'services/api/course/classes/updating'
      }),
    },
    methods:{
      updateClassesLeft(){
        let me = this;

        if(null === me.courseLeft || '' === me.courseLeft){
          me.classesLeft = [];
          return;
        }

        me.$store.dispatch('services/api/course/classes/fetch', me.courseLeft.uniqid)
        .then(res => {
          me.classesLeft = res;
        })
      },
      updateClassesRight(){
        let me = this;

        if(null === me.courseRight || '' === me.courseRight){
          me.classesRight = [];
          return;
        }

        me.$store.dispatch('services/api/course/classes/fetch', me.courseRight.uniqid)
          .then(res => {
            res.forEach(item => {
              item.new = false;
            });
            me.classesRight = res;
          })
      },
      acceptLeft(){
        let me = this;
        me.selected.forEach(item => {
          let copy = JSON.parse(JSON.stringify(item));
          copy.number = me.getNextPosition();
          copy.new = true;
          me.classesRight.push(copy);
        });

        me.selected = [];
      },
      getNextPosition(){
        if(this.classesRight.length === 0){
          return 1;
        }

        const index = this.classesRight.findLastIndex(item => item.status === 'open');
        return (this.classesRight[index].number + 1);
      },
      saveModules(){
        let me = this,
          atLeastOne = false,
          form = new FormData();

        for(let i = 0; i < me.classesRight.length; i++){
          if(!me.classesRight[i].new){
            continue;
          }

          atLeastOne = true;
          let key = ['classes', '[', me.classesRight[i].id, ']'].join('');
          form.append(key, me.classesRight[i].number);
        }

        if(!atLeastOne){
          return;
        }

        form.append('course', me.courseRight.uniqid);

        me.$store.dispatch('services/api/course/classes/save', {
          form: form,
        })
          .then(response => {
            me.updateClassesRight();
            me.snackbar.message = 'Classes was added succcessfully';
            me.snackbar.color = 'success';
            me.$refs['snackbar'].show();
          })
          .catch(error => {
            me.snackbar.message = error.response.data.message;
            me.snackbar.color = 'error';
            me.$refs['snackbar'].show();
          })
      },
      deleteModules(){
        let me = this,
          atLeastOne = false,
          form = new FormData();

        for(let i = 0; i < me.rejected.length; i++){
          if(me.rejected[i].new){
            me.classesRight.splice(me.classesRight.findIndex(a => a.id === me.rejected[i].id) , 1)
            continue;
          }

          atLeastOne = true;
          form.append('classes[]', me.rejected[i].id);
        }

        if(!atLeastOne){
          return;
        }

        form.append('course', me.courseRight.uniqid);

        me.$store.dispatch('services/api/course/classes/remove', {
          form: form,
        })
          .then(response => {
            me.rejected = [];
            me.updateClassesRight();
            me.snackbar.message = 'Classes was removed succcessfully';
            me.snackbar.color = 'success';
            me.$refs['snackbar'].show();
          })
          .catch(error => {
            me.snackbar.message = error.response.data.message;
            me.snackbar.color = 'error';
            me.$refs['snackbar'].show();
          })
      }
    },
    props:{
      courses:{
        type: Array,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>
