<template>
  <v-main
    v-if="true === hashSuccess"
    class="text-xs-center animated fadeIn white fill-height"
  >

    <v-container grid-list-md class="pa-0">

      <v-layout align-center class="justify-center">

        <v-card
          v-if="!passwordChangeSuccess"
          class="elevation-0 card-container"
        >

          <v-toolbar class="title elevation-0" color="primary">
            <img src="@/assets/logo/logo.svg" style="margin:0 10px 0 25px;-webkit-filter: brightness(10);filter: brightness(10);float:left; width:40px;"/>
            <span class="pl-3 white--text">ALUMNI</span>
          </v-toolbar>

          <v-card-title class="justify-center">
            Cambia tu contraseña
          </v-card-title>

          <v-card-text>
            <!-- This is to avoid autocomplete (works on chrome and its a know bug - August 2019) -->
            <input style="display:none;"/>

            <v-text-field
              type="password"
              v-model="password"
              label="Ingresa una nueva contraseña"
              browser-autocomplete="new-password"
            ></v-text-field>

            <p>Nivel de seguridad</p>

            <password-strength
              v-model="password"
              :strength-meter-only="true"
              @score="updateSecurityScore"
            />

            <v-text-field
              v-model="passwordConfirm"
              type="password"
              label="Repite la contraseña ingresada"
              browser-autocomplete="new-password"
            ></v-text-field>
          </v-card-text>

          <v-card-actions>

            <v-layout row wrap class="justify-center">

              <v-flex xs12>
                <p>
                    <span
                      v-if="true === passwordMatchError"
                      class="error-text animated shake"
                    >
                      <v-icon color="red">ico-exclamation-circle</v-icon>
                      Las contraseñas ingresadas no coinciden
                    </span>
                </p>

                <p>
                    <span
                      v-if="null !== securityScore && securityScore < 2"
                      class="error-text animated shake"
                    >
                      <v-icon color="red">ico-exclamation-circle</v-icon>
                      Las contraseña ingresada no es lo suficientemente segura, utiliza simbolos y mayusculas!
                    </span>

                </p>
              </v-flex>

              <v-flex xs12 md7>
                  <v-btn
                    block
                    :loading="passwordChangeSending"
                    color="primary"
                    v-ripple
                    @mouseover="buttonIcon = 'ico-unlock'"
                    @mouseout="buttonIcon = 'ico-lock'"
                    :disabled="submitDisabled"
                    class="elevation-0"
                    @click.prevent="recaptcha"
                  >
                    Cambiar contraseña
                    <v-icon class="pl-4">
                      {{buttonIcon}}
                    </v-icon>
                  </v-btn>
              </v-flex>

              <v-flex xs12 md7>
                <v-btn
                  :to="{name:'Login'}"
                  color="secondary"
                  v-ripple
                  block
                  class="elevation-0"
                >
                  <v-icon left color="white">ico-chevron-left</v-icon> Volver
                </v-btn>
              </v-flex>

            </v-layout>

          </v-card-actions>

        </v-card>

        <v-card
          v-if="true === passwordChangeSuccess"
          class="animated bounceIn elevation-0 card-container"
        >

          <v-toolbar class="title elevation-0" color="primary">
            <img src="@/assets/logo/logo.svg" style="margin:0 10px 0 25px;-webkit-filter: brightness(10);filter: brightness(10);float:left; width:40px;"/>
            <span class="pl-3 white--text">ALUMNI</span>
          </v-toolbar>

          <v-card-title>Tu contraseña ha sido cambiada con exito!</v-card-title>

          <v-card-text>
            Por favor procede a loguearte con tu nueva contraseña
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="primary"
              :to="{name:'Login'}"
              block
              v-ripple
            >
              Ir al login
              <v-icon class="pl-3">ico-chevron-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-layout>
    </v-container>

  </v-main>
</template>

<script>
  import {mapGetters} from 'vuex'
  import PasswordStrength from 'vue-password-strength-meter'

  export default{
    name: 'ForgotPasswordView',
    components:{
      PasswordStrength
    },
    computed:{
      ...mapGetters({
        passwordChangeSending: 'services/api/auth/changePasswordSending',
        passwordChangeSuccess: 'services/api/auth/changePasswordSuccess',
        hashSuccess: 'services/api/auth/hashValidateSuccess',
        hashErrors: 'services/api/auth/hashValidateErrors',
      }),
      passwordMatchError:function (){
        let me = this,
          pass = me.password,
          confirm = me.passwordConfirm;

        if((null === pass || confirm === null)){
          return false;
        }

        if(0 === pass.length || 0 === confirm.length){
          return true;
        }

        return pass !== confirm;
      },
      submitDisabled: function() {
        let me = this,
          pass = me.password,
          confirm = me.passwordConfirm;

        return (me.securityScore < 2) || me.passwordMatchError || (null === pass || confirm === null);

      }
    },
    data(){
      return {
        siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
        password: null,
        passwordConfirm: null,
        securityScore: null,
        buttonIcon: 'ico-lock',
        showForm: true
      }
    },
    methods:{
      async recaptcha() {
        let me = this;

        await me.$recaptchaLoaded()
        const token = await me.$recaptcha('login')
        me.submit(token);
      },
      submit: function(token){
        let me = this;

        me.$store.dispatch('services/api/auth/changePassword',{
          hash: me.hash,
          password: me.password,
          captcha: token
        })
          .then(response => {
          })
      },
      updateSecurityScore: function(data) {
        let me = this;
        me.securityScore = data;
      }
    },
    mounted(){
      let me = this;
      me.$store.dispatch('services/api/auth/hashValidate',{
        hash: me.hash
      })
        .then(response => {
          if(false === me.hashSuccess){
            me.$router.push({
              name: 'Login'
            })
          }
        })
    },
    props:{
      hash:{
        type: String
      }
    }
  }
</script>

<style scoped>
  .card-container{
    width:100%;
  }

  .title{
    font-size: 1.5rem;
    color: white;
  }

  @media (min-width:1030px){
    .card-container {
      border: 1px solid #ccc;
      margin-top: 10%;
      width:50%;
    }
  }

</style>
