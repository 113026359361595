const error = state => state.error;
const success = state => state.success;
const fetching = state => state.fetching;
const last = state => state.last;

export default {
  fetching,
  success,
  error,
  last
}
