import api from '../api'

const fetchTranslations = ({ commit }, lang) => {
  commit('FETCHING', true);

  return api.fetchTranslations(lang ? lang : null)
  .then((response) => {
    commit('FETCHING', false);
    commit(
      response.data.success === true ? 'FETCH_COMMIT' : 'FETCH_ERROR',
      response.data
    );

    return response.data;
  })
  .catch((error) => {
    commit('FETCHING', false);
    commit('FETCH_ERROR', error);
    return error;
  });

}

export default {
  fetchTranslations,
}
