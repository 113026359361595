<template>
  <div class="profile">
    <v-row>
      <v-col cols="12" sm="10" offset-sm="1" md="8" offset-md="2" class="student-info">
        <div class="bg-blue">
          <v-row>
            <v-col cols="12" md="7">
              <div class="student-profile">
                <img :src="student.avatar" class="avatar-profile">

                <div class="ml-3">
                  <p class="profile-name">{{student.name}} {{student.lastname}}</p>
                  <p class="profile-desc">{{finishedCourses}} {{translations.profile.coursesCompleted}}, {{diplomas.length}} {{translations.profile.diplomas}} y {{achievements.length}} {{translations.profile.assignedAchievements}}</p>
                </div>

              </div>
            </v-col>

            <v-col cols="12" md="4" offset-md="1" class="pt-2">
              <div class="info-resumen">
                <v-icon>ico-trophy</v-icon> {{achievements.length}} {{translations.profile.achievements}}
              </div>

              <div class="info-resumen">
                <v-icon>ico-collections_bookmark</v-icon> {{courses.length}} {{translations.profile.courses}}
              </div>

              <div class="info-resumen">
                <a v-if="false !== linkedIn"
                   :href="linkedIn.value"
                   target="_blank"
                   class="profile-link"
                >
                  LinkedIn&nbsp;
                  <v-icon>ico-linkedin-square</v-icon>
                  {{student.name}}
                </a>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col v-if="null !== student.biography" cols="12" sm="10" offset-sm="1" md="8" offset-md="2">
        <v-card class="card-box">
          <v-card-title>
            <h3 class="headline mb-0">Biografía</h3>
          </v-card-title>
          <v-card-text v-if="student.biography.length <= 500">
            {{student.biography}}
          </v-card-text>
          <v-card-text v-if="student.biography.length > 500">
            <span v-if="!readMore">{{student.biography.slice(0, 500)}}</span>
            <a class="" v-if="!readMore" @click="readMore = true" href="#">
              ... Ver más
            </a>
            <span v-if="readMore">{{student.biography}}</span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="10" offset-sm="1" md="8" offset-md="2">

        <v-card v-if="certificates.length > 0" class="card-box">
          <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">Certificados</h3>
            </div>
          </v-card-title>

          <v-row class="px-3 pb-5">
            <v-col
              v-for="certificate in certificates"
              :key="certificate.certificate.hash"
              cols="12"
              sm="6"
              md="3"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" class="diploma-box" @click="gotoCertificate(certificate)">
                    <img class="icon" :src="getCertificateUrl(certificate.formats, png)" />
                    <img class="diploma-svg" :src="require('@/assets/Diploma.svg')">
                  </div>
                </template>
                <span>{{certificate.course.name}}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>

        <v-card class="card-box">
          <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">{{translations.profile.diplomas}}</h3>
            </div>
          </v-card-title>

          <v-row
            v-if="diplomas.length > 0"
            class="px-3 pb-5"
          >
            <v-col
              v-for="diploma in diplomas"
              :key="diploma.code"
              cols="12"
              sm="6"
              md="3"
            >

              <div class="diploma-box" @click="gotoDiploma(diploma)">
                <CourseIcon :icon="diploma.course.icon" classes="icon" />
              </div>

            </v-col>
          </v-row>

          <v-row
            v v-if="0 === diplomas.length"
            class="no-results"
          >
            <v-col cols="12">
              <div class="no-results-text">
                {{translations.profile.noDiplomas}}
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Social networks -->
    <v-row v-if="social.length > 0">
      <v-col cols="12" sm="10" offset-sm="1" md="8" offset-md="2">
        <v-card class="card-box pb-3">
          <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">{{translations.profile.socialNetworks}}</h3>
            </div>
          </v-card-title>

          <v-row v-if="social.length > 0">
            <v-col
              v-for="(network, index) in social"
              :key="index"
              cols="12"
              sm="6"
            >
              <a
                :href="network.value"
                target="_blank"
              >
                <div class="list-course communities">
                  <div class="icon-box">
                    <v-icon>{{getSocialNetworkIcon(network.name)}}</v-icon>
                  </div>
                  <div class="course-info">
                    <p class="course-name">{{network.name}}</p>
                    <v-icon>arrow_forward</v-icon>
                  </div>
                </div>
              </a>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Achievements -->
    <v-row>
      <v-col cols="12" sm="10" offset-sm="1" md="8" offset-md="2">
        <v-card class="card-box pb-3">
          <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">{{translations.profile.achievements}}</h3>
            </div>
          </v-card-title>

          <v-row
            v-if="achievements.length > 0"
            class="achievements"
          >
            <v-col
              v-for="(achievement, index) in achievements"
              :key="index"
              cols="12"
              sm="6"
            >
              <template>
                <div class="list-course">
                  <div class="course-info">
                    <p class="course-name" v-html="achievement.name"></p>
                    <p class="course-progress">{{translations.profile.progressLevel}}: {{achievement.progress}}%</p>
                    <ProgressBar :progress="achievement.progress"/>
                  </div>
                </div>
              </template>
            </v-col>
          </v-row>

          <v-row
            v v-if="0 === achievements.length"
            class="no-results"
          >
            <v-col cols="12">
              <div class="no-results-text">
                {{translations.profile.noAchievements}}
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Courses -->
    <v-row>
      <v-col cols="12" sm="10" offset-sm="1" md="8" offset-md="2">
        <v-card class="card-box pb-3">
          <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">{{translations.profile.courses}}</h3>
            </div>
          </v-card-title>

          <v-row
            v-if="courses.length > 0"
            class="achievements"
          >
            <v-col
              v-for="(course, index) in courses"
              :key="index"
              cols="12"
              sm="6"
            >
              <template>
                <div class="list-course">
                  <CourseIcon :icon="course.icon" classes="box-img-course curso" />

                  <div class="course-info">
                    <p class="course-name"><v-icon>ico-stars</v-icon>{{course.name}}</p>

                    <p class="course-progress">

                      <span v-if="true === course.finished && false === course.certified" class="completed">
                        <v-icon size="small" color="blue darken-3">ico-check</v-icon>
                        {{translations.profile.courseCompleted}} - ({{course.hours}} - {{translations.profile.courseHours}})
                      </span>

                      <span v-if="false === course.finished" class="inprogress">
                        <v-icon size="small" color="red lighten-3">ico-space-shuttle</v-icon>
                        {{translations.profile.courseInProgress}}
                      </span>

                      <span v-if="true === course.finished && true === course.certified" class="certified">
                        <v-icon size="small" color="yellow darken-3">ico-star</v-icon>
                        {{translations.profile.courseCertified}} - ({{course.hours}} - {{translations.profile.courseHours}})
                      </span>
                    </p>

                  </div>
                </div>
              </template>
            </v-col>
          </v-row>

          <v-row
            v v-if="0 === courses.length"
            class="no-results"
          >
            <v-col cols="12">
              <div class="no-results-text">
                {{translations.profile.noCourses}}
              </div>
            </v-col>
          </v-row>

        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<script>
  import SocialNetworkIconMixin from '@/mixins/SocialNetworkIcon'
  import ProgressBar from '@/components/progress'
  import Routes from '@/router/routes'
  import CourseIcon from "@/components/ui/course/Icon";

  export default{
    name: "Profile",
    mixins:[
      SocialNetworkIconMixin
    ],
    components:{
      CourseIcon,
      ProgressBar
    },
    data(){
      return {
        html: 'html',
        pdf: 'pdf',
        png: 'png',
        png64: 'png64',
        readMore: false
      }
    },
    computed:{
      finishedCourses: function(){
        let me = this,
          count = 0;

        me.courses.map(course => {
          count += (true === course.finished) | 0;
        });

        return count;
      },
      linkedIn: function(){
        let me = this,
          linkedin = me.hasSocialNetwork('linkedin');

        if(false === linkedin){
          return false;
        }

        return linkedin;

      }
    },
    methods:{
      getCertificateUrl: function(formats, format){
        return formats.find(item => {
          return item.format === format;
        }).url;
      },
      hasSocialNetwork: function (name){
        let me = this;

        for(let i = 0; i < me.social.length; i++){
          let s = me.social[i];

          if(s.name===name){
            return s;
          }
        }

        return false;
      },
      gotoDiploma: function(diploma){
        let me = this;

        me.$router.push({
          name: Routes.get('diploma').name,
          params:{
            code: diploma.code,
            calendar: diploma.course.calendar,
            user: me.student.uniqid
          }
        }).catch(()=>{})
      },
      gotoCertificate: function(certificate){
        let me = this;

        me.$router.push({
          name: Routes.get('certificate').name,
          params:{
            course: certificate.course.uniqid,
            user: me.student.uniqid
          }
        }).catch(()=>{})
      }
    },
    props:{
      student:{
        type: Object
      },
      social:{
        type: Array
      },
      diplomas:{
        type: Array,
        default: () => { return []; }
      },
      certificates:{
        type: Array,
        default: () => { return []; }
      },
      achievements:{
        type: Array,
        default: () => { return []; }
      },
      courses:{
        type: Array,
        default: () => { return []; }
      },
      translations:{
        type: Object
      }
    }
  }
</script>

<style scoped>

  /*--- Diploma box ---*/
  .diploma-box{
    border: 3px solid #777;
    cursor: pointer;
    margin: 8px;
    margin-bottom: 20px;
    position: relative;
    height: 10vw;
    background-color:#fdfdfd;
  }
  .diploma-box i{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 75px;
    margin-top: 3px;
  }

  .diploma-box .icon{
    max-height: 9vw;
    width:100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .diploma-box .diploma-svg{
    max-width: 55px;
    position: absolute;
    bottom: -30px;
    right: 0;
  }

  @media (max-width: 960px) {
    .diploma-box{
      height: 20vw;
      margin-bottom: 30px;
    }
    .diploma-box .icon{
      max-height: 15vw;
    }
  }

  @media (max-width: 600px) {
    .diploma-box{
      height: 50vw;
      margin-bottom: 30px;
    }
    .diploma-box .icon{
      max-height: 35vw;
    }
  }

  .ico-certificado2{color:#757575!important;}
  .ico-certificado2.active{color:#FFCA28!important;}

  .student-info .bg-blue{
    background-color: #1976d2 !important;
    margin-top: 50px;
    padding: 30px;
    box-shadow: 0 3px 22px rgba(0,0,0,0.3);
  }

  .student-info .card .list{
    background-color: inherit;
    color: #fff;
  }

  .student-info .card .card__media{
    height:150px!important;
    border-radius: 50%;
    height: 150px;
    width: 150px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .student-info .card .card__title{}
  .student-info .card .card__title .headline{font-size: 1.2rem!important;}

  .courses .card .list__tile__content{font-size:1rem;}

  /*  .margintop20{
      margin-top: 20px;
    }*/
  .marginleft56{
    margin-left: 56px;
  }
  .ml-auto{
    margin-left: auto;
  }

  .profile{
    margin-bottom: 40px;
  }

  .student-profile{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    color: #fff;
  }
  .avatar-profile{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
  }
  .profile-name{
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .profile-desc{
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 0;
    line-height: 1.2;
  }
  .profile-link{
    color: #fff;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .profile-link i{
    color: #fff;
    margin-right: 10px;
  }

  .info-resumen{
    height: 25%;
    color: #fff;
    font-size: 18px;
    margin-left: 20px;
  }

  .info-resumen i{
    margin-right: 10px;
    color: #fff;
  }

  .card-box{
    margin-top: 40px;
  }

  .list-course{
    padding: 15px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
  }

  .list-course.diploma .course-name{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    color: #000;
  }

  .list-course .course-name i{
    display: none;
  }

  .list-course.diploma .course-name i{
    font-size: 27px;
    display: inline-block;
    margin-right: 5px;
    color: #b4a17c;
  }

  .list-course .course-progress .text-diploma{
    display: none;
  }

  .list-course.diploma .course-progress .text-diploma{
    color: #b4a17c;
    display: inline;
  }

  .list-course.diploma .box-img-course{
    border: 5px solid #b4a17c;
    background-position: center;
    background-size: contain;
  }

  .list-course .box-img-course{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 3px rgba(0,0,0,0.3);
    position: relative;
    margin-right: 10px;
    flex-shrink: 0;
  }

  .list-course .box-img-course.curso{
    border-radius: 0;
    height: 70px;
    width: 70px;
  }

  .list-course .box-img-course i{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .list-course .course-info{
    width: 100%;
  }

  .list-course .course-name{
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 0;
  }

  .list-course .course-progress{
    margin-bottom: 5px;
    font-size: 13px;
    color: #aaa;
  }

  .list-course .course-progress .inprogress{
    color: #f0a4a4;
    font-weight: 500;
  }

  .list-course .course-progress .certified{
    color:#116699;
  }

  .list-course .course-progress .completed{
    color: #5199e0;
    font-weight: 500;
  }

  .list-course.communities .course-name{
    color: #000;
  }
  .list-course.communities .course-info{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
  }
  .list-course .icon-box{
    margin-right: 10px;
    padding: 5px;
    box-shadow: 0 0 3px rgba(0,0,0,0.3);
  }
  .list-course .icon-box i{
    font-size: 18px;
  }

  /*No results*/
  .no-results{
    padding: 15px;
  }
  .no-results .no-results-text{
    padding:  40px 15px;
    background-color: #f5f5f5;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 300;
    border: 1px solid #aaa;
  }

  @media (max-width: 767px) {
    .hidden-xs{
      display: none;
    }
    .visible-xs{
      display: block;
    }
    .profile-name{
      margin-top: 20px;
    }
    .marginleft56{
      margin-left: 0;
    }
    .card__title .list{
      margin: auto;
    }
    .list-avatar-profile{
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
    }
  }

  @media (min-width: 767px) {
    .visible-xs{
      display: none;
    }
  }

  @media (max-width: 960px) {
    .student-profile{
      display: block;
      margin: 0;
      margin-bottom: 30px;
    }
    .info-resumen{
      margin-left: 16px;
    }
  }

  @media (max-width: 600px) {
    .profile{
      margin-left: 0;
    }
    .student-info .bg-blue{
      padding: 30px 15px;
    }
    .avatar-profile{
      display: block;
      margin: 0 auto;
    }
  }
</style>
