import api from '../api'
import initialState from './state'
import cacheStorage from '@/services/api/cache';

const doAuth = ({ commit, dispatch }, args={}) => {
  dispatch('dropAuthToken');
  commit('LOGIN_PENDING', true);

  return api.authenticate(args)
  .then(response => {

    commit('LOGIN_PENDING', false);
    commit('LOGIN_SUCCESS', response.data);
    return response.data;
  })
  .catch(error => {
    commit('LOGIN_PENDING', false);
    commit('LOGIN_FAILURE', error);
    return error;
  })
};

const isAuthenticated = ({ commit }, args={}) => {
  return api.isAuthenticated()
  .then(response => {
    args.data = response.data;
    commit('SESSION_SUCCESS', args);
    return response;
  })
  .catch(error => {
    commit('SESSION_FAILURE', error);
    return error;
  })
};

const recoverPassword = ({commit}, data) => {
  commit('RECOVER_PASSWORD_SENDING', true);
  commit('RECOVER_PASSWORD_ERRORS', initialState.get().recover.errors);

  return new Promise((resolve, reject) => {
    api.recoverPassword(data)
      .then(response => {
        commit('RECOVER_PASSWORD_SENDING', false);
        commit('RECOVER_PASSWORD_SUCCESS', true);

        resolve(response);
      })
      .catch(error => {

        commit('RECOVER_PASSWORD_SENDING', false);
        commit('RECOVER_PASSWORD_SUCCESS', false);
        commit('RECOVER_PASSWORD_ERRORS', error.response.data.errors);

        reject(error);
      })
  });
};

const hashValidate = ({commit}, data) => {
  commit('HASH_VALIDATE_SENDING', false);

  return new Promise((resolve, reject) =>{
    api.validateHash(data)
      .then(response => {
        commit('HASH_VALIDATE_SENDING', false);
        commit('HASH_VALIDATE_SUCCESS', true);

        resolve(response);
      })
      .catch(error => {

        commit('HASH_VALIDATE_SENDING', false);
        commit('HASH_VALIDATE_SUCCESS', false);
        commit('HASH_VALIDATE_ERRORS', error.response.data.errors);

        reject(error);
      })
  })
};

const changePassword = ({commit}, data) => {
  commit('CHANGE_PASSWORD_SENDING', true);

  return api.changePassword(data)
    .then(response => {
      commit('CHANGE_PASSWORD_SENDING', false);
      commit('CHANGE_PASSWORD_SUCCESS', true);
      return response;
    })
    .catch(error => {
      commit('CHANGE_PASSWORD_SENDING', false);
      commit('CHANGE_PASSWORD_SUCCESS', false);
      return error;
    })
};


const logout = ({ commit }) => {
  return api.logout()
  .then(response => {
    commit('LOGOUT_SUCCESS');
    return response;
  })
  .catch(error => {
    commit('LOGOUT_ERROR');
    return error;
  });
};

const keepAlive = ({ commit, dispatch }, args) => {
  setInterval(function() {
    api.refresh();
  }, args.interval * 60000);
};

const dropAuthToken = ({commit}, state) => {
  cacheStorage.drop('application/token');
}

export default {
  doAuth,
  dropAuthToken,
  isAuthenticated,
  logout,
  recoverPassword,
  changePassword,
  hashValidate,
  keepAlive
}
