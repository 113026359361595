import storeCache from '@/services/api/cache'
import myCache from './cache'

const getTranslations = (state) => {
  return state.api.get.values;
}

const getCurrentTranslationLanguage = (state) => {
  return state.api.get.lang;
}

const getTranslationsFromCache = (state) => {
  let cache = storeCache.get(myCache.STORAGE_KEY);

  if(!cache){
    throw 'Translations cache not found!';
  }

  return cache.api.get.values;
}

export default {
  getTranslations,
  getCurrentTranslationLanguage,
  getTranslationsFromCache,
};
