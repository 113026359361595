<template>
  <v-container fluid class="pa-2">
    <v-row align="center">
      <v-col :class="titleClass">
        {{title}}
      </v-col>
      <slot name="beforeValue"></slot>
      <v-col :class="valueClass">
        {{value}}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "CardItem",
    props:{
      title:{
        type: String,
        required: true
      },
      value: {
        type: String,
        required: true
      },
      titleClass:{
        type: String,
        required: false,
        default: 'xs9'
      },
      valueClass:{
        type: String,
        required: false,
        default: 'text-xs-left xs3'
      }
    }
  }
</script>

<style scoped>

</style>
