<template>
  <div v-shortkey.once="['ctrl', 'esc']" @shortkey="toggle">
    <v-navigation-drawer
      v-model="showModel"
      :permanent="!isMobile"
      :app="true"
      clipped
      mini-variant-width="60"
      ref="sidebarNavigation"
      class="navigation-drawer"
    >
      <v-toolbar flat class="hidden-md-and-up" color="primary" height="50">
        <v-toolbar-title class="white--text">
          <v-row align="center" justify="start">
            <v-col cols="2">
              <v-avatar size="30">
                <img :src="avatar" />
              </v-avatar>
            </v-col>

            <v-col cols="9">
              {{name}} {{lastname}}
            </v-col>
          </v-row>
        </v-toolbar-title>
      </v-toolbar>

      <VuePerfectScrollbar class="scroll">
        <Nav
          ref="nav"
          :user="user"
          :sidebarExpanded="!isCollapsed"
          @groupClick="toggle"
          @toggle="toggle"
        />
      </VuePerfectScrollbar>

    </v-navigation-drawer>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import Nav       from './_components/Nav'
  import MyCache   from '@/services/local/sidebar/store/cache'

  export default {
    name: 'Sidebar',
    components:{
      Nav
    },
    data(){
      return {
        showModel: false,
      }
    },
    computed:{
      ...mapGetters({
        user: 'services/api/auth/user',
        avatar: 'services/api/auth/avatar',
        name: 'services/api/auth/name',
        lastname: 'services/api/auth/lastname',
        age: 'services/api/auth/age',
        username: 'services/api/auth/username',
        uniqid: 'services/api/auth/uniqid',
        isCollapsed: 'services/local/sidebar/isCollapsed',
        isMobile: 'services/browser/window/mobile'
      })
    },
    methods:{
      getNavComponent: function(){
        let me = this;
        return me.$refs.nav;
      },
      hide: function(){
        let me = this;
        me.$store.dispatch('services/local/sidebar/collapse', true);
      },
      show: function(){
        let me = this;
        me.$store.dispatch('services/local/sidebar/collapse', false);
      },
      toggle: function(){
        let me = this,
          nav = me.getNavComponent();

        me.$store.dispatch('services/local/sidebar/collapse', !me.isCollapsed);

        nav.expandGroups(me.isCollapsed);
        me.showModel = !me.showModel;
      }
    },
    mounted() {
      let me = this,
        cacheCollapsed = localStorage.getItem(MyCache.SIDEBAR_STORAGE_KEY);

      if(false === me.isMobile && null !== cacheCollapsed) {
        me.$store.dispatch('services/local/sidebar/collapse', JSON.parse(cacheCollapsed).isCollapsed);
      }

      /**
       * On mobile, the sidebar state must be always collapsed
       */
      if(true === me.isMobile){
        me.$store.dispatch('services/local/sidebar/collapse', true);
      }

      me.$root.$on('toggleSidebar', me.toggle);
    },
    watch:{
      isMobile: function(newValue, oldValue){
        let me = this;
        me.$store.dispatch('services/local/sidebar/collapse', newValue);
      }
    },
    beforeDestroy(){
      let me = this;
      me.$root.$off('toggleSidebar', me.toggle);
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import './style/style.scss';
</style>
