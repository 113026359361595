const fetching = state => state.fetching;
const updating = state => state.updating;
const success = state => state.success;
const error = state => state.error;
const list = state => state.list;

export default {
  fetching,
  updating,
  success,
  error,
  list
};
