<template>
  <v-row class="pa-0 ma-0">
    <v-col cols="12" md="3"
      v-for="item in items" :key="item.subtitle"
    >
      <Item
        :subtitle="item.subtitle"
        :text="item.text"
        :icon="item.icon"
        :route="item.route"
      />
    </v-col>
  </v-row>

</template>

<script>
  import Routes from '@/router/routes'
  import Item   from '@/components/ui/card/CardLink'

  export default {
    name: 'StatsNavigationModule',
    components:{
      Item
    },
    data(){
      return {
        routes: Routes,
        items:[
          {
            subtitle: 'Carrera',
            text: 'Reporte el cual agrupa resultados mediante fecha y año',
            icon: 'fas fa-calendar-alt',
            route: Routes.get('statsCareerDate')
          },
          {
            subtitle: 'Curso',
            text: 'Reporte el cual agrupa resultados mediante fecha y año',
            icon: 'fas fa-calendar-alt',
            route: Routes.get('statsCourseDate')
          }
        ]
      }
    }
  }
</script>

<style scoped>

</style>
