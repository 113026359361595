import LoginView           from '@/views/auth/Login'
import LogoutView          from '@/views/auth/Logout'
import LostPasswordView    from '@/views/auth/LostPassword'
import ChangePasswordView  from '@/views/auth/ChangePassword'
import DashboardView       from '@/views/Dashboard'
import StatsView           from '@/views/stats'
import CareerStatsDateView from '@/views/stats/career/date'
import CourseStatsDateView from '@/views/stats/course/date'
import CourseListView      from '@/views/course/List'
import CourseCreateView    from '@/views/course/Create'
import CourseUpdateView    from '@/views/course/Update'
import CourseOptionsView     from '@/views/course/Options'
import CourseCloneModulesView    from '@/views/course/CloneModules'
import CourseManageModulesView    from '@/views/course/ManageModules'
import CourseManageExamsView    from '@/views/course/ManageExams'
import UserListView        from '@/views/user/List'
import UserProfileView     from '@/views/user/Profile'
import UserOptionsView     from '@/views/user/Options'
import UserMergeAccountsView  from '@/views/user/MergeAccounts'
import DiplomaView         from '@/views/user/Diploma'
import CertificateView     from '@/views/user/Certificate'
import AchievementListView from '@/views/achievement'
import AchievementCreateView from '@/views/achievement/Create'
import AchievementUpdateView from '@/views/achievement/Update'
import SystemView            from '@/views/system/System'

const LOGIN = {
      name:'Login',
      path:'/',
      component: LoginView,
      localizedName: null, //@TODO add localized named for login
      meta:{
        isHomePage: false,
        requiresAuth: false,
        roles:[]
      }
}

const LOGOUT = {
      name:'Logout',
      path:'/logout',
      component: LogoutView,
      localizedName: null, //@TODO add localized named for logout
      meta:{
        isHomePage: false,
        requiresAuth: false,
        roles:[]
      }
}

const LOST_PASSWORD = {
  name:'LostPassword',
  path:'/optin/password/recover',
  component: LostPasswordView,
  localizedName: null, //@TODO add localized named for logout
  meta:{
    isHomePage: false,
    requiresAuth: false,
    roles:[]
  }
}

const CHANGE_PASSWORD = {
  name:'ChangePassword',
  path:'/optin/password/change/:hash',
  props: true,
  component: ChangePasswordView,
  localizedName: null, //@TODO add localized named for logout
  meta:{
    isHomePage: false,
    requiresAuth: false,
    roles:[]
  }
}

const DASHBOARD = {
  name: 'Dashboard',
  path: '/dashboard',
  component: DashboardView,
  localizedName: null,
  meta:{
    isHomePage: true,
    requiresAuth: true,
    history: true,
    roles: []
  }
}

const STATS = {
  name: 'Stats',
  path: '/stats',
  component: StatsView,
  localizedName: null,
  meta:{
    isHomePage: false,
    requiresAuth: true,
    history: true,
    roles: []
  }
}

const CAREER_STATS_DATE = {
  name: 'CareerStatsDate',
  path: '/career/stats/date',
  component: CareerStatsDateView,
  localizedName: null,
  meta:{
    isHomePage: false,
    requiresAuth: true,
    history: true,
    roles: []
  }
}

const COURSE_STATS_DATE = {
  name: 'CourseStatsDate',
  path: '/course/stats/date',
  component: CourseStatsDateView,
  localizedName: null,
  meta:{
    isHomePage: false,
    requiresAuth: true,
    history: true,
    roles: []
  }
}

const COURSE_LIST = {
  name: 'CourseList',
  path: '/course/list',
  component: CourseListView,
  localizedName: null,
  meta:{
    isHomePage: false,
    requiresAuth: true,
    history: true,
    roles: []
  }
}

const COURSE_CREATE = {
  name: 'CourseCreate',
  path: '/course/create',
  component: CourseCreateView,
  localizedName: null,
  meta:{
    isHomePage: false,
    requiresAuth: true,
    history: true,
    roles: []
  }
}

const COURSE_UPDATE = {
  name: 'CourseUpdate',
  path: '/course/update/:courseId',
  props: true,
  component: CourseUpdateView,
  localizedName: null,
  meta:{
    isHomePage: false,
    requiresAuth: true,
    history: true,
    roles:[]
  }
}

const COURSE_OPTIONS = {
  name: 'CourseOptions',
  path: '/course/options',
  component: CourseOptionsView,
  localizedName: null,
  meta:{
    isHomePage: false,
    requiresAuth: true,
    history: true,
    roles: []
  }
}

const COURSE_CLONE_MODULES = {
  name: 'CourseCloneModules',
  path: '/course/clone/modules',
  component: CourseCloneModulesView,
  localizedName: null,
  meta:{
    isHomePage: false,
    requiresAuth: true,
    history: true,
    roles: []
  }
}

const COURSE_MANAGE_MODULES = {
  name: 'CourseManageModules',
  path: '/course/manage/modules',
  component: CourseManageModulesView,
  localizedName: null,
  meta:{
    isHomePage: false,
    requiresAuth: true,
    history: true,
    roles: []
  }
}

const COURSE_MANAGE_EXAMS = {
  name: 'CourseManageExams',
  path: '/course/manage/exams',
  component: CourseManageExamsView,
  localizedName: null,
  meta:{
    isHomePage: false,
    requiresAuth: true,
    history: true,
    roles: []
  }
}

const USER_LIST = {
  name: 'UserList',
  path: '/student/list',
  component: UserListView,
  localizedName: null,
  meta:{
    isHomePage: false,
    requiresAuth: true,
    history: true,
    roles: []
  }
}

const USER_PROFILE = {
  name: 'UserProfile',
  path: '/student/profile/:id',
  props: true,
  component: UserProfileView,
  localizedName: null, //@TODO add localized named for profile
  meta:{
    isHomePage: false,
    requiresAuth: true,
    history: true,
    roles:[]
  }
}

const USER_OPTIONS = {
  name: 'UserOptions',
  path: '/student/options',
  component: UserOptionsView,
  localizedName: null,
  meta:{
    isHomePage: false,
    requiresAuth: true,
    history: true,
    roles: []
  }
}

const USER_MERGE_ACCOUNTS = {
  name: 'UserMergeAccounts',
  path: '/student/account/merge',
  component: UserMergeAccountsView,
  localizedName: null,
  meta:{
    isHomePage: false,
    requiresAuth: true,
    history: true,
    roles: []
  }
}

const DIPLOMA = {
  name: 'diploma',
  path: '/student/course/diploma/:code/:user',
  props: true,
  component: DiplomaView,
  localizedName: null,
  meta:{
    isHomePage: false,
    requiresAuth: true,
    history: true,
    roles:[]
  }
}

const CERTIFICATE = {
  name: 'certificate',
  path: '/student/course/certificate/:course/:user',
  props: true,
  component: CertificateView,
  localizedName: null,
  meta:{
    isHomePage: false,
    requiresAuth: true,
    history: true,
    roles: []
  }
}

const ACHIEVEMENT_LIST = {
  name: 'AchievementList',
  path: '/role/list',
  component: AchievementListView,
  localizedName: null,
  meta:{
    isHomePage: false,
    requiresAuth: true,
    history: true,
    roles: []
  }
}

const ACHIEVEMENT_CREATE = {
  name: 'AchievementCreate',
  path: '/role/create',
  component: AchievementCreateView,
  localizedName: null,
  meta:{
    isHomePage: false,
    requiresAuth: true,
    history: true,
    roles: []
  }
}

const ACHIEVEMENT_UPDATE = {
  name: 'AchievementUpdate',
  path: '/role/update/:achievementId',
  props: true,
  component: AchievementUpdateView,
  localizedName: null,
  meta:{
    isHomePage: false,
    requiresAuth: true,
    history: true,
    roles:[]
  }
}

const SYSTEM = {
  name: 'System',
  path: '/system',
  component: SystemView,
  localizedName: null,
  meta:{
    isHomePage: false,
    requiresAuth: true,
    history: true,
    roles: []
  }
}

let routes = new Map();

routes.set('login', LOGIN)
routes.set('logout', LOGOUT)
routes.set('lostPassword', LOST_PASSWORD)
routes.set('changePassword', CHANGE_PASSWORD)
routes.set('dashboard', DASHBOARD)
routes.set('stats', STATS)
routes.set('statsCareerDate', CAREER_STATS_DATE)
routes.set('statsCourseDate', COURSE_STATS_DATE)
routes.set('courseList', COURSE_LIST)
routes.set('courseCreate', COURSE_CREATE)
routes.set('courseUpdate', COURSE_UPDATE)
routes.set('courseOptions', COURSE_OPTIONS)
routes.set('courseCloneModules', COURSE_CLONE_MODULES)
routes.set('courseManageModules', COURSE_MANAGE_MODULES)
routes.set('courseManageExams', COURSE_MANAGE_EXAMS)
routes.set('userList', USER_LIST)
routes.set('userProfile', USER_PROFILE)
routes.set('userOptions', USER_OPTIONS)
routes.set('userMergeAccounts', USER_MERGE_ACCOUNTS)
routes.set('diploma', DIPLOMA)
routes.set('certificate', CERTIFICATE)
routes.set('achievementList', ACHIEVEMENT_LIST)
routes.set('achievementCreate', ACHIEVEMENT_CREATE)
routes.set('achievementUpdate', ACHIEVEMENT_UPDATE)
routes.set('system', SYSTEM)

export default routes
