import initialState from './state.js'

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve fetching state
  Object.assign(state, iState);
};

const MERGE_SENDING = (state, value) => {
  state.merge.sending = !!value;
}

const MERGE_SUCCESS = (state, value) => {
  state.merge.success = !!value;
}

export default {
  RESET_STATE,
  MERGE_SENDING,
  MERGE_SUCCESS
};
