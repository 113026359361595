const fetching = state => state.fetching;
const success = state => state.success;
const error = state => state.error;

const users = state => state.users;
const pages = state => state.pages;
const courses = state => state.courses;

export default {
  fetching,
  success,
  error,
  users,
  pages,
  courses
};
