const doRequest = ({ commit }) => {
  commit('PENDING', true);
}

const endRequest = ({ commit }) => {
  commit('PENDING', false);
}

const addDescription = ({ commit }, args) => {
  commit('ADD_DESCRIPTION', args);
}

const removeDescription = ({ commit }, args) => {
  commit('REMOVE_DESCRIPTION', args);
}

const notifyConnError = ({ commit }, args) => {
  commit('CONN_ERROR', args);
}

export default {
  doRequest,
  endRequest,
  addDescription,
  removeDescription,
  notifyConnError,
}
