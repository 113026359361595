/**
 * @todo When connecting the real backend, just change this file to the correct
 * end points.
 */

const ENDPOINT = new Map();
ENDPOINT.set('get', '/administration/course/exam/list');
ENDPOINT.set('remove', '/administration/course/exams/delete');
ENDPOINT.set('update', '/administration/course/exams/update');

export default ENDPOINT
