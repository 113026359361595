import api from '../api'
import translationStore from '@/services/api/translation/store'
import getters from './getters'

export default {
  fetchLanguages({ commit }, args) {
    commit('FETCHING', true);

    return api.fetchLanguages()
    .then((response) => {
      commit('FETCHING', false);

      if(true === response.data.success){
        commit('FETCH_COMMIT', response.data.values);
      }

      commit('FETCH_ERROR', response.data);

      return response.data;
    })
    .catch((error) => {
      commit('FETCHING', false);
      commit('FETCH_ERROR', error);
      return error;
    });
  },
  setCurrentLanguage({ commit }, lang){
    if(null === lang) return;
    commit('SET_CURRENT', lang);
  }
}
