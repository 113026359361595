<template>
  <v-main v-if="authenticated" id="dashboard-view">

    <Toolbar/>

    <v-container fluid class="pa-0 main-content">
      <VuePerfectScrollbar class="scroll pa-0">
        <v-container>
          <v-btn
            v-if="authenticated && false === isFetching"
            large
            text
            class="elevation-0"
            @click.native="$router.go(-1)"
          >
            <v-icon left>fas fa-chevron-left</v-icon>
            Volver
          </v-btn>

          <PageLoader :display="true === isFetching" />

          <CertificateModule
            v-if="false === isFetching"
            :PDFUrl="diplomaPDF"
            :imageUrl="diplomaImage"
            :HTMLUrl="diplomaHtml"
            :socialText="socialText"
            :hashTags="hashTags"
            :translations="translations"
          />
        </v-container>
      </VuePerfectScrollbar>
    </v-container>
  </v-main>
</template>

<script>
  import TranslationMixin   from '@/mixins/Translation'
  import Toolbar from '@/components/toolbar'
  import CertificateModule from "@/modules/certificate"
  import PageLoader from "@/components/ui/loader/PageLoader"

  import { mapGetters } from 'vuex'

  export default{
    name: 'DiplomaView',
    mixins:[
      TranslationMixin
    ],
    components:{
      Toolbar,
      CertificateModule,
      PageLoader
    },
    computed: {
      ...mapGetters({
        authenticated: 'services/api/auth/authenticated',
        course: 'services/api/course/diploma/getCourse',
        diplomaImage: 'services/api/course/diploma/getImage',
        diplomaHtml: 'services/api/course/diploma/getHtml',
        diplomaPDF: 'services/api/course/diploma/getPDF',
        branchName: 'services/api/course/diploma/getBranchName',
        branchLocation: 'services/api/course/diploma/getBranchName',
        isFetching: 'services/api/course/diploma/isFetching'
      }),
      socialText: function(){
        let me = this;

        return [
          me.translations.general.diploma,
          me.course.name,
          me.branchName,
          me.branchLocation
        ].join(' - ');
      },
      hashTags: function(){
        let me = this;

        return [
          me.course.name.toLowerCase().replace(' ',''),
          me.branchName.toLowerCase().replace(' ',''),
          me.branchLocation.toLowerCase().replace(' ','')
        ].join(',')
      }
    },
    mounted(){
      let me = this;
      me.$store.dispatch('services/api/course/diploma/fetch',{
        code: me.code
      });
    },
    props:{
      code:{
        type: String,
        required: true
      },
      user:{
        type: String,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>
