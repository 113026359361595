<template>
  <v-main v-if="authenticated" id="dashboard-view">

    <Toolbar/>

    <v-container fluid class="pa-0 main-content">
      <VuePerfectScrollbar class="scroll pa-0">
        <v-container class="pa-3 ma-3">
          <AchievementCreateComponent
            :sendingForm="sendingForm"
            @formSend="createAchievement"
          />

          <SnackbarMessage
            ref="finishMessage"
            :text="message"
            :color="color"
          />
        </v-container>
      </VuePerfectScrollbar>
    </v-container>
  </v-main>
</template>

<script>
  import AchievementCreateComponent     from '@/modules/achievement/crud/Create'
  import Toolbar                   from '@/components/toolbar/index'
  import PageLoader                from '@/components/ui/loader/PageLoader'
  import SnackbarMessage           from '@/components/ui/snackbar'
  import Routes from '@/router/routes'

  import { mapGetters } from 'vuex'

  export default {
    name: 'AchievementCreate',
    components:{
      AchievementCreateComponent,
      PageLoader,
      Toolbar,
      SnackbarMessage
    },
    data(){
      return{
        message: '',
        color: ''
      }
    },
    computed:{
      ...mapGetters({
        authenticated: 'services/api/auth/authenticated',
        sendingForm: 'services/api/achievement/crud/fetching',
        error: 'services/api/achievement/crud/error'
      })
    },
    methods:{
      createAchievement: function(values){
        let me = this;
        me.$store.dispatch('services/api/achievement/crud/create', values)
          .then(response => {
            me.color = true === response.success ? 'success' : 'red darken-2';
            me.message = response.message;
            me.$refs['finishMessage'].show();

            if(!response.success){
              return;
            }

            setTimeout(function(){
              me.$router.push({
                name: Routes.get('achievementUpdate').name,
                params: {
                  achievementId: response.achievement.uniqid
                }
              }).catch(()=>{})
            }, 500);
          })
      }
    }
  }
</script>

<style scoped>

</style>
