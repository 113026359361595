<template>
  <v-file-input
    v-model="formValue"
    :label="label"
    :outlined="outlined"
    :chips="chips"
    prepend-icon=""
    prepend-inner-icon="fas fa-paperclip"
  ></v-file-input>
</template>

<script>
  export default {
    name: 'FileInput',
    data(){
      return{
        formValue: null,
      }
    },
    methods:{
      getName: function(){
        let me = this;
        return me.name;
      },
      getFormValue: function(){
        let me = this;
        return me.formValue;
      },
      reset: function(){
        let me = this;
        me.formValue = null;
      }
    },
    props:{
      label:{
        type: String,
        required: true
      },
      name:{
        type: String,
        required: true
      },
      outlined:{
        type: Boolean,
        default: true
      },
      chips:{
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style scoped>

</style>
