<template>
  <v-container fluid class="pa-0">

    <v-layout row wrap align-center>
      <slot name="icon">
        <v-flex xs2 sm1 v-if="'' !== icon" :class="iconSlotClass">
          <v-icon
            :size="iconSize"
            :color="iconColor"
            :class="iconClass"
          >
            {{icon}}
          </v-icon>
        </v-flex>
      </slot>

      <slot name="title">
        <v-flex xs10 sm11>
          <span v-if="false === isHtml" :style="'font-size:'+textSize" class="text">{{text}}</span>
          <span v-if="true === isHtml" :style="'font-size:'+textSize" class="text html" v-html="text"></span>
        </v-flex>
      </slot>
    </v-layout>
  </v-container>
</template>

<script>
  export default {
    name:"Title",
    computed:{
      iSize: function(){
        let me = this;

        return me.iconSize === 0 ? me.size : me.iconSize;
      }
    },
    props:{
      icon:{
        type: String,
        default: () => {
          return '';
        }
      },
      iconColor:{
        type: String,
        default: () => {
          return 'red';
        }
      },
      text:{
        type: String
      },
      isHtml: {
        type: Boolean,
        default: false
      },
      textSize:{
        type: [Number, String],
        default: '1.5em'
      },
      iconSize:{
        type:[Number, String],
        default: 0
      },
      iconClass:{
        type: String,
        default: ''
      },
      iconSlotClass:{
        type: String,
        default: 'text-xs-center'
      }
    }
  }
</script>

<style scoped>
  .alumni-title{
    padding:0 0 3px 0;
  }

  .alumni-title .icon{
    float:left;
    display:block;
    padding:0.2% 8px 0 0;
    margin:0;
  }

  .alumni-title .text{
    padding:0;
    margin:0;
    text-transform:capitalize;
  }

</style>
