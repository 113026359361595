import initialState from "./state";

const RESET = (state) => {
  Object.assign(initialState.get(), state);
}

const SEARCHING = (state, value) => {
  state.searching = !!value;
}

const SUCCESS = (state, value) => {
  state.success = !!value;
}

const ERROR = (state, value) => {
  state.error = value;
}

const DATA = (state, value) => {
  state.data = value;
}

export default {
  DATA,
  SEARCHING,
  SUCCESS,
  ERROR,
  RESET
}
