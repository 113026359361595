export default {
  get: function () {
    return {
      data: [],
      searching: null,
      error: null,
      success: null
    }
  }
}
