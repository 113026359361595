<template>
  <v-main v-if="authenticated" id="dashboard-view">

    <Toolbar/>

    <v-container fluid class="pa-0 main-content">
      <VuePerfectScrollbar class="scroll pa-0">
        <v-container class="pa-3 ma-3">
          <DashboardNavigation />
        </v-container>
      </VuePerfectScrollbar>
    </v-container>
  </v-main>
</template>

<script>
  import TranslationsMixin      from '@/mixins/Translation'

  import Toolbar                from '@/components/toolbar/index'
  import Sidebar                from '@/components/sidebar/index'
  import Title                  from '@/components/ui/text/Title'
  import DashboardNavigation    from '@/modules/dashboard/navigation'

  import { mapGetters } from 'vuex'

  export default{
    name: 'DashboardView',
    mixins:[
      TranslationsMixin
    ],
    components:{
      Toolbar,
      Sidebar,
      Title,
      DashboardNavigation
    },
    computed:{
      ...mapGetters({
        authenticated: 'services/api/auth/authenticated',
        focused: 'services/browser/window/focused',
      })
    }
  }
</script>

<style scoped>
  .scroll{
    height:95vh;
  }
  .success {
    font-size:1.2rem!important;
    border-color:transparent!important;
    font-weight: bold;
    margin-right: 20px;
  }

  @media (max-width: 960px){
    .success{
      margin-right: 0;
    }
  }

</style>
