<template>
  <v-row class="pa-0 ma-0">
    <v-col cols="12">
      <StatsDateComponent
        :stats="stats"
        :sections="sections"
        :items="courses"
        :isFetchingFilters="isFetchingFilters"
        :isFetchingStats="isFetchingStats"
        :info="info"
        @search="search"
      />
    </v-col>
  </v-row>
</template>

<script>
  import StatsDateComponent from '@/modules/stats/date'
  import { mapGetters } from 'vuex'

  export default {
    name: 'index',
    components:{
      StatsDateComponent
    },
    data(){
      return{
        info:{
          name: 'career',
          text: 'name',
          value: 'code',
          label: 'Carrera',
          hint: 'Carreras a filtrar'
        }
      }
    },
    computed:{
      ...mapGetters({
        isFetchingStats: 'services/api/career/stats/date/results/fetching',
        stats: 'services/api/career/stats/date/results/stats',
        sections: 'services/api/career/stats/date/filters/sections',
        isFetchingFilters: 'services/api/career/stats/date/filters/fetching',
        courses: 'services/api/career/stats/date/filters/careers',
      })
    },
    created(){
      let me = this;
      me.$store.dispatch('services/api/career/stats/date/filters/fetch');
    },
    methods:{
      search: function(filters){
        let me = this;
        me.$store.dispatch('services/api/career/stats/date/results/fetch', filters);
      }
    }
  }
</script>

<style scoped>

</style>
