import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const fetch = () => {
  return Api.get(endpoints.get('get'));
}

export default {
  fetch,
};
