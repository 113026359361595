import listService from './list'
import crudService from './crud'
import statsService from './stats'
import versionService from './version'
import achievementService from './achievement'

export default {
  namespaced:true,
  modules: {
    list: listService,
    crud: crudService,
    stats: statsService,
    version: versionService,
    achievement: achievementService
  }
}
