<template>
  <router-link :to="route">
    <v-hover
      v-slot:default="{ hover }"
      open-delay="200"
    >
      <v-card
        :elevation="hover ? 16 : 2"
        class="pointer text-xs-center fill-height"
      >
        <v-card-title>
          <v-container grid-list-md class="text-center">
            <v-icon color="primary" size="96">
              {{icon}}
            </v-icon>
          </v-container>
        </v-card-title>

        <v-divider />

        <v-card-subtitle>{{subtitle}}</v-card-subtitle>

        <v-card-text>{{text}}</v-card-text>
      </v-card>
    </v-hover>
  </router-link>
</template>

<script>
  export default {
    name: 'CardLink',
    props:{
      icon:{
        type: String,
        required: true
      },
      text:{
        type: String,
        required: true
      },
      subtitle:{
        type: String,
        required: true
      },
      route:{
        type: Object,
        required:true
      }
    }
  }
</script>

<style scoped>

</style>
