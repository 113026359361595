<template>
  <v-row>
    <v-col cols="12">
      <v-row align="center" justify="center">
        <v-col cols="12" md="11">
          <h4 class="text-xs-center">Para compartir en redes sociales, simplemente descarga el certificado y adjuntalo en tu red social favorita</h4>
          <img
            :src="imageUrl"
            :class="isMobile ? 'img-certificate mobile' : 'img-certificate desktop'"
          />
        </v-col>

        <v-col cols="12" md="1" class="align-self-center text-xs-right">
          <h3 class="text-xs-center mt-4 mb-4 hidden-md-and-up">{{translations.social.shareTitle}}</h3>

          <v-row>

            <v-col cols="3" md="12">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    color="red lighten-1"
                    v-bind="attrs"
                    v-on="on"
                    :href="downloadPDFLink"
                    target="_blank"
                  >
                    <v-icon color="white">fas fa-file-pdf</v-icon>
                  </v-btn>
                  {{translations.general.downloadPDF}}
                </template>
              </v-tooltip>
            </v-col>

            <v-col cols="3" md="12">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    color="pink lighten-1"
                    v-bind="attrs"
                    v-on="on"
                    :href="PDFUrl"
                    target="_blank"
                  >
                    <v-icon color="white">fas fa-print</v-icon>
                  </v-btn>
                  {{translations.general.print}}
                </template>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>

      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "CertificateModule",
    computed:{
      ...mapGetters({
        isMobile: 'services/browser/window/mobile'
      }),
      twitterLink: function(){
        let me = this,
          url = 'https://twitter.com/intent/tweet',
          params = new URLSearchParams();

        params.append('hashtags', me.hashTags);
        params.append('url', me.HTMLUrl);
        params.append('text', me.socialText);

        return [url,'?',params.toString()].join('');
      },
      facebookLink: function(){
        let me = this,
          url = 'https://www.facebook.com/sharer/sharer.php';

        return [url,'?','u=', encodeURIComponent(me.HTMLUrl)].join('');
      },
      downloadPDFLink: function(){
        let me = this;
        return [me.PDFUrl,'&','download=1'].join('');
      }
    },
    props:{
      PDFUrl:{
        type: String,
        required: true
      },
      imageUrl:{
        type: String,
        required: true
      },
      HTMLUrl:{
        type: String,
        required: true
      },
      socialText:{
        type: String,
        required: true
      },
      hashTags:{
        type: String,
        required: true
      },
      translations:{
        type: Object,
        required: true
      }
    }
  }
</script>

<style scoped type="scss">
  .img-certificate{
    margin: 0 auto;
    display: block;
    padding:1em;
  }

  .img-certificate.mobile {
    width: 100%;
  }

  .img-certificate.desktop{
    width: 40%;
  }
</style>
