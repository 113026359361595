import cache from '@/services/api/cache'
import myCache from './cache'

const FETCHING = (state, value) => {
  state.api.get.fetching = !!value;
}

const FETCH_COMMIT = (state, translations) => {
  state.api.get.lang = translations.lang;
  state.api.get.values = translations.values;
  cache.save(myCache.STORAGE_KEY, state);
}

const FETCH_ERROR = (state, value) => {
  state.api.get.error = value;
}

export default {
  FETCHING,
  FETCH_COMMIT,
  FETCH_ERROR,
};
