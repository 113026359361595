<template>
   <div>
      <vm-progress
         :type="type"
         :percentage="value"
         :trackColor="color.track"
         :strokeColor="color.progress"
         :showText="true"
         :textInside="textInside"
         :width="width"
         :strokeWidth="strokeWidth"
         linearClassName="test"
      />

      <slot v-if="value === progress" name="done"></slot>
   </div>
</template>

<script>
   import Animate from 'animate'

   export default{
      name: "ProgressBar",
      computed:{
         color:function(){
            let me = this;

            switch(true){
               case me.value <= 25:
                  return {
                     progress:'#ff5252',
                     track:'#ffeded'
                  };
               case me.value <= 50:
                  return {
                     progress:'#ffc107',
                     track:'#fff5d8'
                  }
               case me.value <= 75:
                  return {
                     progress:'#2196f3',
                     track:'#d3ecff'
                  }
               case me.value === 100:
                  return {
                     progress:'#4caf50',
                     track:'#edffe3'
                  }
               default:
                  return {
                     progress:'#2196f3',
                     track:'#d3ecff'
                  }
            }
         },
      },
      data(){
         return {
            value:0
         }
      },
      mounted(){
         let _this = this,
         prog = _this.progress;
         /**
             *@todo Pass in random speed (min,max), constant speed, or no speed at all
             *as props
             */
         function determineSpeed(){
            return Math.floor(Math.random() * (35 - 50), 2) + 50;
         }

         if(prog > 100){
            console.warn('Given progress can not be greater than 100', prog);
            prog=100;
         }

         if(false === _this.animate){
            return _this.value = prog;
         }

         var animate = Animate(
            function(){
               let me = this;
               _this.value++;

               if(_this.value >= prog){
                  _this.value = prog;
                  me.pause();
                  _this.$emit('done', true);
               }
            },
            determineSpeed()
         );
      },
      props:{
         progress:{
            type: [String, Number]
         },
         textInside:{
            type: Boolean,
            default: false
         },
         type:{
            type: String,
            default:'line'
         },
         width:{
            type: Number,
            default:126
         },
         strokeWidth:{
            type: Number,
            default: 6
         },
         animate:{
            type:Boolean,
            default:true
         }
      },
   }
</script>

<style scoped>
  >>> .vm-progress-bar__innerText{
    color:red !important;
  }
</style>
