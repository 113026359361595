<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <v-btn
          large
          text
          class="elevation-0"
          @click.native="goToAchievementList"
        >
          <v-icon left>fas fa-chevron-left</v-icon>
          Volver
        </v-btn>
      </v-col>

      <v-col cols="12">
        <h1>{{versions.achievement.name}}</h1>
      </v-col>
      <v-col cols="12" v-if="selectedVersion">
        <v-row align="center">
          <v-col cols="1">
            <h3>Versión:</h3>
          </v-col>

          <v-col cols="11">
            <v-select
              v-model="selectedVersion"
              :items="versions.versions"
              item-value="id"
              item-text="version"
              return-object
              @change="fetchStats"
            >
              <template slot="item" slot-scope="data">
                {{data.item.version}}
              </template>
              <template slot="selection" slot-scope="data">
                <v-chip>
                  <span>{{data.item.version}}</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col cols="1">
            <h3>Activo:</h3>
          </v-col>

          <v-col cols="11">
            <v-select
              v-model="selectedVersion.active"
              :items="activeItems"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="true === loading" cols="12" class="text-center">
        <v-progress-circular
          color="primary"
          size="80"
          indeterminate
        />
      </v-col>

      <v-col cols="12" v-if="false === loading">
        <Comparison
          ref="AchievementRequirements"
          title="Roles"
          secondaryTitle="Roles requeridos actualmente"
          :items="stats.requirements"
          :has-position="false"
        />

        <Comparison
          ref="AchievementCareers"
          title="Carreras"
          secondaryTitle="Carreras del rol"
          :withOptions="true"
          :items="stats.careers"
          :has-specs="false"
        />

        <Comparison
          ref="AchievementCourses"
          title="Cursos"
          secondaryTitle="Cursos del rol"
          :withOptions="true"
          :items="stats.courses"
        />

        <v-row class="text-center">
          <v-col cols="12">
            <v-btn color="primary" class="mr-5" @click.prevent="updateAchievement" :disabled="updating">

              <v-progress-circular
                v-if="updating"
                indeterminate
                color="white"
                size="20"
              ></v-progress-circular>

              <div v-if="!updating">Guardar</div>
            </v-btn>
            <v-btn color="secondary lighten-2" @click.prevent="goToAchievementList">Cancelar</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import UpdateForm from '@/modules/course/_components/form'
  import Comparison from '@/components/comparison/Table'

  import { mapGetters } from 'vuex'

  import Routes from '@/router/routes'

  export default {
    name: 'Update',
    components:{
      Comparison,
      UpdateForm
    },
    data(){
      return{
        selectedVersion: null,
        activeItems: [
          {
            text: "Activo",
            value: true
          },
          {
            text: "Inactivo",
            value: false
          },
        ],
      }
    },
    computed:{
      ...mapGetters({
        loading: 'services/api/achievement/stats/fetching',
        updating: 'services/api/achievement/crud/fetching',
        stats: 'services/api/achievement/stats/stats'
      })
    },
    mounted(){
      let me = this;

      me.selectedVersion = me.versions.versions[0];

      for(let i = 0;i < me.versions.versions.length; i++){
        if(me.versions.versions[i].active){
          me.selectedVersion = me.versions.versions[i];
          break;
        }
      }

      me.fetchStats();
    },
    methods:{
      fetchStats: function(){
        let me = this;
        me.$store.dispatch('services/api/achievement/stats/fetch', {
          achievement: me.versions.achievement.uniqid,
          version: me.selectedVersion.version
        })
      },
      getAchievementRequirementComponent: function(){
        let me = this;
        return me.$refs['AchievementRequirements']
      },
      getAchievementCareersComponent: function(){
        let me = this;
        return me.$refs['AchievementCareers']
      },
      getAchievementCoursesComponent: function(){
        let me = this;
        return me.$refs['AchievementCourses']
      },
      updateAchievement: function(){
        let me = this,
          form = new FormData(),
          requirements = me.getAchievementRequirementComponent().getAccepted(),
          careers = me.getAchievementCareersComponent().getAccepted(),
          courses = me.getAchievementCoursesComponent().getAccepted();

        form.append('version', me.selectedVersion.id);
        form.append('active', me.selectedVersion.active ? '1' : '0');

        for(let i = 0; i < requirements.length; i++){
          form.append('requirements[]', requirements[i].id);
        }

        for(let i = 0; i < careers.length; i++){
          let key = ['careers', '[', careers[i].id, ']'].join('');
          form.append(key, careers[i].position);
        }

        for(let i = 0; i < courses.length; i++){
          let courseKey = ['courses_codes', '[', courses[i].course_code, ']'].join('');
          let specificKey = ['specific', '[', courses[i].course_code, ']'].join('');
          form.append(courseKey, courses[i].position);
          form.append(specificKey, courses[i].specific);
        }

        me.$emit('update', form);
      },
      goToAchievementList: function(){
        let me = this;

        me.$router.push({
          name: Routes.get('achievementList').name
        }).catch(()=>{})
      }
    },
    props:{
      versions:{
        type: Object,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>
