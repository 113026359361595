<template>
  <v-main v-if="authenticated" id="dashboard-view">

    <Toolbar/>

    <v-container fluid class="pa-0 main-content">
      <VuePerfectScrollbar class="scroll pa-0">
        <v-container>
          <v-row class="pa-0 ma-0">
            <v-col
              cols="12" md="3"
              v-for="item in items" :key="item.subtitle"
            >
              <Item
                :subtitle="item.subtitle"
                :text="item.text"
                :icon="item.icon"
                :route="item.route"
              />
            </v-col>
          </v-row>
        </v-container>
      </VuePerfectScrollbar>
    </v-container>
  </v-main>
</template>

<script>
  import Toolbar from '@/components/toolbar/index'
  import Routes from '@/router/routes'
  import Item from '@/components/ui/card/CardLink'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Options',
    components:{
      Toolbar,
      Item
    },
    computed:{
      ...mapGetters({
        authenticated: 'services/api/auth/authenticated',
      })
    },
    data(){
      return {
        routes: Routes,
        items:[
          {
            subtitle: 'Unión de cuentas',
            text: 'Une dos cuentas de usuario',
            icon: 'fas fa-sync',
            route: Routes.get('userMergeAccounts')
          },
          {
            subtitle: 'Listado',
            text: 'Revisa el listado de alumnos',
            icon: 'fas fa-user',
            route: Routes.get('userList')
          },
        ]
      }
    }
  }
</script>

<style scoped>

</style>
