<template>
  <v-app-bar
    dark
    color="primary"
    :app="app"
    class="elevation-0 main-nav"
    height="50"
  >
    <!-- <v-icon class="hamburger" @click.prevent="toggleSidebar">fas fa-bars</v-icon> -->

    <router-link to="/dashboard">
      <v-app-bar-nav-icon class="text-xs-center ml-3">
        <img src="@/assets/logo/logo.svg" width="32" style="margin:0 10px 0 25px;-webkit-filter: brightness(10);filter: brightness(10);"/>
      </v-app-bar-nav-icon>
      <span class="white--text pa-2">ADMIN</span>
    </router-link>

    <v-spacer></v-spacer>

    <!-- User Profile toggle button -->
    <v-menu
      ref="profileMenu"
      offset-y
      min-width="300"
      max-width="300"
      :close-on-content-click="false"
      justify-end
      class="hidden-sm-and-down"
    >

      <template v-slot:activator="{on}">
        <v-btn
          slot="activator"
          icon
          v-on="on"
        >
          <v-avatar :size="30">
            <img :src="avatar" />
          </v-avatar>
        </v-btn>
      </template>

      <v-layout row wrap class="pa-0 text-xs-right">
        <v-card flat width="100%">
          <v-card-actions>
            <v-icon color="primary lighten-3" class="animated pulse infinite">ico-user</v-icon>
            <v-card-title class="text-xs-left pl-2">
              {{user.name}} {{user.lastname}}
            </v-card-title>
          </v-card-actions>
        </v-card>

        <v-flex xs12>
          <v-card class="pa-0 ma-0 nav-profile">
            <ProfileMenu
              ref="profileMenu"
              :user="user"
            />
          </v-card>
        </v-flex>
      </v-layout>
    </v-menu>
  </v-app-bar>
</template>

<script>
  import ProfileMenu from '@/modules/profile/menu'

  import TranslationMixin from '@/mixins/Translation'
  import { mapGetters } from 'vuex'
  import uniqid from 'uniqid'

  export default {
    name: 'Toolbar',
    data(){
      return{
        buttonId: null,
        isMounted: false
      }
    },
    mixins:[
      TranslationMixin
    ],
    components:{
      ProfileMenu
    },
    computed:{
      ...mapGetters({
        user: 'services/api/auth/user',
        avatar: 'services/api/auth/avatar',
        isMobile: 'services/browser/window/mobile',
        windowHeight: 'services/browser/window/height'
      })
    },
    created(){
      let me = this;
      me.buttonId = uniqid('button-');
    },
    mounted(){
      let me = this;
    },
    methods:{
      toggleSidebar: function(){
        let me = this;
        me.$root.$emit('toggleSidebar');
      }
    },
    props:{
      app:{
        type:Boolean,
        default: true,
        required: false
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  nav{
    padding:0px !important;
  }

  .hamburger{
    cursor:pointer;
  }

  nav a{
    color:#fff !important;
    text-decoration:none;
  }

  nav .toolbar__title a img{
    width:30px;
    display:block;
  }

</style>
