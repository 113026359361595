import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/src/styles/main.sass'
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
    theme:{
      dark: true,
      themes:{
        light: {
          primary: '#9c27b0',
          secondary: '#607d8b',
          accent: '#3f51b5',
          error: '#f44336',
          warning: '#e91e63',
          info: '#009688',
          success: '#03a9f4'
        }
      }
    }
  },
});
