<template>
  <v-main v-if="authenticated" id="dashboard-view">

    <Toolbar/>

    <PageLoader :display="loadingCourses" />

    <v-container v-if="false === loadingCourses" fluid class="pa-0 main-content">
      <VuePerfectScrollbar class="scroll pa-0">
        <v-container>
          <CourseList :courses="courses" />
        </v-container>
      </VuePerfectScrollbar>
    </v-container>
  </v-main>
</template>

<script>
  import CourseList     from '@/modules/course/list'
  import Toolbar        from '@/components/toolbar/index'
  import PageLoader     from '@/components/ui/loader/PageLoader'

  import { mapGetters } from 'vuex'

  export default {
    name: 'List',
    components:{
      CourseList,
      PageLoader,
      Toolbar
    },
    computed:{
      ...mapGetters({
        authenticated: 'services/api/auth/authenticated',
        loadingCourses: 'services/api/course/list/fetching',
        courses: 'services/api/course/list/courses'
      })
    },
    mounted(){
      let me = this;
      me.$store.dispatch('services/api/course/list/fetch');
    }
  }
</script>

<style scoped>

</style>
