import api from '../api'

const fetch = ({ commit }) => {
  commit('FETCH', true);

  return api.fetch()
  .then(response => {
    commit('FETCH', false)
    commit('SUCCESS', true)
    commit('ERROR', false)
    commit('DATA', response.data)

    return response.data;
  })
  .catch(error => {
    commit('FETCH', false)
    commit('SUCCESS', false)
    commit('ERROR', error)
    return error;
  })
};

export default {
  fetch
}
