import filtersService from './filters'
import resultsService from './results'

export default {
  namespaced:true,
  modules: {
    filters: filtersService,
    results: resultsService
  }
}
