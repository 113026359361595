import statsService from './stats'
import listService from './list'
import courseService from './course'
import crudService from './crud'
import certificateService from './certificate'
import diplomaService from './diploma'
import classesService from './classes'
import examService from './exam'
import typeService from './type'

export default {
  namespaced:true,
  modules: {
    stats: statsService,
    list: listService,
    course: courseService,
    crud: crudService,
    certificate: certificateService,
    diploma: diplomaService,
    classes: classesService,
    exam: examService,
    type: typeService
  }
}
