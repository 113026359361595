import xhrError from './error'

export default{
  get(){
    return {
      pending: false,
      descriptions:[],
      error: xhrError.ERR_NONE,
    }
  }
}
