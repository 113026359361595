import api from '../api'

const clear = ({ commit }, args) => {
  commit('FETCHING', true);

  return api.clear(args)
    .then(response => {
      commit('FETCHING', false);
      commit('SUCCESS', true)
      commit('ERROR', null);
      return response;
    })
    .catch(error => {
      commit('FETCHING', false);
      commit('SUCCESS', false)
      commit('ERROR', error);
      return error;
    })
};

export default {
  clear
}
