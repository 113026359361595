<template>
    <div class="connection-error">
      <v-dialog v-model="show" max-width="500px" :persistent="persistent">
        <v-card>
          <v-card-title>
            {{ translations.connection.errors.noConnection.title }}
          </v-card-title>
          <v-card-text>
            {{ translations.connection.errors.noConnection.message }}
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" flat @click="retry">
              {{ translations.dialogs.retry }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import xhrError from '@/services/api/api/store/error'
  export default {
    name: 'NoConnection',
    data(){
      return {
        persistent: true
      }
    },
    computed:{
      ...mapGetters({
        error: 'services/api/api/error',
        translations: 'services/api/translation/getTranslationsFromCache'
      }),
      show: (_this) => {
        return _this.error === xhrError.NO_CONNECTION;
      }
    },
    methods:{
      retry: function(){
        this.$router.go(this.$router.history.current);
      }
    }
  }
</script>

<style scoped>
</style>
