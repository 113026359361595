import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const create = (args) => {
  return Api.post(endpoints.get('create'), args,{
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

const update = (args) => {
  return Api.post(endpoints.get('update'), args,{
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

export default {
  create,
  update
};
