<template>
  <v-row>
    <v-btn
      text
      fab
      small
      class="elevation-0"
      @click.prevent="show = true"
      :disabled="false === showDetailsModal"
    >
      <v-icon color="primary">far fa-eye</v-icon>
    </v-btn>

    <v-dialog v-model="show" fullscreen>
      <v-card class="statistics-modal">
        <v-toolbar color="primary">
          <v-toolbar-title class="white--text">{{title}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text fab small @click="show = false" dark><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>
        <v-row align="start" justify="start">
          <v-col cols="12" md="4" v-for="(item, i) in stats" :key="i">
            <v-row>
              <v-col cols="12">
                <v-card-title class="statistics-section-title white--text">{{item.section.name}}</v-card-title>
              </v-col>

              <v-col cols="12">
                <v-card-text>
                  <v-row v-for="(detail, j) in item.detail" :key="j">
                    <v-col cols="10">
                      {{detail.questionText}}
                    </v-col>

                    <v-col cols="2">
                      <span :style="scoreClass(scoreThermometer, detail.score)">{{detail.score}}</span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import ScoreColor from '@/components/ui/score/mixins/ScoreColor'

  export default {
    name: 'StatsModal',
    mixins:[
      ScoreColor
    ],
    data(){
      return{
        details: [],
        show: false
      }
    },
    computed:{
      showDetailsModal: function(){
        let me = this,
          hasDetails = false;

        for(let i = 0; i < me.stats.length; i++){
          if(me.stats[i].detail.length !== 0){
            hasDetails = true;
            break;
          }
        }

        return hasDetails;
      }
    },
    props:{
      title:{
        type: String,
        required: true
      },
      stats:{
        type: Array,
        required: true
      },
      scoreThermometer:{
        type: Array,
        required: true
      }
    }
  }
</script>

<style scoped>
  .statistics-modal{
    overflow-x: hidden !important;
  }
  .statistics-section-title{
    background-color: #607d8b;
  }
</style>
