import initialState from './state'

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve fetching state
  iState.fetching = state.fetching;
  Object.assign(state, iState);
};

const FETCH = (state, value) => {
  state.fetching = value;
}

const FETCH_SUCCESS = (state, value) =>{
  state.stats = value;
}

const FETCH_ERROR = (state, value) => {
  state.error = value;
}

export default {
  RESET_STATE,
  FETCH,
  FETCH_SUCCESS,
  FETCH_ERROR
}
