<template>
  <v-container fluid class="bg-login" full>
    <Auth />
  </v-container>
</template>

<script>

  import Auth      from '@/modules/auth'
  import AuthMixin from '@/mixins/Authenticated'

  export default{
    name: 'LoginView',
    mixins:[
      AuthMixin
    ],
    components:{
      Auth
    }
  }
</script>

<style scoped>
  .bg-login{
    width:100%;
    height:100%;
    background-color:#006199;
  }
</style>
